import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import LoveCycle from '../../../materials/icons/lovecycle.png';
import OnePercentLogo from '../../../materials/icons/1pcftp_logo.png';
import './charity.css';

class CharityPage extends Component {
    render() {
        updateNav();

        const loveCycleInsta = 'https://www.instagram.com/lovecycle_';
        const loveCycleFacebook = 'https://www.facebook.com/lovecycle20/';
        return (
            <div className="containerFluid">
                <div className="paperOffWhite standardFontResponsive lightenBgMore linkHighlight" id="charityContentPadding">
                    <h4 className="centeredHeader">Charity</h4>
                    <p>You may have spotted some of our wines marked as ‘Charity’ wines and come here for more information - you are in the right place! </p>

                    <p>We want to do our part and help support our local community as much as we can, you are all our family and we look after family. Whilst following our passion for wine we made sure giving back was embedded into the DNA of our company Carafe. </p>

                    <br />
                    <br />

                    <p>Carafe started its commitment to give back via joining the <a href="https://www.onepercentfortheplanet.org/" target="_blank" rel="noreferrer">1% for the Planet initiative</a> which was birthed by the founders of the companies Patagonia and Blue Ribbon Flies who believe because companies profit from the resources they take from the earth, they should protect those resources. This runs true to us, in dedication as part of the <a href="https://www.onepercentfortheplanet.org/" target="_blank" rel="noreferrer">1% for the Planet movement</a>, Carafe donates at least 1% minimum of all annual sales to environmental causes. </p>

                    <p>This contribution only scratches the surface in our opinion - we want to help more and create a personal touch. To further our commitment to charitable causes and prioritising our care for our community Carafe decided to launch wines named ‘charity’ wines. </p>

                    <p>‘Charity’ wines are wines we choose in each of the major categories - Sparkling, White, Orange, Rosé and Red where a generous percentage of each of the profits sold will go to a nominated charity. We choose charities local and true to us at Carafe. The charities selected are charities that are not common in mainstream media - we love using our platform to amplify the voices of these charities and contribute not only monetary but educationally, informing our guests. We rotate these charities on a frequent basis to ensure we can spread the love all round to different causes.</p>

                    <br />
                    <br />

                    <img className="charity-page-logo" src={LoveCycle}></img>
                    <p>Our current nominated charity wine beneficiary is <b><u>LoveCycle</u></b></p>

                    

                    <p>LoveCycle is a Grassroots charitable organisation aiming to provide reusable menstrual care to women and girls living in poverty. There are around 68,000 women living in poverty right now in the UK alone. This number consists of a huge womxn sleeping rough or living in hostels and temporary accommodation. The lifetime cost of a period is close to £4,800 which consists of nearly 11,000 disposable sanitary products over a lifetime.</p>

                    <p>Through local fundraising LoveCycle has been able to distribute menstrual care packages to women and girls across the South East, which contain cost and waste-effective reusable menstrual cups. LoveCycle is charged with the goal to de-stigmatise menstruation while empowering young women, girls, and anyone who menstruates. A silent necessity that every woman and girl requires but might not necessarily ask for.</p>
                        
                    <p>Residing local to Tunbridge Wells, founded by Best friends and Soul sisters, Tilly and Lucy, this cause is incredibly close to our heart. We are honoured to be able to help elevate their cause. If you would like any more information on upcoming events and ongoing projects related to LoveCycle, follow them on their instagram <a href={loveCycleInsta} target="_blank" rel="noreferrer">@lovecycle_</a> or Facebook page <a href={loveCycleFacebook} target="_blank" rel="noreferrer">LoveCycle</a>. Keep updated on their incredible journey.</p>

                    <br />

                    <p>We have an extraordinary waiting list for charities however if there is a charity that you would really like us to make a song and dance about that you think will be a great fit feel free to request to <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk</a> and we will see if we can work any magic. </p>
                </div>
            </div>
        );
    }
};

export default CharityPage;