import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { updateNav } from '../../../utils/updateActivePage';
import CheckoutProgress from '../../../components/checkout/checkoutProgress/checkoutProgress';
import DeliveryForm from '../../../components/checkout/deliveryForm/deliveryForm';
import { toTop } from '../../../utils/updateActivePage';
import postalCodesJs from 'postal-codes-js';
import { parse } from 'postcode';
import './checkoutDelivery.css';

export default class CheckoutDeliveryPage extends React.Component {

  componentDidMount() {
    updateNav();
    toTop();
  }

  handleOnChangeAdditionalNotes = (evt) => {
    const onChangeAdditionalNotes = this.props.onChangeAdditionalNotes;
    let value = evt.target.value || '';
    if (value.length > 1000) {
      value = value.substring(0, 1000);
    }
    onChangeAdditionalNotes(value);
  }

  postcodeApproved = (postcodeIn) => {
    // If not valid, false 
    const toUpper = postcodeIn.toUpperCase();
    const trimmed = toUpper.trim();
    /*
    const countryCode = 'GBR';
    const isValid = postalCodesJs.validate(countryCode, trimmed);
    if (!isValid) {
      return false;
    }
    */
    /*
    const nowhspc = trimmed.replace(/ /g, '');
    console.log('trimmedNoWhSpc', nowhspc);
    const strLen = nowhspc.length;
    const sub = nowhspc.substring(0, strLen-3);
    let indexOfDigit = -1;
    const digits = ['1','2','3','4','5','6','7','8','9','0']
    for (let i=0; i<sub.length; i++) {
      const letter = sub[i];
      if (digits.includes(letter)) {
        indexOfDigit = i;
        break;
      }
    }
    if (indexOfDigit === -1) return false;
    const postcodeArea = sub.substring(0, indexOfDigit);
    */
    const nowhspc = trimmed.replace(/ /g, '');
    const parsed = parse(nowhspc);
    if (!parsed.valid) return false;
    const postcodeArea = parsed.area;
    //console.log('postcodeArea', postcodeArea);

    // If in this list, false
    const disallowed = ['HS', 'ZE', 'IM', 'GY', 'JE', 'KA', 'PA'];
    //const someDisallowed = ['TR', 'ES', 'KW', 'PA', 'KA', 'PH'];

    if (disallowed.includes(postcodeArea)) return false;

    this.props.postcodeToUpdateDeliveryCost(postcodeArea);

    // true
    return true;
  }

  handleNextButton = (evt) => {
    evt.preventDefault();
    const postcodeVal = $('#postcode').val();
    const isAccepted = this.postcodeApproved(postcodeVal);
    const fullName = $('#fullName').val() || '';
    const address1 = $('#address1').val() || '';
    const city = $('#city').val() || '';
    const county = $('#county').val() || '';
    const country = $('#country').val() || '';
    const postcode = $('#postcode').val() || '';
    if ( !fullName || !address1 || !city || !county || !country || !postcode) {
      return;
    }
    if (isAccepted) {
      //console.log('Accepted!');
      this.props.history.push('/shop/checkout/confirm-and-pay');
    } else {
      console.log('Not accepted');
      $('#postcode-error').text("Sorry, we aren't able to ship to you at the moment. Please try again soon!");
    }
  }

  render(){
    const { checkout, updateDeliveryAddress, updateDeliveryAddressWhole, token } = this.props;
    if (!token) {
      //console.log('No state token');
      //console.log('REDIRECT ATTEMPT PAGE');
      //window.location.href = '/account/login';
    }
    const { shippingAddress, deliveryAdditionalNotes } = checkout;
    return (
      <div>

        <CheckoutProgress step={1} />
        
        <DeliveryForm 
          updateCheckoutAddress={updateDeliveryAddress} 
          shippingAddress={shippingAddress} 
          updateCheckoutAddressWhole={updateDeliveryAddressWhole}
          token={token}
        />

        <div className="containerFluidNarrow deliveryNoteContainer">
          <h6>Additional notes:</h6>
          <textarea id="additionalNotes" rows={8} onChange={this.handleOnChangeAdditionalNotes} value={deliveryAdditionalNotes} maxLength="1000"></textarea>
        </div>

        <div className="containerFluidNarrow">
          <p id="postcode-error"></p>
          {/*<p className="textRight">Continue to <Link className="bridgesTown" to="/shop/checkout/confirm-and-pay">Payment</Link></p>*/}
          <p className="textRight continue-payment">Continue to <a className="bridgesTown" href="#" onClick={this.handleNextButton}>Payment</a></p>
        </div>
      </div>
    )
  }
}