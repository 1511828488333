import React, { Component } from 'react';
import FormInput from '../../../generic/formInput/formInput';
import CountryInput from '../../../generic/formInput/countryInput/countryInput';
//import SubmitButton from '../../generic/submitButton/submitButton';
import { onChangePostcodeHandler } from '../../../../utils/addressFormUtils';
import './signupAddress.css';

export default class AddressInput extends Component {
    render() {
        return (
            <fieldset className="addressForm">
                <legend>Address</legend>
                <input id="postcode" className="postcode" type="text" onChange={onChangePostcodeHandler} name={'address-postcode'} placeholder='Postcode*' required={true} />
                <FormInput id="address1" type="text" name="address-line1" placeholder={'Address Line 1*'} required={true} />
                <FormInput id="address2" type="text" name="address-line2" placeholder={'Address Line 2'} />
                <FormInput id="city" type="text" name="address-locality" placeholder={'Town/City*'} required={true} />
                <FormInput id="county" type="text" name="address-county" placeholder={'Region/County*'} required={true} />
                <CountryInput id="country" doNotTrackState={true} />
                <p className="countryErrorMessage">Please select a country from the list</p>
            </fieldset>
        );
    }
}