import React from 'react'

export default function AddToCartBtn( { addRemoveToBasket, product, inStock }) {
    //const { addToCart, product } = this.props.addToCart;
  return (
    inStock ? 
      <button className="addToCartButton bridgesTown" onClick={() => addRemoveToBasket(product, 1, false)}>Add To Cart</button>
      : 
      <button className="addToCartButton bridgesTown" onClick={() => console.log('Out of stock') }>Out of Stock</button>
  )
}