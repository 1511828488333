import React from 'react';
import SignupDetails from './signupDetails/signupDetails';
import AddressInput from './signupAddress/signupAddress';
import SubmitButton from '../../generic/submitButton/submitButton';
import { pass } from '../../../utils/passFormat';
import $ from 'jquery';
//import axios from "axios";
import './signupForm.css';

class SignupForm extends React.Component {

    countryInputSuccess = (country) => {
        if (!country) return;
        const acceptedCountries = JSON.parse(localStorage.getItem('allCountries')) || [];
        const countryLower = country.trim().toLowerCase();
        const found = acceptedCountries.find(acceptedCountry => acceptedCountry.toLowerCase() === countryLower);
        if (found) {
            $('.countryErrorMessage').removeClass('showCountryError');
            return true;
        }
        $('.countryErrorMessage').addClass('showCountryError');
        return false;
    }

    enableSubmit = () => {
        const $submitbtn = $('.submitButton');
        $submitbtn.prop('disabled', false);
    }

    onSubmit = async (evt) => {
        evt.preventDefault();
        const $submitbtn = $('.submitButton');
        $submitbtn.prop('disabled', true);

        // Personal
        const title = $('#title').val();
        const firstName = $('#first-name').val();
        const lastName = $('#last-name').val();
        const phone = $('#phone-number').val();
        const email = $('#email').val();
        const password = $('#password').val();
        const password2 = $('#password2').val();
        if (password.length < 8) {
            $('.passwordMsg').text('Password must at least 8 characters');
            $('.passwordMsg').removeClass('pass');
            $('.passwordMsg').addClass('weak');
            $('#password').focus();
            this.enableSubmit();
            return;
        }
        if (password !== password2) {
            $('.passwordMsg').text('Paswords do not match');
            $('#password2').removeClass('strong');
            $('#password2').addClass('weak');
            $('.passwordMsg').removeClass('pass');
            $('.passwordMsg').addClass('weak');
            $('#password2').focus();
            this.enableSubmit();
            return;
        }

        const isPass = pass(password);
        if (!isPass) {
            $('#password').removeClass(['strong', 'pass']);
            $('#password').addClass('weak');
            $('.passwordMsg').text('Password must an: Uppercase, lowercase and a number(0-9)');
            $('.passwordMsg').removeClass('pass');
            $('.passwordMsg').addClass('weak');
            this.enableSubmit();
            return;
        }

        if (!firstName || !lastName || !password || !password2) return;
        const personalData = { title, firstName, lastName, phone, email, password };
        // Address
        const postcode = $('#postcode').val();
        const address1 = $('#address1').val();
        const address2 = $('#address2').val();
        const city = $('#city').val();
        const county = $('#county').val();
        const country = $('#country').val();
        if (!this.countryInputSuccess(country)) { this.enableSubmit(); return; };
        if (!postcode || !address1 || !city || !county || !country) { this.enableSubmit(); return; };
        const addressData = { postcode, address1, address2, city, county, country };
        const basket = this.props.basket;
        const { lastModified, productDiscountApplied, deliveryDiscountApplied, giftBoxes, giftWrapIds } = basket;
        const propProducts = basket.productLines;
        const liteGiftBoxes = giftBoxes.map(giftBox => {
            const { id } = giftBox;
            const liteGiftbox = { id };
            return liteGiftbox;
        });
        const liteProducts = propProducts.map(productObj => {
            const { id, quantity } = productObj;
            return { id, quantity };
        })
        const liteBasket = { productLines: liteProducts, lastModified, productDiscountApplied, deliveryDiscountApplied, giftBoxes: liteGiftBoxes, giftWrapIds };

        const signUp = this.props.signUp;

        const $mailingListSignUp = $('#mailingListSignUp');
        const addToMailingList = $mailingListSignUp.prop('checked') ? true : false;

        signUp(personalData, addressData, liteBasket, addToMailingList);
    }

    render() {
        return (
            <div className="signupComponent">

                <h3>Signup</h3>

                <form onSubmit={this.onSubmit}>
                    <SignupDetails doNotTrackState={true} />
                    <AddressInput doNotTrackState={true} />
                    <input id="mailingListSignUp" type="checkbox" />
                    <label htmlFor="mailingListSignUp">Sign up to our mailing list?</label>
                    <SubmitButton onClick={this.onSubmit} />
                </form>

                <p id="signup-error-container"></p>


            </div>
        )
    }
}

export default SignupForm;