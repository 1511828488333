import React, { Component } from 'react';
import './underConstruction.css';
import logo from '../../../materials/images/pngsTrans/carafe-logo-white-trans.png';
import toastError from '../../../materials/icons/toast-error.png';
import toastSuccess from '../../../materials/icons/toast-success.png';
//import SnackBar from '../../../components/generic/snackbar/snackbar';
import axios from 'axios';
import { getFromSessionStorage, setInSessionStorage } from '../../../utils/sessionstorage';
import { isValidEmail } from '../../../utils/emailUtils';

const sha256 = require('crypto-js/sha256');


class UnderConstruction extends Component {
    submitForm = async () => {
        const emailInput = document.getElementById('userEmail');
        const email = emailInput.value.toLowerCase();

        if (!email) {
            this.toast('All inputs are required', "#b33", "fee", toastError);
            return;
        }

        if (!isValidEmail(email)) {
            this.toast('Please enter valid email', "#b33", "fee", toastError);
            return;
        }

        // Check localStorage for previously submitted emails
        const emailHash = sha256(email).toString();
        const storageKey = 'browseremails';
        let browserEmails = [];
        try {
            // Needle/haystack check in array of emails the current user has already submitted in sessionStorage
            browserEmails = getFromSessionStorage(storageKey) || [];
            if (browserEmails) {
                // Valid object in sessionStorage
                const alreadySubmitted = browserEmails.find( item => { return item === emailHash });
                if (alreadySubmitted) {
                    // Already signed up
                    this.toast('Signed up!', "#5a5", "#efe", toastSuccess);
                    emailInput.value = '';
                    return;
                }
            }
        } catch (error) {
            console.log(error);
        }
        
        // Post email to API
        try {
            const url = '/api/mailinglist/signup';
            const response = await axios({
                url: url,
                method: 'POST',
                data: { email }
            });
        
            const { data } = response;
            const { success } = data;
        
            if (success !== true) {
                const errorMessage = data.message || 'Server error';
                this.toast(errorMessage, "#b33", "fee", toastError);
                return;
            } else {
                this.toast('Signed up!', "#5a5", "#efe", toastSuccess);
                emailInput.value = '';
                // Add email to array for storage
                browserEmails.push(emailHash);
                // Update submitted emails object in sessionStorage
                setInSessionStorage(storageKey, browserEmails);
            }
        } catch (error) {
            console.log(error);
            this.toast('Server error', "#b33", "fee", toastError);
        }
    };

    isValidEmail = (email) => {
        //const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x20-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return expression.test(String(email));
    }

    // Display toast in top right corner
    toast = (message, bgColor, textColor, img) => {
        var x = document.getElementById("snackbar");
        var xIcon = document.getElementById('toast-icon');
        var xText = document.getElementById('toast-text');
        xIcon.src = img;
        xText.style.color = textColor;
        xText.innerText = message;
        x.style.backgroundColor = bgColor;
        x.className = "show";
        setTimeout(function(){ 
            x.className = x.className.replace("show", "");
        }, 3000);
    }

    keyUp = function(e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            document.getElementById("submitButton").click();
        }
    }

    render(){
        // URLs for links
        const termsUrl = '/help/termsofservice/';
        const privacyUrl = '/help/privacypolicy/';
        const cookieUrl = '/help/cookiepolicy/';

        return (
            <div className="mainBackground">
                <div className="underContructionContainer">
                <div className="underConstructionWrapper">
                    <img src={logo} className="mainLogo" title="Carafe Logo" alt="Carafe Logo"/>
                    <p className="courierNew" id="underConstructionText">
                        Meet your new best friend - Carafe. Your wine hero for surburban and rural life bringing small and interesting producers of tasty grape juice to your doorstep!
                    </p>
                    <p className="bridgesTownBlack" id="comingSoon">Coming soon!</p>
                </div>
                </div>

                <div className="emailFormContainer">
                    <div className="emailFormWrapper">
                        <h2 className="bridgesTownBlack emailHeader">FOMO?</h2>
                        <p className="bridgesTownBlack emailText">Sign up to our mailing list so you dont miss a beat</p>
                        <input type="email" max="30" id="userEmail" name="userEmail" placeholder="Enter email here... " onKeyUp={this.keyUp}/>
                        <button id="submitButton" onClick={this.submitForm}>Submit</button>
                        <p className="terms-container">
                            By entering your email address, you agree to receive our marketing offers in accordance with our&nbsp;
                            <a className="terms-link" href={privacyUrl}>Privacy Policy</a>,&nbsp;
                            <a className="terms-link" href={termsUrl}>Terms of Service</a> and&nbsp;
                            <a className="terms-link" href={cookieUrl}>Cookie Policy</a>.
                        </p>
                    </div>
                </div>
            </div>

        )
    }
}

export default UnderConstruction;