import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//import $ from 'jquery';
import { updateNav } from '../../../utils/updateActivePage';
import './home.css';
import '../../../modular.css';
import Grapes from '../../../materials/images/GRAPES.jpg';
import Bottles from '../../../materials/images/NEW BOTTLE REPEAT.jpg';

/* test lengths
const winesOfTheWeekPlaceholder = [
    { "person": "Vicki", "wineName": "Abcdf Ergl_Abcdf Ergl_Abcdf Ergl_", "urlName": "Harvest" },
    { "person": "Freya", "wineName": "Abcdf Ergl_Abcdf Ergl_Abcdf Ergl_12345", "urlName": "Ricciotto-Chianti" },
    { "person": "Meg", "wineName": "Abcdf Ergl_Abcdf Ergl_Abcdf Ergl_Abcdf Ergl_", "urlName": "Vin-du-Pays-De-La-Cote-Vermeille-La-Luna" },
    { "person": "Lois", "wineName": "Abcdf Ergl_Abcdf Ergl_Abcdf Ergl_Abcdf Ergl_12345", "urlName": "MunJebel-Bianco" }
];
*/

class HomePage extends Component {

    idToNameNoWhSpc = (productId) => {
        const products = this.props.products || [];
        if (!products) {
            return '';
        }

        const foundProduct = products.find(prod => prod.id === productId);
        // for (let i=0;i<products.length;i++){
        //     console.log(`Checking: ${products[i].id}`);
        //     if (products[i].id === productId) {
        //         foundProduct = products[i];
        //         break;
        //     }
        // }
        // console.log(productId);
        if (!foundProduct) {
            return '';
        }
        const nameNoWhSpc = foundProduct['nameNoWhSpc'] || '';
        return nameNoWhSpc;
    }

    getBlackboardListItems = () => {
        const winesOfTheWeekPlaceholder = [
            { "person": "Vicki", "wineName": "Unico Zelo, 'Harvest' Pinot Noir, Australia", "urlName": "Harvest" },
            { "person": "Freya", "wineName": "Lanciola, 'Ricciotto Chianti' Sangiovese, Italy", "urlName": "Ricciotto-Chianti" },
            { "person": "Meg", "wineName": "Domaine Bruno Duchene, 'Vin du Pays De La Cote Vermeille 'La Luna'' Grenache & Carignan, France", "urlName": "Vin-du-Pays-De-La-Cote-Vermeille-La-Luna" },
            { "person": "Lois", "wineName": "Frank Cornelissen, 'Munjebel Bianco', Italy", "urlName": "MunJebel-Bianco" }
        ];

        const winesOfTheWeek = this.props.blackboards;

        // Create html
        let blackboardItems = [];
        for (let i = 0; i < winesOfTheWeek.length; i++) {
            const wineObj = winesOfTheWeek[i];
            if (!wineObj['person']) {
                // No data
                continue;
            }
            const person = wineObj['person'] || '';
            if (!wineObj['productId']) {
                // TBC
                const listItem = <div key={person}><span className="blackboardPerson">{person}:</span> <span className="tbc blackboardSmall">TBC</span></div>;
                blackboardItems.push(listItem);
                continue;
            }
            if (!wineObj['text']) {
                // Not handling auto name atm
                continue;
            }
            const productId = wineObj['productId'] || 0;
            const nameNoWhSpc = this.idToNameNoWhSpc(productId);
            const { text } = wineObj;
            const url = `/shop/product/${nameNoWhSpc}`;
            const textLen = text.length;
            let aClass = '';
            if (textLen > 48) {
                aClass = 'blackboardExtraSmall';
            } else if (textLen > 40) {
                aClass = 'blackboardSmall';
            } else if (textLen > 33) {
                aClass = 'blackboardMedium';
            } else {
                aClass = 'blackboardLarge';
            }
            const listItem = <div className={"blackboard-row " + aClass} key={person}><span className="blackboardPerson">{person}:</span> <Link to={url}>{text}</Link></div>;
            blackboardItems.push(listItem);
        }
        return blackboardItems;
    }

    render() {
        updateNav();
        const blackboardItems = this.getBlackboardListItems() || [];

        return (
            <div className="homepage">

                <div className="containerFluid">
                    <div className="homePageContentWrapper paperBrown lightenBg">
                        <h2>Welcome!</h2>

                        <div className="homePageContentContainer standardFontResponsive">
                            <div id="homePageText">
                                <p>Hello new friends, welcome to Carafe.</p>
                                <p>We are purevyors of fine grape juice, here to be your wine hero for suburban and rural life.</p>
                                <p>Have you ever felt disenchanted at the wine selection in supermarkets and struggled to get your hands on delicious juice outside of major cities or restaurants?</p>
                                <p>Us too babe, that’s why we took it upon ourselves to become the solution to our own problem.</p>
                                <p>We got you, delivery all around the neighbourhood (and further afield to our international friends coming soon!)</p>
                                <p>We focus on small and interesting producers and present them in an approchable way - no snobbiness here.</p>
                                <p>We want to showcase wine as the Disneyland for adults that it should be, the ultimate adult amusement park. So join us on this wild ride.</p>
                            </div>
                            <img src={Grapes} id="grapesImage" title="grapes" alt="grapes"></img>
                        </div>
                    </div>
                </div>

                <div className="containerFluid">
                    <img src={Grapes} id="grapesImageMobile" title="grapes" alt="grapes"></img>
                </div>

                <div className="containerFluid">
                    <div className="paperBrown lightenBg linkHighlight">
                        <div className="homeShopNow">
                            <img src={Bottles} title="bottles" alt="bottles" className="bottlesImageDesktop"></img>
                            <div className="inlineBlock homeShopNowText">
                                <h6 id="shopNowHeading" className="inlineBlock m0"><Link to="/shop">SHOP NOW -></Link></h6>
                                <p>Embedded in the founder's brain like a relentless alarm with no snooze, rings the phrase “a good sommelier is a storyteller”. <br />
                                    A good sommelier can transport you to the vineyard where you can visualise the journey from vines to bottle, almost peeking into the life of the producers tending to their grapes, smell the soil and see the love inside the glass.</p>
                                <p>It’s all very well getting the wine spiel in restaurants when you are in front of the sommelier however a lot of the times it can also be quite daunting and we find ourselves nodding along like sheep agreeing that ‘yes, 2008 was a great vintage for that wine… mmmh mmmh’ and ‘oh yes we get the high tannins and the hint of mulberry on the finish’ nod nod.</p>
                                <p>The best sommeliers customise their interaction with the guest and give you what you want to know about the wine. We are all for that! We will be your e-sommelier. Enough of trying to read uninspired tasting notes on big chain bottles hoping your bet pays off and you enjoy the wine. If it’s your taste, you’ve hit the jackpot. We are here to be your bookies, place your wagers on us, all of our wines are great contenders and we want to help you win the race.</p>
                                <p><b>For an easy win, see what wines Carafe is crazy about this week:</b></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="containerFluid">
                    <img src={Bottles} title="bottles" alt="bottles" className="bottlesImageMobile"></img>
                </div>

                <div className={"blackboard" + (blackboardItems.length > 4 ? " tallerBlackboard" : '')}>
                    <div className="blackboardText">
                        <h4>- Carafe's Wine of the Week -</h4>
                        <div id="blackboardList" className={blackboardItems.length > 4 ? "blackBoardTaller" : ''}>{blackboardItems}</div>
                    </div>
                </div>

            </div>
        )
    }

}

export default HomePage;