import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import FormInput from '../../../components/generic/formInput/formInput';
import SubmitButton from '../../../components/generic/submitButton/submitButton';
import { isValidEmail } from '../../../utils/emailUtils'
import './forgotPassword.css';

class ForgotPasswordPage extends React.Component {
    onSubmit = async(evt) => {
        evt.preventDefault();
        this.disableSubmit();
        const $email = $('#email')
        const email = $email.val();
        if (!isValidEmail(email)) {
            console.log('Error, invalid email');
            $('.errorMessage').addClass('show');
            this.enableSubmit();
        } else {
            // send to API
            //console.log('Send email to api');
            try {
                const url = '/api/accounts/forgot-password';
                const response = await axios({
                    url: url,
                    method: 'POST',
                    data: { email }
                });
                const { data } = response;
                const { success } = data;
                if (success) {
                    $('.submitButton').text('Email sent');
                    $email.prop("disabled", true);
                    $('.errorMessage').removeClass('show');
                } else {
                    this.enableSubmit();
                }
            } catch (error) {
                console.log(error);
                this.enableSubmit();
            }
        }
    }

    disableSubmit = () => {
        $('.submitButton').attr("disabled", true);
    }
    enableSubmit = () => {
        $('.submitButton').removeAttr("disabled");
    }

    render() {
        return (
            <div className="forgotPasswordPage containerFluidNarrow paperOffWhite paperOffWhiteLooser">
                <h5>Forgot password</h5>
                <form onSubmit={this.onSubmit}>
                    <FormInput id="email" placeholder="Email" required={true} />
                    <SubmitButton onClick={this.onSubmit} />
                </form>
                <p className="errorMessage">Please enter a valid email</p>
            </div>
        )
    }
}

export default ForgotPasswordPage;