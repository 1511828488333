import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './roundel.css';

export default class Roundel extends Component {
    render() {
        const { props } = this;
        const { currentStep, stepNum, stepText } = props;
        const roundelDisabled = currentStep < stepNum;
        const textDisabled = currentStep <= stepNum;
        const roundelClass = roundelDisabled ? "roundel roundel-disabled" : "roundel";
        let linkUrl = '';
        switch(stepNum){
            case 1: linkUrl = '/shop/checkout/delivery'; break;
            case 2: linkUrl = '/shop/checkout/confirm-and-pay'; break;
            case 3: linkUrl = '/shop/checkout/confirm-and-pay'; break;
            default: linkUrl = '/shop/checkout/delivery'; break;
        }
        return (
            <div className="roundelContainer">
                <div className={roundelClass}>
                    <h4>{stepNum}</h4>
                </div>
                { textDisabled ? <span className='disabled'>{stepText}</span> : <Link to={linkUrl}>{stepText}</Link>}
            </div>
        );
    }

}