import React, { Component } from 'react';
import AddressInputShipping from '../addressInputShipping/addressInputShipping';
//import $ from 'jquery';
import { onChangePostcodeHandler, onChangePostcodeHandlerCheckout } from '../../../utils/addressFormUtils';
import './deliveryForm.css';

export default class DeliveryForm extends Component {

    onChangePostcode = (evt) => {
        //console.log('deliveryform');
        const updateCheckoutAddressWhole = this.props.updateCheckoutAddressWhole;
        if (updateCheckoutAddressWhole) {
            //console.log('calling util');
            //console.log('Got address whole func');
            const stateUpdateVars = { updateCheckoutAddressWhole, addressType: 'shipping' };
            onChangePostcodeHandlerCheckout(evt, stateUpdateVars);
        } else {
            //console.log('No address whole func');
        }
    }

    /*
    onChangeHandler = (evt) => {
        const { updateCheckoutAddress } = this.props;
        if (updateCheckoutAddress) {
            const stateUpdateVars = { updateCheckoutAddress, addressType: 'shipping' };
            //onChangePostcodeHandler(evt, stateUpdateVars);
            const { target } = evt;
            const { id, value } = target;
            updateCheckoutAddress('shipping', id, value);
        }
    }
    */

    render() {
        const { updateCheckoutAddress, shippingAddress } = this.props;
        return (
            <div className="deliveryForm">
                <div className="addressSplit">
                    <div className="postcodeFinder">
                        <label htmlFor="postcode-finder">Postcode search:</label><br />
                        <input id="postcode-finder" type="text" onChange={this.onChangePostcode} name={'postcodefinder'} placeholder='Postcode' />
                    </div>
                    <AddressInputShipping shipping={true} shippingAddress={shippingAddress} updateCheckoutAddress={updateCheckoutAddress} />
                </div>
            </div>
        );
    }

}