import React from 'react';
import { Link } from 'react-router-dom';
import { updateNav } from '../../../utils/updateActivePage';
import $ from 'jquery';
import './faq.css';

class FAQ extends React.Component {
    addListeners() {
        $('#faqOverflow').children('h5').on('click', function () {
            $(this).next().slideToggle();
        });
    }
    componentDidMount() {
        this.addListeners();
    }
    render() {
        updateNav();
        return (
            <div className="faqPage linkUnderline containerFluid">
                <div id="faqContainer">
                    <h1 className="bridgesTownHeader faqHeader">Frequently Asked Questions</h1>
                </div>
                <div className="contentContainer contentWrap paperBlack whiteText">
                    <div className="contentWrap standardFontResponsive linkHighlight" >
                        <div id="faqOverflow">

                            <h5 className="questionH5">I would love some advice about wine - can you help?</h5>
                            <div>
                                <p>Absolutely! Let us be your guide. Email our friendly team at&nbsp;
                            <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk</a> and we’ll be more than happy to help you
                            find the right juice.</p>
                            </div>

                            <h5 className="questionH5">What does it mean by charity wines?</h5>
                            <div><p>Good question, my star student. At Carafe we nominate a
                            wine in each of our core categories (Sparkling, White,
                            Rosé, Orange {"&"} Red) where with each purchase 25% of the
                            profits from sale will go directly to a charity.</p>
                                <p>We rotate the nominated wines and change charities on a
                            frequent basis - keep posted for a cause close to home.</p>
                                <p>We are big advocates of supporting local and supporting
                                our industry at Carafe so you are likely to see mentions
                                of Pilot Light, Help 4 Hospso and Ladies of Restaurants as
                                charity wine beneficiaries. The wines that are charity will
                                be indicated with a wine stain next to the product and
                                more information will be found on the charity on the wines
                            product page.</p></div>

                            <h5 className="questionH5">Is it true all your packaging is recyclable?</h5>
                            <div><p>Yes, we have a strong eco focus and believe it shouldn't
                            be an afterthought so it made sense when establishing our
                            brand to make sure all our packaging is recyclable from the
                            get-go.</p><p>This includes every part of our packaging from the
                                tape on the boxes, to the paper used for the branded
                                postcards and even the logo stickers which are biodegradable.
                                We aspire to be join the B Corporation when we are a little bit
                            bigger - sustainability is at our core.</p></div>

                            <h5 className="questionH5">There’s a type of wine I love but can’t find it on the website - help!</h5>
                            <div><p><a href="mailto:info@carafewine.co.uk">Give us a message</a> and let us know what wine it is and we will
                            try and work our magic or help you find a cracking alternative.
                        Call us your wine whisperer.</p></div>

                            <h5 className="questionH5">Can you send wine abroad?</h5>
                            <div><p>We are so close!!!! We have a partnership in the works and are just getting the last little configuration done so we can get quotes automatically generated for you at checkout. Watch this space! And thank-you so much for your interest, you’re a star.</p></div>

                            <h5 className="questionH5">What gift options do you have?</h5>
                            <div><p>Depends on how fancy pants you want to get! We have wine knot wraps from repurposed vintage scarves, all one-of-a-kind to wrap your bottle up nice, snug and stylish. You can purchase those individually or as part of our hand painted abstract bow gift boxed. We cater for 1-2 bottles. If you want to gift significantly larger please message us and we will see if there is anything we can do, we can always add a lovely lil gift note.</p></div>

                            <h5 className="questionH5">Do you offer Subscriptions?</h5>
                            <div><p>Yep, if you like it you should put a direct debit on
                            it, woah-oh-oh-oh-oh-oh. Delicious wines delivered
                            to your door when you like - can’t go wrong. Check
                            out our Subscriptions page <Link to="/subscriptions">here</Link>.</p></div>

                            <h5 className="questionH5">But if I choose a Subscription, won’t I get locked in?</h5>
                            <div><p>Just a wine fling, we get it. We offer a range of
                            subscriptions to suit your needs from just a month,
                            to 3 months, 6 months and annually.</p><p>If it doesn’t
                                work out we won’t make you kick and scream the door
                                down, you can go. If there’s anything we can do
                                differently to reignite the flame let us know
                                but if not you can end your subscription with no
                            questions asked.</p><p> Please do note that we need 14 days notice minimum prior to your next wine delivery to ensure you do not get charged for the next subscription. Promise we won’t be a naggy ex
                            asking for one more shot.</p></div>

                            <h5 className="questionH5">Do you stock ‘natural’ wines?</h5>
                            <div><p>Natural is a bit of a taboo term in the wine industry
                            as the definitions of it seem to be vast and up to
                            interpretation.</p><p>We choose to stock wines that we believe
                                are a true expression of terroir (the land in which they
                                came from) and quite a number of these are wines with low
                            intervention which is under that ‘natural’ umbrella.</p><p>Low
                                intervention wines are wines where the winemaker lets
                            the grapes do its job with little interference.</p><p>These
                                wines have little added, they are a rawer expression
                                of the grapes - you may see minimal sulphites or a
                            Demeter logo on the back that may indicate this.</p><p> As
                                these wines haven’t been fussed with as much as
                                mass produced wines they may taste greatly different
                               than wines you see in a supermarket. </p></div>

                            <h5 className="questionH5">Do you offer discounts for bulk ordering?</h5>
                            <div><p>We sure do, treat yo’ self. “That wine really brings
                            out your eyes, oh and we also have it in pink.”
                            We have built in discounts automatically applied
                            in price with the more bottles you add of a wine
                            to incentivise you to treat yo’ self - go for it
                            and know we got your back.</p></div>

                            <h5 className="questionH5">I have a special occasion coming up - can you help?</h5>
                            <div><p>Sure we could, just drop us a line at <a href="mailto:info@carafewine.co.uk" className="linkBackground">info@carafewine.co.uk</a> and we’ll
                            do our very best to be your wine fairy godmother and make your dreams
                            come true - if this is creating a bespoke order and waving our wand
                            to source specific types of wine we don’t have, we’ll do our very best.</p>
                                <p>We also have Event mice at our disposal if you want the VIP treatment
                                for the day - contacts with amazing entrepreneurs and event companies
                            even down to catering. Bippity boppity boo. </p></div>

                            <h5 className="questionH5">Is there a minimum order?</h5>
                            <div><p>Nope - be as conservative or decadent with your spending
                            as you like, we are not wine political. Get what suits you.</p></div>

                            <h5 className="questionH5">I have an issue with a wine I've received - what do I do?</h5>
                            <div>
                                <p>What’s the sitch? If it is corked (smelling of wet dog or damp
                                cardboard) or experiencing another wine fault we are happy to
                                send a replacement - ideally if we can detain the faulty bottle
                                from you so we can check with our suppliers to prevent a bad batch
                                being sent out again that would be greatly appreciated! Drop us a
                            line at <a href="mailto:info@carafewine.co.uk" className="linkBackground">info@carafewine.co.uk</a> with your Order no to get this started.</p>
                                <br></br>
                                <p>Please note that if the wine purchased is listed as low intervention, these wines are a rawer expression of the grape with little interference from the winemaker - fresh vibrant styles. Many are not used to these tastes as wines sold on mass often have a lot of intervention and additives when bottling so it could be a shock to the system trying one however this is not a wine fault, this is how the wines are designed and the style. It is a good idea to decant the wine and let it breathe for a while then try again, the flavour may mellow over time and be a gentler expression. You could also try chilling it if it’s a red - some of the Low intervention wines are often drunk cool.</p>
                                <br></br>
                                <p>Any breakages please send a pic as soon as it arrives over to <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk</a> so we can send you over a replacement, please provide your order number so we can expedite this as fast as possible for you.</p>
                                <br></br>
                                <p>If you would like to make a return for any other reason than listed above please contact us directly via <a href="mailto:info@carafewine.co.uk" className="linkBackground">info@carafewine.co.uk</a> however do note that unless your wine is in unopened, in good condition and no more than 28 days since you placed your order has passed we will not be able to grant a refund unfortunately. </p>
                            </div>

                            <h5 className="questionH5">How should I store my wine?</h5>
                            <div>
                                <p>Wine is best stored laying down as this protects the cork from drying out and crumbling in the bottle. This is not a massive issue for wine that you intend to drink soon however if you don’t intend to drink it in the near future, lay it down and keep it in a consistently dry dark and cool place (e.g cupboard under the stairs).</p>
                                <p>It is a good idea to keep the bottles away from light as the bottles could experience light damage which could give them an undesirable taste such as cauliflower which Ruinart experienced when their Blanc de Blanc was incorrectly stored. You can get much more in depth with this question however unless you have a cellar or temperature controlled wine fridge this is the best way to keep your wines stored for best drinkability.</p>
                            </div>

                            <h5 className="questionH5">Can you provide wines for wholesale?</h5>
                            <div>
                                <p>We sure can, as the kids say ‘hook you up’. Drop us a line at <a href="mailto:info@carafewine.co.uk" className="linkBackground">info@carafewine.co.uk</a> and we will show you all the exciting wine at our fingertips.</p>
                            </div>

                            <h5 className="questionH5">Who did those fab graphics on your site? </h5>
                            <div>
                                <p>You think they’re cool too, huh? We love them! That would be the Queen, Robyn Dickins, check her out here <a target="_blank" href="https://www.instagram.com/robyn.design/" className="linkBackground">@robyn.design</a></p>
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

export default FAQ;