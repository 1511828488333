import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import './dashboard.css';

class DashboardPage extends Component {

    // fetchFromApi = async () => {
    //     try {
    //         const adminToken = localStorage.getItem('adminToken');
    //         const url = '/api/admin/orders/all';
    //         const response = await axios({
    //             url,
    //             method: 'POST',
    //             data: { adminToken }
    //         });
    //         if (response) {
    //             const { data } = response;
    //             if (data) {
    //                 const { orderDocs } = data;
    //                 localStorage.setItem('orderDocs', JSON.stringify(orderDocs));
    //                 const unixNow = Math.floor(Date.now()/1000);
    //                 localStorage.setItem('orderDocsLastFetched', unixNow);
    //             }
    //             console.log('Data error');
    //         } else {
    //             console.log('API response error');
    //         }
    //         console.log('Fetch error');
    //         return null;
    //     } catch (error) {
    //         console.log(error);
    //         console.log('redirect');
    //         const statusCode = error['response']['status'];
    //         console.log(statusCode);
    //         if ( statusCode == 401 ) {
    //             window.location.href = '/admin-archie-96/login';
    //         }
    //         //window.location.href = '/admin-archie-96/login';
    //     }
    // }

    fetchLatestCollectionFromApi = async () => {
        try {
            const adminToken = localStorage.getItem('adminToken');
            const url = '/api/admin/dhl/get-latest-collection-request';
            const response = await axios({
                url,
                method: 'POST',
                data: { adminToken }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    const { dhlCollectionRequest } = data;
                    localStorage.setItem('dhlCollectionRequest', JSON.stringify(dhlCollectionRequest));
                } else {
                    console.log('Data error');
                }
            } else {
                console.log('API response error');
            }
            return null;
        } catch (error) {
            console.log(error);
            const statusCode = error['response']['status'];
            console.log('redirect');
            console.log(statusCode);
            if ( statusCode == 401 ) {
                window.location.href = '/admin-archie-96/login';
            }
            
            //window.location.href = '/admin-archie-96/login';
        }
    }

    componentDidMount() {
        //this.fetchFromApi();
        this.fetchLatestCollectionFromApi();
    }

    render() {

        const adminToken = localStorage.getItem('adminToken');
        if (!adminToken) {
            console.log('redirect');
            window.location.href = '/admin-archie-96/login';
        } else {
            console.log('got token');
        }

        return (
            <div className="admin-dashboard-page containerFluidMedium">
                <h1>Admin portal</h1>

                <div className="pastel-red">
                    <h6>Pub in the Park</h6>
                    <p><Link to="/admin-archie-96/dashboard/pitp/orders">View Orders</Link></p>
                    <p><Link to="/admin-archie-96/dashboard/pitp/copy-product">Copy shop Product to PitP Products (by ID)</Link></p>
                    <p><Link to="/admin-archie-96/dashboard/pitp/add-products">Add PitP Products with CSV</Link></p>
                </div>

                <div className="pastel-green">
                    <h6>Orders</h6>
                    <p><Link to="/admin-archie-96/dashboard/orders/all"><b>View all</b></Link></p>
                    <p><Link to="/admin-archie-96/dashboard/orders/not-picked">View all unpicked orders (last 7 days)</Link></p>
                    <br />
                    <p><Link to="/admin-archie-96/dashboard/dhl/set-pickup"><b>Arrange DHL pickup date</b></Link></p>
                    <p><Link to="/admin-archie-96/dashboard/dhl/labels"><b>Get DHL Labels</b></Link></p>
                    <br />
                    <p><Link to="/admin-archie-96/dashboard/orders/send-email"><b>Resend order confirmation email</b></Link></p>
                    <br />
                    <p className="disabled"><Link to="/admin-archie-96/dashboard/orders/today">View up to 4:30pm today</Link></p>
                </div>

                <div className="pastel-yellow">
                    <h6>Products</h6>
                    <p><Link to="/admin-archie-96/dashboard/products/update-stock-with-note">Update Stock (with note)</Link></p>
                    <p><Link to="/admin-archie-96/dashboard/products/update-by-id">Update by ID</Link></p>
                    <br />
                    <p className="disabled"><Link to="/admin-archie-96/dashboard/products/view-low-stock">View low stock</Link></p>
                </div>

                <div className="pastel-blue">
                    <h6>Customers</h6>
                    <p className="disabled"><Link to="/admin-archie-96/dashboard/customers/view-latest">View latest sign ups</Link></p>
                </div>

                <div className="pastel-red">
                    <h6>Mailing List</h6>
                    <p><Link to="/admin-archie-96/dashboard/mailinglist">View all</Link></p>
                </div>

            </div>
        )
    }
}

export default DashboardPage;