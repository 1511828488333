import React from 'react';
//import { Redirect } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import { updateNav } from '../../../utils/updateActivePage';
// import $ from 'jquery';
import './checkoutPayment.css';
import CheckoutForm from '../../../components/checkout/stripe/CheckoutForm.jsx';
//import CheckoutProgress from '../../../components/checkout/checkoutProgress/checkoutProgress';
import { loadStripe } from "@stripe/stripe-js";
import { toTop } from '../../../utils/updateActivePage';

// New
const stripeApiPublicKey = localStorage.getItem('stripePublicKey') || '';
const stripePromise = stripeApiPublicKey ?
  loadStripe(stripeApiPublicKey)
  : null;

// Live
//const initpublicKeyLive = "pk_live_51I6JzlH1YulaXG2iQCn7HRCkzT9OKnVNcbBa0ljFX1Frpz4LRQimowmI7zQw79stXoSjPB0xzhI7pH06IqC7ufUu00ClgosTZg";
//const stripePublicKey = stripeApiPublicKey || initpublicKeyLive;
//const stripePromise = loadStripe(initpublicKeyLive);

// Test
//const testKey = 'pk_test_51HyJWaFxLYcr99aG3nUA2QrbglF7cWX0jt8viuy4Irokqx6hUPIU9LGW2pvqGDTpTR01LTNKUb6ndQe918QIR2Mn00hl9O2rno';
//const stripePromise = loadStripe(testKey);


export default class CheckoutPaymentPage extends React.Component {

  handleOnSuccessfulCheckout = () => {
    console.log('Success from checkout.js');
    //window.location = "/shop/checkout/complete/true";
    this.props.onSuccessfulCheckout();
  }

  onFailCheckout = () => {
    console.log('Fail from checkout.js');
    //window.location = "/shop/checkout/complete/false";
    this.props.onFailCheckout();
  }

  componentDidMount() {
    toTop();
  }

  render(){
    updateNav();
    const { props } = this;
    const { checkout, basket, email, updateBillingAddress, updateBillingAddressWhole, token, saveClientSecretAndPaymentMethod } = props;

    const totalWithDelivery = basket.totalWithDelivery;
    if ( !token || !totalWithDelivery ) {
      //console.log(localStorage.getItem('sessionToken'));
      //console.log('token', token);
      //console.log('total', totalWithDelivery);
      //console.log('REDIRECT ATTEMPT');
      //window.location.href = '/shop/basket';
    }
    //console.log('Promise, fresh: ', stripePromise);
    
    return (
      <div className="checkoutPaymentPage">

        { totalWithDelivery ?
        <Elements stripe={stripePromise}>
          <CheckoutForm 
           onSuccessfulCheckout={this.handleOnSuccessfulCheckout}
           checkout={checkout}
           basket={basket}
           updateCheckoutAddress={updateBillingAddress}
           updateCheckoutAddressWhole={updateBillingAddressWhole}
           defaultEmail={email}
           saveClientSecretAndPaymentMethod={saveClientSecretAndPaymentMethod}
           stripePromise={stripePromise}
           totalWithDelivery={totalWithDelivery}
           token={token}
           />  
        </Elements>
        : null }

        <p id="payment-error-container"></p>
        
      </div>
    )
  }
}