import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../../../components/account/login/loginForm';
import { updateNav } from '../../../utils/updateActivePage';
//import * as QueryString from "query-string"
import './loginOrGuest.css';

class LoginPage extends React.Component {
    onSubmit = (email, password, isChecked) => {
        const { props } = this;
        const { login } = props;
        //const { location } = props;
        //const { redirect } = QueryString.parse(location.search);
        // send to API
        //const goBack = this.props.history.goBack;
        return login(email, password, isChecked);
    }
    render() {
        if (this.props.token) window.location.href = '/shop/checkout/delivery';
        updateNav();
        return (
            <div className="containerFluid containerFluidVeryNarrow loginPage paperOffWhite paperOffWhiteLooser">
                <div className="loginPageTitle linkHighlight">
                    <h5>Login</h5>
                    <span>(Don't have an account? <Link to="/account/signup">Signup</Link>!)</span>
                </div>
                <LoginForm onSubmit={this.onSubmit} />
                <p className="guest-checkout-notify"><Link to="/shop/checkout/delivery">Or checkout as Guest?</Link></p>
            </div>
        )
    }
}

export default LoginPage;