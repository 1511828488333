import { Link } from 'react-router-dom';
import $ from 'jquery';
import './basketRow.css';

const BasketRow = ({ product, url, quantity, sum, addRemoveToBasket, addGiftToRow }) => {
    const disableButtons = () => {
        $('button').prop('disabled', true);
    }

    const dec = () => {
        const set = false;
        handleAddRemoveToBasket(-1, set);
    }
    const inc = () => {
        const set = false;
        handleAddRemoveToBasket(1, set);
    }
    const handleOnChange = (evt) => {
        const asNum = parseInt(evt.target.value);
        const set = true;
        handleAddRemoveToBasket(asNum, set);
    }
    
    /*
    const decGift = () => {
        addGiftToRow(product, giftQuantity-1);
    }
    const incGift = () => {
        if (giftQuantity < quantity){
            //console.log(product);
            addGiftToRow(product, giftQuantity+1);
        }
    }
    const handleOnChangeGift = (evt) => {
        const asNum = parseInt(evt.target.value);
        addGiftToRow(product, asNum);
    }
    const handleOnChangeGiftWrapped = (event) => {
        let checkboxVal = $(event.target).prop('checked');
        //console.log($(event.target).prop('checked'))
        if(checkboxVal === true){
            incGift();
        }
    }
    */

    const handleAddRemoveToBasket = (quantity, set) => {
        const disableToast = true;
        addRemoveToBasket(product, quantity, set, disableToast);
    }
    const hoverText = () => {
        let textArray = [];
        const grapeType = product['grapeType'];
        const countryType = product['country'];
        if (grapeType) {
            textArray.push(grapeType);
        }
        if (countryType) {
            textArray.push(countryType);
        }
        if (textArray.length > 0) {
            return textArray.join(', ');
        }
        return '';
    }
    const showHoverText = (evt) => {
        $(evt.target).find('.hoverText').slideDown();
    }
    const hideHoverText = (evt) => {
        $(evt.target).find('.hoverText').slideUp();
    }
    const hideOtherHoverTexts = (evt) => {
        $(evt.target).siblings().find('.hoverText').slideUp();
    }

    return (
    <tr className="basketRow" onMouseEnter={hideOtherHoverTexts}>
        <td className="basketProdImg" onMouseEnter={showHoverText} onMouseLeave={hideHoverText}>
            <div className="basketImageContainer">
                <img src={`/uploads/products/${product.id}.png`} alt={product.name}/>
            </div>
            <Link to={url}>{product.name}<span className="hoverText">{hoverText()}</span></Link>
        </td>
        <td className="tbl-quantity">
            <button onClick={dec}>-</button>
            <input type="number" value={quantity} onChange={handleOnChange} />
            <button onClick={inc}>+</button>
        </td>

        { /*giftQuantity ?
            <td className="tbl-quantity">
                <button onClick={decGift}>-</button>
                <input type="number" value={giftQuantity} onChange={handleOnChangeGift}  />
                <button onClick={incGift}>+</button>
            </td>
            :
            <td>
                <input type="checkbox" id={`giftCheckbox_${product.id}`} className="giftCheckbox" onChange={handleOnChangeGiftWrapped} /><label htmlFor={`giftCheckbox_${product.id}`}>Yes</label>
        </td> */} 

        <td className="tbl-price">{sum}</td>
    </tr>)
}

export default BasketRow;