import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './subscriptions.css';
import { formatPrice } from '../../../utils/FormatPrice';

class Subscriptions extends React.Component {
    constructor(props){
        super(props);
        this.CreateSubscriptions = this.CreateSubscriptions.bind(this);
    }

    CreateSubscriptions(){
        const { subscriptions } = this.props;
        //console.log(subscriptions);
        let returnArray = [];
        for(let i = 0; i < subscriptions.length; i ++)
        {
            const imgUrl = `./uploads/subscriptions/${subscriptions[i].id}.jpg`;
            //console.log(subscriptions);
            
            let sixMonthPrices = []
            for(let j = 0; j < subscriptions[i].bottleOptions[0].frequencies.length; j++){
                if ( subscriptions[i].bottleOptions[1].frequencies[j].months === 12 ) {
                    sixMonthPrices.push(<li> Yearly -   {formatPrice(subscriptions[i].bottleOptions[1].frequencies[j].pricePerMonth)} (per month)</li>);
                } else if (j !== 0){
                    sixMonthPrices.push(<li>{subscriptions[i].bottleOptions[0].frequencies[j].months} months - {formatPrice(subscriptions[i].bottleOptions[0].frequencies[j].pricePerMonth)} (per month)</li>);
                } else {
                    sixMonthPrices.push(<li>{subscriptions[i].bottleOptions[0].frequencies[j].months} months - {formatPrice(subscriptions[i].bottleOptions[0].frequencies[j].pricePerMonth)}</li>);
                }
                
            }

            let twelveMonthPrices = []
            for(let j = 0; j < subscriptions[i].bottleOptions[1].frequencies.length; j++){
                if ( subscriptions[i].bottleOptions[1].frequencies[j].months === 12 ) {
                    twelveMonthPrices.push(<li> Yearly -  {formatPrice(subscriptions[i].bottleOptions[1].frequencies[j].pricePerMonth)} (per month)</li>);
                } else if (j !== 0) {
                    twelveMonthPrices.push(<li>{subscriptions[i].bottleOptions[1].frequencies[j].months} months - {formatPrice(subscriptions[i].bottleOptions[1].frequencies[j].pricePerMonth)} (per month)</li>);
                } else {
                    twelveMonthPrices.push(<li>{subscriptions[i].bottleOptions[1].frequencies[j].months} months - {formatPrice(subscriptions[i].bottleOptions[1].frequencies[j].pricePerMonth)}</li>);
                }
            }


            returnArray.push(
            <div className="wineSubscriptionContainer">
                <div className="subscriptionTitle">
                    <h4>{subscriptions[i].name}</h4>
                </div>
                <div className="subscriptionInfo">
                    <div className="subscriptionImgContainer">
                        <img src={imgUrl}></img>
                    </div>
                    <div className="containerStyle1Light subscriptionInfoDiv">
                        <div>
                            {subscriptions[i].paragraphs.map(paragraph => {
                                return <p className={paragraph.class}>{paragraph.text}</p>
                            })}
                        </div>
                    </div>
                </div>

                <div id="subscriptionPricingContainer">
                    <div id="sixMonthContainer">
                        <div id="sixMonthSubscription">
                            <p className="subscriptionPriceHeader">6 bottle subscription</p>
                            <ul> 
                                {sixMonthPrices}
                            </ul>
                        </div>
                    </div>
                    <div id="twelveMonthContainer">
                        <div id="twelveMonthSubscription">
                            <p className="subscriptionPriceHeader">12 bottle subscription</p>
                            <ul>
                                {twelveMonthPrices}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
        //console.log(returnArray);
        return returnArray;
    }

    render() {
        updateNav();

        let subscriptions = [];
        subscriptions = this.CreateSubscriptions()

        return (
            <div className="subscription standardFontResponsive">
                <div>
                    <h4>Wine Subscriptions, Available to order soon!</h4>
                </div>
                <div className="subscriptionsBlBoard">
                    <div>                    
                        <p>Hello hello and welcome to the most tasty wine subscriptions around.</p>

                        <p>Below we have a selection of delights for you, each box ideal for different tastes.</p>

                        <p>Choose a one off box, a 3 month subscription, 6 month subscription or live it up and do it annually!</p>

                        <p>We just want to be your wine wingmen.</p>   
                    </div>
                </div>
                {subscriptions}
            </div >
        )
    }
}

export default Subscriptions; 