import $ from 'jquery';

export function shiftFocus() {
    const address1 = $('#address1').val() || '';
    if (address1) {
      const indexOf = address1.indexOf(' ');
      const cursorStart = 0;
      const cursorEnd = indexOf === -1 ? 0 : indexOf;
      $('#address1').focus().prop("selectionStart", cursorStart).prop("selectionEnd", cursorEnd);
    }
}