import React, { Component } from 'react';
import './snackbar.css';

class SnackBar extends Component {
  
  render() {
    return (
      <div id="snackbar">
        <img id="toast-icon" alt="Notification icon" />
        <span id="toast-text"></span>
      </div>
    );
  }
}

export default SnackBar;
