import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import FormInput from '../../generic/formInput/formInput';
import SubmitButton from '../../generic/submitButton/submitButton';
import './loginForm.css';

class LoginForm extends React.Component {
    handleSubmit = async(evt) => {
        const $submitbtn = $('.submitButton');
        try {
            $submitbtn.prop('disabled', true);
        } catch (error) {
            console.log(error);
        }
        evt.preventDefault();
        const email = $('#email').val();
        const password = $('#password').val();
        const isChecked = $('#rememberMe').prop("checked");
        const loginSuccess = await this.props.onSubmit(email, password, isChecked);
        if (loginSuccess === false) {
            try {
                $submitbtn.prop('disabled', false);
            } catch (error) {
                console.log(error);
            }
        }
    }
    render() {
        return (
            <form className="loginComponent" onSubmit={this.handleSubmit}>
                <FormInput id="email" name="email" placeholder={'Email*'} required={true} />
                <FormInput id="password" type="password" name="current-password" placeholder={'Password*'} required={true} />
                <div className="flexBetween">
                    <div>
                        <input id="rememberMe" type="checkbox"></input>
                        <label htmlFor="rememberMe">Remember me</label>
                    </div>
                    <div className="forgotPasswordContainer linkHighlight">
                        <p><Link to="/account/forgot-password" className="flRight">Forgot password?</Link></p>
                    </div>
                </div>
                <SubmitButton onClick={this.handleSubmit} />
            </form>
        )
    }
}

export default LoginForm;