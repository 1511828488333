import React from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery';
import MailingWidget from '../mailingWidget/mailingWidget';
import './navBar.css';
import basket from '../../materials/images/basket.png';
import logo from '../../materials/images/pngsTrans/carafe Range 2-01.png';
import accountIcon from '../../materials/icons/baseline_account_circle_black_48dp.png';
import SnackBar from '../generic/snackbar/snackbar';
import axios from 'axios';
import { isValidEmail } from '../../utils/emailUtils';
import Confetti from '../../components/shop/confetti/confettiInner';

class NavBar extends React.Component {
    openMenu() {
        $('.navMobile').addClass('navOpen');
        $('#hamburger').children('div').addClass('change');
    }
    closeMenu() {
        $('.navMobile').removeClass('navOpen');
        $('#hamburger div').removeClass('change');
    }
    toggleMenu() {
        $('.navMobile').toggleClass('navOpen');
        $('#hamburger div').toggleClass('change');
    }

    hideModal = () => {
        $('#privacyAgreeModal').slideUp();
        $('#privacyAgreeModalBg').removeClass('modalIn');
    }

    submitEmail = async () => {
        const $btn = $('#navMailingBtn');
        const $emailInput = $('#navMailing');
        const emailVal = $emailInput.val();

        if (!isValidEmail(emailVal)) {
            //console.log('Email invalid format');
            this.props.signedUpToast(false);
            return;
        }

        this.hideModal();

        if (emailVal) {
            $btn.prop('disabled', 'true');
            $emailInput.prop('disabled', 'true');
            const email = emailVal.toLowerCase();

            // Hit API
            try {
                const url = '/api/mailinglist/signup';
                const consent = true;
                const response = await axios({
                    url: url,
                    method: 'POST',
                    data: { email, consent }
                });

                const { data } = response;
                const { success } = data;

                if (success !== true) {
                    this.props.signedUpToast(false);
                    return;
                } else {
                    // Success
                    $emailInput.val('');
                    this.props.signedUpToast(true);

                    $emailInput.prop('placeholder', 'Signed up!');
                    localStorage.setItem('mailingListSignup', '1');
                }
                $btn.prop('disabled', false);
                $emailInput.prop('disabled', false);

            } catch (error) {
                console.log(error);
                this.props.signedUpToast(false);
                $btn.prop('disabled', false);
                $emailInput.prop('disabled', false);
            }
            const alreadySignedUp = localStorage.getItem('mailingListSignup');
            if (alreadySignedUp) {
                $('#navSubscribeLink').addClass('sideline');
                $('#navMailingForm').addClass('sideline')
                $('#navSubscribeLink').children('span.sub-text').text('');
                $('#navSubscribeLink').removeClass('open');
                $('#navMailingForm').slideUp();
            }
        }
    }

    render() {
        let numberOfItemsInBasket = this.props.itemsInBasket > 0 ? <span id="itemsInBasketNumber">{this.props.itemsInBasket}</span> : "";
        const { hasToken, toConfetti } = this.props;
        const accountUrl = hasToken ? '/account/' : '/account/login';

        const hasExtraButton = localStorage.getItem('navExtraButtonText');
        let buttonObj = { "link" : '', "text" : '' };
        if (hasExtraButton) {
            const extraButtonLink = localStorage.getItem('navExtraButtonLink');
            if (extraButtonLink) {
                buttonObj.text = hasExtraButton;
                buttonObj.link = extraButtonLink;
            }
        }

        return (
            <div className="navBarParent fullWidth" onMouseLeave={this.closeMenu}>

                { hasExtraButton ? <Link className="navExtraButton" to={buttonObj.link}>{buttonObj.text}</Link> : null }

                <SnackBar />
                { toConfetti ? <Confetti toConfetti={toConfetti} /> : null}

                <div id="privacyAgreeModal">
                    <p>By submitting your email, you agree with our <a href="/help/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                    <p className="navPrivacyConsent">
                        <button type="text" onClick={this.submitEmail}>I agree</button>&nbsp;
                        <button type="text" onClick={this.hideModal}>Cancel</button>
                    </p>
                </div>
                <div id="privacyAgreeModalBg"></div>

                <div className="navBarContainer">
                    <header id="logoContainer">
                        <Link to="/"><img src={logo} className="carafeLogo" title="Carafe Logo" alt="Carafe Logo" /></Link>
                    </header>

                    <div className="listBasketBanner">

                        <nav className="navLinks">
                            <div className="listAndBasket">
                                <div id="menuMobile" onClick={this.toggleMenu} onMouseEnter={this.openMenu}>
                                    <div id="hamburger">
                                        <div className="bar1"></div>
                                        <div className="bar2"></div>
                                        <div className="bar3"></div>
                                    </div>
                                </div>
                                <ul>
                                    <li className="homePageItem"><Link to="/">Home</Link></li>
                                    <li className="whoWeArePageItem"><Link to="/who-we-are">Who We Are</Link></li>
                                    <li className="shopPageItem"><Link to="/shop">Shop</Link></li>
                                    <li className="subscriptionsPageItem"><Link to="/subscriptions">Subscriptions</Link></li>
                                    <li className="contactPageItem"><Link to="/contact">Contact</Link></li>
                                </ul>
                                <div id="basket"><Link to="/shop/basket" id="basketLink"><img src={basket} id="basketImg" alt="Basket icon"></img>{numberOfItemsInBasket}</Link></div>
                                <div id="accountOverviewIcon"><Link to={accountUrl}><img src={accountIcon} id="accountIcon" alt="Account icon"></img></Link></div>
                            </div>
                        </nav>

                    </div>
                </div>

                <ul onClick={this.closeMenu} className="navMobile fullWidth">
                    <li className="homePageItem"><Link to="/">Home</Link></li>
                    <li className="whoWeArePageItem"><Link to="/who-we-are">Who We Are</Link></li>
                    <li className="shopPageItem"><Link to="/shop">Shop</Link></li>
                    <li className="subscriptionsPageItem"><Link to="/subscriptions">Subscriptions</Link></li>
                    <li className="contactPageItem"><Link to="/contact">Contact</Link></li>
                    <li><Link to={accountUrl}>{hasToken ? "Account" : "Log In"}</Link></li>
                </ul>

                <MailingWidget signedUpToast={this.props.signedUpToast} />

                <div id="topOfBody"></div>
            </div>
        )
    }
}

export default NavBar;