import axios from 'axios';
import $ from 'jquery';
import { isValid } from 'postcode';

// Change postcode to UPPERCASE
export function onChangePostcodeHandler(evt, stateUpdateVars) {
    //console.log('in util');
    const userInput = evt.target.value;
    const valUpper = userInput.toUpperCase();
    evt.target.value = valUpper;
    //console.log(valUpper);
    if (stateUpdateVars) {
        //console.log('got stateupdatevars');
        const updateCheckoutAddressWhole = stateUpdateVars.updateCheckoutAddressWhole || null;
        const addressType = stateUpdateVars.addressType || null;
        if (updateCheckoutAddressWhole && addressType) {
            if (isValid(valUpper)) {
                //console.log('is valid postcode');
                postcodeApiFallback(valUpper, stateUpdateVars);
                // also pass callback for whole state update
            }
        }
    } else {
        if (isValid(valUpper)) {
            postcodeApiFallback(valUpper);
        }
    }
}

// Change postcode to UPPERCASE
export function onChangePostcodeHandlerCheckout(evt, stateUpdateVars) {
    //console.log('in util');
    const userInput = evt.target.value;
    const valUpper = userInput.toUpperCase();
    evt.target.value = valUpper;
    //console.log(valUpper);
    if (stateUpdateVars) {
        //console.log('got stateupdatevars');
        const updateCheckoutAddressWhole = stateUpdateVars.updateCheckoutAddressWhole || null;
        const addressType = stateUpdateVars.addressType || null;
        if (updateCheckoutAddressWhole && addressType) {
            if (isValid(valUpper)) {
                //console.log('is valid postcode');
                postcodeApiFallbackCheckout(valUpper, stateUpdateVars);
                // also pass callback for whole state update
            }
        }
    } else {
        //console.log('No state update vars');
    }
}

// Postcode => address => inputs
export function autoComplete(addressesJson) {
    const { address1, city, county, country, postcode } = addressesJson;

    //const number0 = numbers[0] || {};
    //const number = number0['number'] || '';
    //const street = number0['street'] || '';
    //let streetOutput = '';
    const indexOf = address1.indexOf(' ');
    /*
    if (number && street) {
        streetOutput = number + ' ' + street;
        indexOf = number.length;
    } else if (number) {
        indexOf = number.indexOf(",");
        streetOutput = number.replace(',', '');
    }
    */
    const cursorStart = 0;
    const cursorEnd = indexOf === -1 ? 0 : indexOf;

    const address2 = '';
    $('#address2').val(address2);
    $('#city').val(city);
    $('#county').val(county);
    $('#country').val(country);
    $('#postcode').val(postcode);
    $('#address1').val(address1).focus().prop("selectionStart", cursorStart).prop("selectionEnd", cursorEnd);
    const sameAsShipping = $('#same-address').val();
    //console.log('sameAsShipping', sameAsShipping);
    if (sameAsShipping) {
        const inStreet = $('#address1').val();
        copyInputs(inStreet, address2, city, county, country, postcode);
    }
}

// Internal API call as backup
export async function postcodeApiFallback(postcode, stateUpdateVars) {
    //console.log('in api callback');
    const url = '/api/shop/postcode/addresses';
    const method = 'POST';
    const data = { postcode };
    try {
        const response = await axios({ url, method, data });
        const resJson = await response.data;
        if (resJson.success) {
            const addressesJson = resJson.address;
            //console.log(addressesJson);
            autoComplete(addressesJson);
            if (stateUpdateVars) {
                const { updateCheckoutAddressWhole, addressType } = stateUpdateVars;
                const { address1, city, county, country, postcode } = addressesJson;
                const addressObj = { address1, city, county, country, postcode };
                updateCheckoutAddressWhole(addressType, addressObj);
            }
        } else {
            console.log('API error of some kind');
        }
    } catch (error) {
        console.log(error);
    }
}

// Internal API call as backup
export async function postcodeApiFallbackCheckout(postcode, stateUpdateVars) {
    //console.log('in api callback');
    const url = '/api/shop/postcode/addresses';
    const method = 'POST';
    const data = { postcode };
    try {
        const response = await axios({ url, method, data });
        const resJson = await response.data;
        if (resJson.success) {
            const addressesJson = resJson.address;
            //console.log(addressesJson);
            //autoComplete(addressesJson);
            if (stateUpdateVars) {
                const { updateCheckoutAddressWhole, addressType } = stateUpdateVars;
                const fullName = $('#fullName').val() || '';
                const { address1, city, county, country, postcode } = addressesJson;
                const addressObj = { address1, city, county, country, postcode, fullName };
                const switchFocus = true;
                updateCheckoutAddressWhole(addressType, addressObj, switchFocus);
            }
        } else {
            console.log('API error of some kind');
        }
    } catch (error) {
        console.log(error);
    }
}

// Copy all Shipping to Billing
export function copyInputs(inStreet, inStreet2, inCity, inCounty, inCountry, inPostcode) {
    const street = inStreet || $('#address1').val();
    const street2 = inStreet2 || $('#address2').val();
    const city = inCity || $('#city').val();
    const region = inCounty || $('#county').val();
    const country = inCountry || $('#country').val();
    const postcode = inPostcode || $('#postcode').val();
    $('#address1-billing').val(street);
    $('#address2-billing').val(street2);
    $('#city-billing').val(city);
    $('#county-billing').val(region);
    $('#country-billing').val(country);
    $('#postcode-billing').val(postcode);
}