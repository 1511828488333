import React, { Component } from 'react';
import { csvToStockUpdateObjs } from '../../../../../utils/csvUtils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

class StockUpdatePage extends Component {

    returnCsvToTable = () => {
        const productObjects = csvToStockUpdateObjs($('#csv').val().toString().trim());
        const noteText = $('#note').val().toString().trim();
        const tableStart = `<table>
            <thead>
                <tr>
                    <td>ID</td>
                    <td>Stock</td>
                </tr>
            </thead>
        <tbody>`;

        const tableEnd = `</tbody>
        </table><br /><p><b>Note:</b><br />${noteText}</p>`;

        if (productObjects) {
            const tableBody = productObjects.map(productObj => {
                const { id, stock } = productObj;
                return `<tr>
                    <td>${id}</td>
                    <td>${stock}</td>
                </tr>`;
            });
            const wholeTable = tableStart + tableBody.join('') + tableEnd;
            console.log(wholeTable);
            $('#csv-table').html(wholeTable);
        } else {
            const wholeTable = tableStart + tableEnd;
            console.log(wholeTable);
            $('#csv-table').html(wholeTable);
        }
    }

    sendToApi = async () => {
        try {
            const url = '/api/admin/stock-update/with-note';
            const productObjects = csvToStockUpdateObjs($('#csv').val().toString().trim());
            const note = $('#note').val().toString().trim();
            const adminToken = localStorage.getItem('adminToken');
            const response = await axios({
                url,
                method: 'POST',
                data: { productObjects, note, adminToken }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    console.log(data);
                    const { success } = data;
                    if (success) {
                        const { responseObject } = data;
                        if (responseObject) {
                            console.log(responseObject);
                            return;
                        }
                    }
                }
                console.log('Error with response');
            } else {
                console.log('No response')
            }
        } catch (error) {
            console.log(error);
        }
    }





    render() {
        const sampleCsv =
            `1,      9
2,      6
101,    0`;

        return (
            <div className="testPage containerFluidMedium">

                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>

                <div style={{ "display": "flex", "flexDirection": "row" }}>
                    <div style={{ "margin": "1rem"}}>
                        <h6>CSV Source:</h6>
                        <textarea id="csv" rows="10" cols="50" defaultValue={sampleCsv} style={{ "padding": "0.5rem" }}>
                        </textarea>
                    </div>

                    <div style={{ "margin": "1rem"}}>
                        <h6>Note:</h6>
                        <textarea id="note" rows="10" cols="50" defaultValue="Manual stock take"  style={{ "padding": "0.5rem" }}>
                        </textarea>
                    </div>
                </div>

                <br />

                <button onClick={this.returnCsvToTable}>CONVERT to Sample Table</button><br />

                <br />

                <div>
                    <h6>Sample Table:</h6>
                    <div id="csv-table" style={{ "minHeight": "300px", "backgroundColor": "#eee", "maxWidth": "400px" }}>
                        <table>
                            <thead>
                                <tr>
                                    <td>ID</td>
                                    <td>Stock</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>9</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>6</td>
                                </tr>
                                <tr>
                                    <td>101</td>
                                    <td>0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <br />

                <button onClick={this.sendToApi}>SEND TO API</button>

            </div>
        )
    }

}

export default StockUpdatePage;