import React from 'react';
import './tile.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
//import Stain from '../../../components/shop/stain/stain';
import StainNew from '../../../materials/icons/stain-new-sm.png';
import StainNewDark from '../../../materials/icons/stain-new-sm-dark.png';
import StainCharity from '../../../materials/icons/stain-charity-sm.png';
import StainCharityDark from '../../../materials/icons/stain-charity-sm-dark.png';
import LoveCycle from '../../../materials/icons/lovecycle.png';
import { formatPrice } from '../../../utils/FormatPrice';
import AddToCartBtn from '../../../components/shop/addToCartBtn/addToCartBtn';

class Tile extends React.Component {

    onClickHandler = () => {
        const { onClickOverwrite } = this.props;
        if (onClickOverwrite) {
            onClickOverwrite();
        }
    }

    tileShowCart = (evt) => {
        const $tile = $(evt.target);
        $tile.siblings().children('div.tileOverlay').slideUp();
        //const $tile = $(evt.target).closest('div.tileContainer');
        //$tile.siblings().find('div.tileOverlay').slideUp();
        $tile.children('div.tileOverlay').slideDown();
    }
    showCart = (evt) => {
        const $tile = $(evt.target).closest('div.tileContainer');
        $tile.siblings().children('div.tileOverlay').slideUp();
        //const $tile = $(evt.target).closest('div.tileContainer');
        //$tile.siblings().find('div.tileOverlay').slideUp();
        $tile.children('div.tileOverlay').slideDown();
    }
    hideCart = (evt) => {
        const $tile = $(evt.target);
        //const $tile = $(evt.target).closest('div.tileContainer');
        $tile.children('div.tileOverlay').slideUp();
    }

    showCartClick = (evt) => {
        const $tile = $(evt.target).closest('div.tileContainer');
        //$tile.siblings().find('div.tileOverlay').slideUp();
        $tile.children('div.tileOverlay').slideDown();
    }
    
    getStains = () => {
        const { product, isDarkBg } = this.props;
        const { charity, isNew } = product;

        let stains = [];
        let counter = 0;
        const stainKey = product.id + '-stain-';

        if (isNew) {
            const stain = isDarkBg ? <img key={stainKey+counter++} src={StainNewDark} /> : <img key={stainKey+counter++} src={StainNew} />;
            stains.push(stain);
        }
        if (charity) {
            const stain = isDarkBg ? <img key={stainKey+counter++} src={StainCharityDark} /> : <img key={stainKey+counter++} src={StainCharity} />;
            stains.push(stain);
            const charityLogo = <img key={stainKey+counter++} src={LoveCycle} />;
            stains.push(charityLogo);
        }

        if (stains.length > 0) {
            return (
                <div className="stainsContainer">
                    {stains}
                </div>
            );
        }
        return null;
    }

    render() {
        const { product, addRemoveToBasket } = this.props;
        const { id, nameNoWhSpc, producer, name, vintage, price, grapeType, stock } = product;
        const img = `/uploads/products/${id}.png`;
        //const { URL, img, producer, name, vintage, price, isNew, isCharity, grapeType } = this.props;
        // const { URL, img, producer, name, region, country, vintage, price, isNew, isCharity, grapeType } = this.props;
        const productURL = `/shop/product/${nameNoWhSpc}`;
        //const dateNow = Math.floor(Date.now() / 1000);
        //const oneMonthUnix = 86400 * 31;
        //const oneMonthAgo = dateNow - oneMonthUnix;
        //const isNew = arrivalTime > oneMonthAgo;

        const outOfStock = stock < 1 ? ' outOfStock' : '';

        return (
            <div className={"tileContainer"+outOfStock} onClick={this.onClickHandler} onMouseLeave={this.hideCart} onMouseEnter={this.tileShowCart}>

                <Link to={productURL} className="tileLink" onMouseEnter={this.showCart}>
                    <div className="productImageContainer">
                        <img src={img} title={name} className="tile-img" alt={name} />
                        {this.getStains()}
                    </div>
                </Link>

                <div className="productDescriptionContainer courierText" onClick={this.showCartClick}  onMouseEnter={this.showCart}>
                    <table>
                        <tbody>
                            <tr>
                                <td className="productName"><span className="bold">{producer}</span>, {name}, {grapeType} <br /></td>
                            </tr>
                            <tr>
                                <td>{vintage || ''}<b>{formatPrice(price)}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="tileOverlay">
                    <AddToCartBtn addRemoveToBasket={addRemoveToBasket} product={product} inStock={stock ? true : false} />
                </div>

            </div>
        )
    }
}

export default Tile;