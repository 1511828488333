import React from 'react';
import './formInput.css';

class FormInput extends React.Component {
    handleOnChange = (evt) => {
        if (this.props.onChange) {
            this.props.onChange(evt);
        }
    }
    render() {
        const { id, name, placeholder, defaultValue } = this.props;
        const type = this.props.type || 'text';
        const isRequired = this.props.required || false;
        const autoComplete = this.props.autoComplete || "";
        const autofocus = this.props.autofocus || null;
        const className = this.props.className || "";
        const classNameFull = 'formInput ' + className;
        return (
            defaultValue ? 
                <input 
                    className={classNameFull} 
                    id={id} 
                    type={type} 
                    name={name} 
                    placeholder={placeholder} 
                    autoComplete={autoComplete} 
                    required={isRequired}
                    autoFocus={autofocus}
                    defaultValue={defaultValue}
                    >
                </input>
            : <input 
                className={classNameFull} 
                id={id} 
                type={type} 
                name={name} 
                placeholder={placeholder} 
                autoComplete={autoComplete} 
                required={isRequired}
                autoFocus={autofocus}
                onChange={this.handleOnChange}
                >
            </input>
        );
    }
}

export default FormInput;
