import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './getLabels.css';

import axios from 'axios';

class AdminOrderGetLabelsPage extends Component {

    findOrder = async (evt, containerId) => {
        const $errorContainer = $('#responseContainer');
        $errorContainer.text('');

        const $orderInput = $('#order-id-input-' + containerId);
        const orderInputVal = parseInt($orderInput.val());
        if (isNaN(orderInputVal)) {
            $errorContainer.text('Invalid order number');
            return;
        }
        const storageString = 'orderLabel-' + orderInputVal;

        const $imgs = $(evt.target).parent().siblings('img');

        $imgs.attr("src", '');
        $('#img-print-' + containerId).attr("src", '');

        const storageSrc = localStorage.getItem(storageString);
        if (!storageSrc) {
            // fetch
            try {
                const adminToken = localStorage.getItem('adminToken');
                const url = '/api/admin/dhl/get-label';

                const response = await axios({
                    url,
                    method: 'PUT',
                    data: { adminToken, orderId: orderInputVal }
                });
                if (response) {
                    const { data } = response;
                    if (data) {
                        const { base64ImgSrc, estimatedWeight } = data;
                        if (base64ImgSrc) {
                            localStorage.setItem(storageString, base64ImgSrc);
                            $imgs.attr("src", base64ImgSrc);
                            $('#img-print-' + containerId).attr("src", base64ImgSrc);
                            $imgs.addClass('label-img');
                        } else if (estimatedWeight) {
                            const targetInputId = '#weight-' + containerId;
                            const $input = $(targetInputId);
                            $input.val(estimatedWeight);
                        } else {
                            $errorContainer.text('API did not return label');
                        }
                    }
                } else {
                    $errorContainer.text('Error with API');
                }
            } catch (error) {
                console.log(error);
                $errorContainer.text('Error generating label');
            }
        } else {
            // put
            console.log($imgs);
            $imgs.attr("src", storageSrc);
            $('#img-print-' + containerId).attr("src", storageSrc);
            $imgs.addClass('label-img');
        }
    }


    genLabel = async (evt, containerId) => {
        $(evt.target).prop('disabled', true);
        const $errorContainer = $('#responseContainer');
        $errorContainer.text('');

        const inputId = '#order-id-input-' + containerId;
        const $orderInput = $(inputId);

        const orderInputVal = parseInt($orderInput.val());
        if (isNaN(orderInputVal)) {
            $errorContainer.text('Invalid order number');
            return;
        }
        const storageString = 'orderLabel-' + orderInputVal;

        const $imgs = $(evt.target).parent().siblings('img');

        $imgs.attr("src", '');
        $('#img-print-' + containerId).attr("src", '');

        const storageSrc = localStorage.getItem(storageString);
        if (!storageSrc) {
            // fetch

            const collectionJobNumber = $('#collectionJobNumber').val() || '';
            const itemCount = $('#itemCount').val() || '';
            const serviceKey = $('#serviceKey').val() || '';

            const weight = $('#weight-' + containerId).val();
            //const length = $('#length-' + containerId).val();
            //const width = $('#width-' + containerId).val();
            //const height = $('#height-' + containerId).val();

            try {
                const adminToken = localStorage.getItem('adminToken');
                const url = '/api/admin/dhl/gen-label';

                const response = await axios({
                    url,
                    method: 'PUT',
                    data: {
                        adminToken,
                        orderId: orderInputVal,
                        collectionJobNumber,
                        itemCount,
                        serviceKey,
                        totalWeight: weight
                    }
                });
                if (response) {
                    const { data } = response;
                    if (data) {
                        const { base64ImgSrc } = data;
                        if (base64ImgSrc) {
                            localStorage.setItem(storageString, base64ImgSrc);
                            $imgs.attr("src", base64ImgSrc);
                            $('#img-print-' + containerId).attr("src", base64ImgSrc);
                            $imgs.addClass('label-img');
                        } else {
                            $errorContainer.text('API did not return label');
                        }
                    }
                } else {
                    $errorContainer.text('Error with API');
                }
            } catch (error) {
                console.log(error);
                $errorContainer.text('Error generating label');
            }
        } else {
            // put
            $imgs.attr("src", storageSrc);
            $('#img-print-' + containerId).attr("src", storageSrc);
            $imgs.addClass('label-img');
        }
        $(evt.target).prop('disabled', false);
    }



    displayForPrinting = () => {
        // window.open("https://www.w3schools.com");
        $('.navBarParent').hide();
        $('footer').hide();
        $('#label-overlay').show();
        $('#label-overlay').siblings().hide();
        $('.admin-pages').addClass('showingLabels');
    }

    backToLabels = () => {
        $('.navBarParent').show();
        $('footer').show();
        $('#label-overlay').hide();
        $('#label-overlay').siblings().show();
        $('.admin-pages').removeClass('showingLabels');
    }

    getLatestCollection = () => {
        const dhlPreviousPickupString = localStorage.getItem('dhlCollectionRequest');
        if (!dhlPreviousPickupString) return;
        console.log('Found in storage');
        const dhlPreviousPickup = JSON.parse(dhlPreviousPickupString);
        if (!dhlPreviousPickup) return;
        console.log('Parsed');
        const collectionJobNumber = dhlPreviousPickup['collectionJobNumber'];
        if (collectionJobNumber) return collectionJobNumber;
    }

    render() {

        const adminToken = localStorage.getItem('adminToken');
        if (!adminToken) {
            console.log('redirect');
            window.location.href = '/admin-archie-96/login';
        } else {
            console.log('got token:');
        }

        const findOrder = this.findOrder;
        const genLabel = this.genLabel;

        const latestCollectionJobNumber = this.getLatestCollection() || '';

        return (
            <div className="admin-pages containerFluidMedium">

                <div id="label-overlay" onClick={this.backToLabels}>
                    <div>
                        <img id="img-print-1"></img>
                        <img id="img-print-2"></img>
                    </div>
                    <div>
                        <img id="img-print-3"></img>
                        <img id="img-print-4"></img>
                    </div>
                </div>

                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>

                <br />
                <br />

                <h6>Get Labels:</h6>
                <br />

                <div>
                    <label htmlFor="collectionJobNumber"><b>Collection Job Number:</b></label><br />
                    <input id="collectionJobNumber" placeholder="TN450420455" defaultValue={latestCollectionJobNumber}></input><br />
                    <br />
                    <label htmlFor="itemCount"><b>Item count:</b></label><br />
                    <input id="itemCount" type="number" placeholder="Item count" defaultValue="1"></input><br />
                    <br />
                    <label htmlFor="serviceKey"><b>Service Key:</b></label><br />
                    <input id="serviceKey" type="number" placeholder="220 is next day" defaultValue="220"></input><br />
                </div>

                <br />
                <p id="responseContainer"></p>
                <br />

                <div id="found-orders">

                    <div id="found-order-1" className="found-order">
                        <div className="item-finder">
                            <label htmlFor="order-id-input-1"><b>Get Order by ID:</b></label><br />
                            <input id="order-id-input-1" type="number" placeholder="Order ID e.g: 1"></input><br />
                            <br />
                            <button type="text" onClick={(evt) => { findOrder(evt, 1) }}>Find</button><br />
                        </div>

                        <br />
                        <hr />
                        <br />

                        <div className="package-details">
                            <label htmlFor="weight-1"><b>Weight:</b></label><br />
                            <input id="weight-1" type="number" placeholder="Weight in KG"></input><br />
                            <br />
                            <button type="text" onClick={(evt) => { genLabel(evt, 1) }}>Generate label</button><br />
                        </div>

                        <img />
                    </div>

                    <div id="found-order-2" className="found-order">
                        <div className="item-finder">
                            <label htmlFor="order-id-input-2"><b>Get Order by ID:</b></label><br />
                            <input id="order-id-input-2" type="number" placeholder="Order ID e.g: 2"></input><br />
                            <br />
                            <button type="text" onClick={(evt) => { findOrder(evt, 2) }}>Find</button><br />
                        </div>

                        <br />
                        <hr />
                        <br />

                        <div className="package-details">
                            <label htmlFor="weight-2"><b>Weight:</b></label><br />
                            <input id="weight-2" type="number" placeholder="Weight in KG"></input><br />
                            <br />
                            <button type="text" onClick={(evt) => { genLabel(evt, 2) }}>Generate label</button><br />
                        </div>

                        <img />
                    </div>

                    <div id="found-order-3" className="found-order">
                        <div className="item-finder">
                            <label htmlFor="order-id-input-3"><b>Get Order by ID:</b></label><br />
                            <input id="order-id-input-3" type="number" placeholder="Order ID e.g: 3"></input><br />
                            <br />
                            <button type="text" onClick={(evt) => { findOrder(evt, 3) }}>Find</button><br />
                        </div>

                        <br />
                        <hr />
                        <br />

                        <div className="package-details">
                            <label htmlFor="weight-3"><b>Weight:</b></label><br />
                            <input id="weight-3" type="number" placeholder="Weight in KG"></input><br />
                            <br />
                            <button type="text" onClick={(evt) => { genLabel(evt, 3) }}>Generate label</button><br />
                        </div>

                        <img />
                    </div>

                    <div id="found-order-4" className="found-order">
                        <div className="item-finder">
                            <label htmlFor="order-id-input-4"><b>Get Order by ID:</b></label><br />
                            <input id="order-id-input-4" type="number" placeholder="Order ID e.g: 4"></input><br />
                            <br />
                            <button type="text" onClick={(evt) => { findOrder(evt, 4) }}>Find</button><br />
                        </div>

                        <br />
                        <hr />
                        <br />

                        <div className="package-details">
                            <label htmlFor="weight-4"><b>Weight:</b></label><br />
                            <input id="weight-4" type="number" placeholder="Weight in KG"></input><br />
                            <br />
                            <button type="text" onClick={(evt) => { genLabel(evt, 4) }}>Generate label</button><br />
                        </div>

                        <img />
                    </div>

                </div>

                <br />
                <br />
                <button type="text" onClick={this.displayForPrinting}>Display labels for printing</button>
                <br />
                <br />

            </div>
        );
    }

}

export default AdminOrderGetLabelsPage;