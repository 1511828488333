import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import robynDesign from '../../materials/icons/robyn-design.jpg';
import asSeenInSupport from '../../materials/icons/as-seen-in-support.png';
import tncard from '../../materials/icons/tncard.png';
import tncardlong from '../../materials/icons/tncard-long.png';
import tncardtight from '../../materials/icons/tncard-tight.jpg';
import fbIcon from '../../materials/icons/fb.png';
import inIcon from '../../materials/icons/in.png';
import instaIcon from '../../materials/icons/insta.png';
import onePercentIcon from '../../materials/icons/1pcftp_logo.png';
import './footer.css';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footerContainer">
                <nav className="footerLinks">
                    <ul>
                        <li className="deliveryPageItem"><Link to="/help/delivery">Delivery</Link></li>
                        <li className="careersPageItem"><Link to="/help/careers">Careers</Link></li>
                        <li className="faqPageItem"><Link to="/help/faq">FAQ</Link></li>
                        <li className="sustainabilityPageItem"><Link to="/help/sustainability">Sustainability</Link></li>
                        <li className="charityPageItem"><Link to="/help/charity">Charity</Link></li>
                        <li className="privacyPageItem"><Link to="/help/privacy-policy">Privacy</Link></li>
                        <li className="covid19PageItem"><Link to="/help/covid19">Covid 19</Link></li>
                    </ul>
                </nav>
                <div id="footerGrid">

                    <div>
                        <div className="footerLeftIconContainer">
                            <a href="https://www.thetncard.com/" className="social-left" target="_blank"><img className="tn-logo-pad" src={tncardtight} alt="The TN Card"></img></a>
                            <a href="https://www.instagram.com/robyn.design/" className="social-left" target="_blank"><img src={robynDesign} alt="Robyn Design"></img></a>
                            <a href="https://supportlocalmagazine.co.uk/all-articles/2021/3/4/give-back-with-your-juice " className="social-left" target="_blank"><img src=
                            {asSeenInSupport} alt="Support Local Magazine"></img></a>
                        </div>
                    </div>

                    <div>
                        <p id="companyInfoText">Please drink responsibly <a href="https://www.drinksmart.com" target="_blank" rel="noreferrer">https://www.drinksmart.com</a></p>
                        <p id="companyInfoText">CARAFE Limited Companies No: 12127140</p>
                    </div>

                    <div className="socialAndOtherLinksContainer">
                        <div className="socialIconContainer">
                            <a href="https://www.facebook.com/carafeuk" className="socialIcon" target="_blank"><img src={fbIcon} alt="Facebook Carafe UK"></img></a>
                            <a href="https://www.linkedin.com/company/carafewine" className="socialIcon" target="_blank"><img src={inIcon} alt="LinkedIn Carafe Wine"></img></a>
                            <a href="https://www.instagram.com/carafewine/" className="socialIcon" target="_blank"><img src={instaIcon} alt="Instagram Carafe Wine"></img></a>
                            <a href="https://www.onepercentfortheplanet.org/" className="socialIcon" id="onePIcon" target="_blank"><img src={onePercentIcon} alt="One Percent for the Planet"></img></a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;