import React, { Component } from 'react';
import CountryInput from '../../generic/formInput/countryInput/countryInput';
import './addressInputShipping.css';

export default class AddressInput extends Component {

    onChangeHandler = (evt) => {
        const { updateCheckoutAddress } = this.props;
        if (updateCheckoutAddress) {
            const addressType = 'shipping';
            const { target } = evt;
            const { id, value } = target;
            //console.log(value);
            updateCheckoutAddress(id, value);
        }
        return;
    }

    render() {
        const { shippingAddress } = this.props;
        const { fullName, address1, address2, city, county, country, postcode } = shippingAddress;

        return (
            <fieldset className="addressForm">
                <legend>Shipping</legend>
                <input id="fullName" type="text" name="full-name" placeholder={'Full name*'} value={fullName} onChange={this.onChangeHandler} required />
                <input id="address1" type="text" name="address-line1" placeholder={'Address Line 1*'} value={address1} onChange={this.onChangeHandler} required />
                <input id="address2" type="text" name="address-line2" placeholder={'Address Line 2'} value={address2} onChange={this.onChangeHandler} required />
                <input id="city" type="text" name="address-locality" placeholder={'Town/City*'} value={city} onChange={this.onChangeHandler} required />
                <input id="county" type="text" name="address-county" placeholder={'Region/County*'} value={county} onChange={this.onChangeHandler} required />
                <CountryInput id="country" country={country} onChange={this.onChangeHandler} />
                <input id="postcode" type="text" name="address-postcode" placeholder={'Postcode*'} value={postcode} onChange={this.onChangeHandler} required />
            </fieldset>
        );
    }
}