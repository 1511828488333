import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './notPicked.css';
import axios from 'axios';

let productMenu = {};

class AdminNotPickedPage extends Component {

    sendPackingStatusAPI = async (orderId, boolVal, target) => {
        try {
            const adminToken = localStorage.getItem('adminToken');
            const url = '/api/admin/orders/change-packing-status';
            const response = await axios({
                url,
                method: 'PUT',
                data: { adminToken, orderId, boolVal }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    if (data['success']) {
                        const orderDocsString = sessionStorage.getItem('orderDocs');
                        if (orderDocsString) {
                            const orderDocs = JSON.parse(orderDocsString);
                            let newOrderDocs = [];
                            if (orderDocs) {
                                for (let i = 0; i < orderDocs.length; i++) {
                                    const orderDoc = orderDocs[i];
                                    if (orderDoc.id === orderId) {
                                        orderDoc.hasBeenPicked = boolVal;
                                    }
                                    newOrderDocs.push(orderDoc);
                                }
                                const newOrderDocsString = JSON.stringify(newOrderDocs);
                                sessionStorage.setItem('orderDocs', newOrderDocsString);

                                const $button = $(target);
                                $button.prop('disabled', false).addClass('btn-hide');;
                                $button.siblings('button').prop('disabled', false).removeClass('btn-hide');
                                if (boolVal) {
                                    // Button to undo
                                    //$button.text('Undo packing status');
                                } else {
                                    //$button.text('Mark as packed');
                                }
                            }
                        }
                    }
                }
                console.log('Data error');
            } else {
                console.log('API response error');
            }
            console.log('Fetch error');
            return null;
        } catch (error) {
            console.log(error);
        }
    }

    changePackingStatus = (orderId, boolVal, evt) => {

        this.sendPackingStatusAPI(orderId, boolVal, evt.target);

        console.log(orderId);
        console.log(boolVal);
        const $button = $(evt.target);
        $button.prop('disabled', true);
        $button.siblings('button').prop('disabled', true);
        const $spans = $button.parent().siblings('.packing-status-container').find('span');
        if (boolVal) {
            // Now packed
            $spans.removeClass('pastel-red');
            $spans.addClass('pastel-green');
            $spans.text('Just packed');
        } else {
            // Unpacked
            $spans.removeClass('pastel-green');
            $spans.addClass('pastel-red');
            $spans.text('Undone');
        }
    }

    fetchFromApi = async () => {
        try {
            const adminToken = localStorage.getItem('adminToken');
            const url = '/api/admin/orders/all';
            const response = await axios({
                url,
                method: 'POST',
                data: { adminToken }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    const { orderDocs } = data;
                    sessionStorage.setItem('orderDocs', JSON.stringify(orderDocs));
                }
                console.log('Data error');
            } else {
                console.log('API response error');
            }
            console.log('Fetch error');
            return null;
        } catch (error) {
            console.log(error);
        }
    }

    toggleTable = (evt) => {
        const $orderSummary = $(evt.target);
        $orderSummary.siblings('div.table-container').slideToggle();
    }

    getOrders = () => {
        const orders = this.fetchFromStorage();
        //console.log(orders);
        if (orders) {
            const reversed = orders.reverse();
            const unpickedOrders = [];
            for (let i=0; i<reversed.length; i++) {
                const order = reversed[i];
                const { shippingAddress, isLocal, productLines, giftBoxLines, giftWrapCost, timestamp, hasBeenPicked, giftMessage, deliveryMessage } = order;
                const { fullName, address1, address2, city, county, country, postcode, email } = shippingAddress;

                if (hasBeenPicked) {
                    continue;
                }

                const unixNow = Math.floor(Date.now()/1000);
                const oneMin = 60;
                const oneHour = 60 * oneMin;
                const oneDay = 24 * oneHour;
                const sevenDays = 7 *  oneDay;
                const sevenDaysAgo = unixNow - sevenDays;
                if (timestamp < sevenDaysAgo) {
                    continue;
                }

                const orderId = order.id;

                //const unixNow = Math.floor(Date.now() / 1000);
                const daysSinceEpoch = Math.floor(unixNow / 86400);
                const unixThisMorning = daysSinceEpoch * 86400;
                const daysPassed = daysSinceEpoch - Math.floor(timestamp / 86400);
                const theDay = unixThisMorning < timestamp ? "Today" : `${daysPassed} days ago`;

                let unix_timestamp = timestamp
                // Create a new JavaScript Date object based on the timestamp
                // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                var date = new Date(unix_timestamp * 1000);
                // Hours part from the timestamp
                var hours = date.getHours();
                // Minutes part from the timestamp
                var minutes = "0" + date.getMinutes();
                // Seconds part from the timestamp
                var seconds = "0" + date.getSeconds();

                // Will display time in 10:30:23 format
                var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

                const greyedOutClass = hasBeenPicked ? ' greyOut' : '';

                const changePackingStatus = this.changePackingStatus;


                unpickedOrders.push(<div className={"admin-dhl-orders-all-order" + greyedOutClass}>

                    <p onClick={this.toggleTable}>
                        <strong>Time:</strong> {formattedTime}<br />
                        <strong>Day:</strong> {theDay}<br />
                        <strong>Order number:</strong> {order.id}<br />
                        <br />
                        {fullName}<br />
                        {address1}<br />
                        {address2}<br />
                        {city}<br />
                        {county}<br />
                        {country}<br />
                        {postcode}<br />
                        <br />
                        {email}
                    </p>

                    <div className="local-delivery-container">
                        {isLocal ? <span className="pastel-blue">LOCAL DELIVERY</span> : null}
                    </div>

                    <div className="packing-status-container">
                        {hasBeenPicked ? <span className="pastel-green">Already packed</span> : <span className="pastel-red"><b>Not yet packed!</b></span>}
                    </div>

                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ "width": "20%" }}><strong><u>ID</u></strong></td>
                                    <td style={{ "width": "30%" }}><strong><u>Producer</u></strong></td>
                                    <td style={{ "width": "30%" }}><strong><u>Name</u></strong></td>
                                    <td style={{ "width": "20%" }}><strong><u>Quantity</u></strong></td>
                                </tr>
                            </thead>
                            <tbody>

                                {productLines.map(productLine => {
                                    const { id, name, quantity } = productLine;
                                    return (<tr>
                                        <td><strong>{id}</strong></td>
                                        <td>{productMenu[id]['producer'] || ''}</td>
                                        <td>{name}</td>
                                        <td>x{quantity}</td>
                                    </tr>);
                                })}

                                {giftBoxLines.map(giftBoxLine => {
                                    const { id } = giftBoxLine;
                                    return (<tr>
                                        <td>{id} (Box)</td>
                                        <td></td>
                                        <td>Gift box</td>
                                        <td>x1</td>
                                    </tr>);
                                })}

                                {giftWrapCost ?
                                    <tr>
                                        <td>1 (Wrap)</td>
                                        <td></td>
                                        <td>Knot wraps</td>
                                        <td>x{Math.round(giftWrapCost / 250)}</td>
                                    </tr>
                                    :
                                    null
                                }

                            </tbody>
                        </table>

                        {giftMessage ? <p><strong>Gift message:</strong> {giftMessage}</p> : null}

                        {deliveryMessage ? <p><strong>Delivery note:</strong> {deliveryMessage}</p> : null}

                    </div>

                    <div>
                        <button className={ hasBeenPicked ? '' : 'hide-btn' } onClick={(evt) => { changePackingStatus(orderId, false, evt) }}>Undo packing status</button>
                        <button className={ hasBeenPicked ? 'hide-btn' : '' } onClick={(evt) => { changePackingStatus(orderId, true, evt) }}>Mark as packed</button>
                    </div>

                </div>);
            }
            return unpickedOrders;
        } else {
            console.log('getItems error');
            return null;
        }
        
    }

    fetchFromStorage = () => {
        const stringified = sessionStorage.getItem('orderDocs');
        if (stringified) {
            return JSON.parse(stringified);
        }
        return null;
    }

    fillMenu = () => {
        const productsArrayString = localStorage.getItem('wineProducts5');
        if (!productsArrayString) return;
        const productsArray = JSON.parse(productsArrayString);
        if (!productsArray) return;

        productsArray.forEach(product => {
            const { id } = product;
            productMenu[id] = product;
        });
    }

    componentDidMount() {
        this.fetchFromApi();
    }


    render() {

        this.fillMenu();

        const adminToken = localStorage.getItem('adminToken');
        if (!adminToken) {
            console.log('redirect');
            window.location.href = '/admin-archie-96/login';
        } else {
            console.log('got token:');
        }

        //const htmlItems = this.getOrders();

        return (
            <div id="admin-dhl-orders-all" className="testPage containerFluidMedium">
                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>
                <h6>Orders - not yet picked (last 7 days)</h6>
                <div className="color-even">
                    {this.getOrders()}
                </div>
            </div>
        );
    }

}

export default AdminNotPickedPage;