import React, { Component } from 'react';
import { csvToJson, csvToJson2, csvToJsonPlain } from '../../../../../utils/csvUtils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

class ProductUpdatePage extends Component {

    dateChanged = (dateUnix) => {
        console.log('unix', dateUnix);
        const dateObject = new Date(dateUnix * 1000);
        const day = dateObject.toLocaleString('en-GB', { day: "numeric" });
        const month = dateObject.toLocaleString('en-GB', { month: "numeric" });
        const year = dateObject.toLocaleString('en-GB', { year: "numeric" });
        console.log('day', day);
        console.log('month', month);
        console.log('year', year);
    }

    /*
    returnJsonToScreenArray = () => {
        const response = csvToJson2($('#csv').val());
        if (response) {
            console.log(response);
            $('#json').text(JSON.stringify(response));
        }
    }
    */

    returnJsonToScreenMulti = () => {
        const response = csvToJsonPlain($('#csv').val().toString().trim());
        if (response) {
            console.log(response);
            $('#json').text(JSON.stringify(response));
        }
    }

    returnJsonToScreenMulti2 = () => {
        const response = csvToJson2($('#csv').val().toString().trim());
        if (response) {
            console.log(response);
            $('#json').text(JSON.stringify(response));
        }
    }

    /*
    sendToApiArray = async() => {
        try {
            const url = '/api/admin/producer-update/id-to-info-array';
            const csvString = $('#csv').val().toString();
            const response = await axios({
                url,
                method: 'POST',
                data: { csvString }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    console.log(data);
                    const { success } = data;
                    if (success) {
                        const { responseObject } = data;
                        if (responseObject) {
                            console.log(responseObject);
                            return;
                        }
                    }
                }
            console.log('Error with response');
            } else {
                console.log('No response')
            }
        } catch (error) {
            console.log(error);
        }
    }
    */

    sendToApiMulti = async() => {
        try {
            const url = '/api/admin/product-update/update-by-id';
            const csvString = $('#csv').val().toString().trim();
            const adminToken = localStorage.getItem('adminToken');
            const response = await axios({
                url,
                method: 'POST',
                data: { csvString, adminToken }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    console.log(data);
                    const { success } = data;
                    if (success) {
                        const { responseObject } = data;
                        if (responseObject) {
                            console.log(responseObject);
                            return;
                        }
                    }
                }
            console.log('Error with response');
            } else {
                console.log('No response')
            }
        } catch (error) {
            console.log(error);
        }
    }


    


    render() {
        const sampleCsv = 
`id,     stock,  name
number, number, string
1,      9,      WineA
2,      6,      WineB
101,    0,      WineZ`;


        return (
            <div className="testPage containerFluidMedium">

                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>

                <div>
                    <h6>CSV Source:</h6>
                    <textarea id="csv" rows="10" cols="50" defaultValue={sampleCsv}>
                    </textarea>
                </div>

                <br />

                <button onClick={this.returnJsonToScreenMulti2}>CONVERT Multiple fields</button><br />

                <div>
                    <h6>JSON output:</h6>
                    <div id="json" style={{ "minHeight": "300px", "backgroundColor": "#eee" }}>

                    </div>
                </div>

                <br />

                <button onClick={this.sendToApiMulti}>SEND TO API Multiple fields</button>

            </div>
        )
    }

}

export default ProductUpdatePage;