import { useState } from "react";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './all.css';
import axios from 'axios';

let productMenu = {};

const AdminProductIdToStockPage = ({}) => {
    const ordersString = localStorage.getItem('orderDocs') || '';
    const ordersObj = JSON.parse(ordersString) || [];
    const [stateOrders, setStateOrders] = useState(ordersObj);

    const storageLastFetched = localStorage.getItem('orderDocsLastFetched') || 0;

    const [stateLastFetched, setStateLastFetched] = useState(storageLastFetched);
    

    const fetchFromApi = async () => {
        console.log('Fetching from API...');
        try {
            const adminToken = localStorage.getItem('adminToken');
            const url = '/api/admin/orders/all';
            const response = await axios({
                url,
                method: 'POST',
                data: { adminToken }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    const { orderDocs } = data;
                    localStorage.setItem('orderDocs', JSON.stringify(orderDocs));
                    const unixNow = Math.floor(Date.now()/1000);
                    localStorage.setItem('orderDocsLastFetched', unixNow);
                    setStateOrders(orderDocs);
                    setStateLastFetched(unixNow);
                    //updatePage();
                } else  {
                    console.log('Data error');
                }
            } else {
                console.log('API response error');
                return null;
            }
        } catch (error) {
            console.log(error);
            console.log('redirect');
            const statusCode = error['response']['status'];
            console.log(statusCode);
            if ( statusCode == 401 ) {
                window.location.href = '/admin-archie-96/login';
            }
            //window.location.href = '/admin-archie-96/login';
        }
    }

    const sendPackingStatusAPI = async (orderId, boolVal, target) => {
        try {
            const adminToken = localStorage.getItem('adminToken');
            const url = '/api/admin/orders/change-packing-status';
            const response = await axios({
                url,
                method: 'PUT',
                data: { adminToken, orderId, boolVal }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    if (data['success']) {
                        const orderDocsString = localStorage.getItem('orderDocs');
                        if (orderDocsString) {
                            const orderDocs = JSON.parse(orderDocsString);
                            let newOrderDocs = [];
                            if (orderDocs) {
                                for (let i = 0; i < orderDocs.length; i++) {
                                    const orderDoc = orderDocs[i];
                                    if (orderDoc.id === orderId) {
                                        orderDoc.hasBeenPicked = boolVal;
                                    }
                                    newOrderDocs.push(orderDoc);
                                }
                                const newOrderDocsString = JSON.stringify(newOrderDocs);
                                localStorage.setItem('orderDocs', newOrderDocsString);
                                setStateOrders(newOrderDocs);
                            }
                        }
                    }
                }
                //console.log('Data error');
            } else {
                console.log('API response error');
            }
            //console.log('Fetch error');
            return null;
        } catch (error) {
            console.log(error);
        }
    }

    const changePackingStatus = async(orderId, boolVal, evt) => {
        const $button = $(evt.target);
        $button.prop('disabled', true);
        $button.siblings('button').prop('disabled', true);
        const $spans = $button.parent().siblings('.packing-status-container').find('span');
        /*
        if (boolVal) {
            // Now packed
            $spans.removeClass('pastel-red');
            $spans.addClass('pastel-green');
            $spans.text('Just packed');
        } else {
            // Unpacked
            $spans.removeClass('pastel-green');
            $spans.addClass('pastel-red');
            $spans.text('Undone');
        }
        */
        await sendPackingStatusAPI(orderId, boolVal, evt.target);
        $('button').prop('disabled', false);
    }

    const toggleTable = (evt) => {
        const $orderSummary = $(evt.target);
        $orderSummary.siblings('div.table-container').slideToggle();
    }

    const convertArrayToElements = (orders) => {
        if (orders) {
            return (orders.map(order => {
                const { shippingAddress, isLocal, productLines, giftBoxLines, giftWrapCost, timestamp, hasBeenPicked, giftMessage, deliveryMessage } = order;
                const { fullName, address1, address2, city, county, country, postcode, email } = shippingAddress;

                const orderId = order.id;

                const unixNow = Math.floor(Date.now() / 1000);
                const daysSinceEpoch = Math.floor(unixNow / 86400);
                const unixThisMorning = daysSinceEpoch * 86400;
                const daysPassed = daysSinceEpoch - Math.floor(timestamp / 86400);
                const theDay = unixThisMorning < timestamp ? "Today" : `${daysPassed} days ago`;

                let unix_timestamp = timestamp
                // Create a new JavaScript Date object based on the timestamp
                // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                var date = new Date(unix_timestamp * 1000);
                // Hours part from the timestamp
                var hours = date.getHours();
                // Minutes part from the timestamp
                var minutes = "0" + date.getMinutes();
                // Seconds part from the timestamp
                var seconds = "0" + date.getSeconds();

                // Will display time in 10:30:23 format
                var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

                const greyedOutClass = hasBeenPicked ? ' greyOut' : '';

                //const changePackingStatus = this.changePackingStatus;

                return (<div className={"admin-dhl-orders-all-order" + greyedOutClass}>

                    <p onClick={toggleTable}>
                        <strong>Time:</strong> {formattedTime}<br />
                        <strong>Day:</strong> {theDay}<br />
                        <strong>Order number:</strong> {order.id}<br />
                        <br />
                        {fullName}<br />
                        {address1}<br />
                        {address2}<br />
                        {city}<br />
                        {county}<br />
                        {country}<br />
                        {postcode}<br />
                        <br />
                        {email}
                    </p>

                    <div className="local-delivery-container">
                        {isLocal ? <span className="pastel-blue">LOCAL DELIVERY</span> : null}
                    </div>

                    <div className="packing-status-container">
                        {hasBeenPicked ? <span className="pastel-green">Already packed</span> : <span className="pastel-red"><b>Not yet packed!</b></span>}
                    </div>

                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ "width": "20%" }}><strong><u>ID</u></strong></td>
                                    <td style={{ "width": "30%" }}><strong><u>Producer</u></strong></td>
                                    <td style={{ "width": "30%" }}><strong><u>Name</u></strong></td>
                                    <td style={{ "width": "20%" }}><strong><u>Quantity</u></strong></td>
                                </tr>
                            </thead>
                            <tbody>

                                {productLines.map(productLine => {
                                    const { id, name, quantity } = productLine;
                                    return (<tr>
                                        <td><strong>{id}</strong></td>
                                        <td>{productMenu[id]['producer'] || ''}</td>
                                        <td>{name}</td>
                                        <td>x{quantity}</td>
                                    </tr>);
                                })}

                                {giftBoxLines.map(giftBoxLine => {
                                    const { id } = giftBoxLine;
                                    return (<tr>
                                        <td>{id} (Box)</td>
                                        <td></td>
                                        <td>Gift box</td>
                                        <td>x1</td>
                                    </tr>);
                                })}

                                {giftWrapCost ?
                                    <tr>
                                        <td>1 (Wrap)</td>
                                        <td></td>
                                        <td>Knot wraps</td>
                                        <td>x{Math.round(giftWrapCost / 250)}</td>
                                    </tr>
                                    :
                                    null
                                }

                            </tbody>
                        </table>

                        {giftMessage ? <p><strong>Gift message:</strong> {giftMessage}</p> : null}

                        {deliveryMessage ? <p><strong>Delivery note:</strong> {deliveryMessage}</p> : null}

                    </div>

                    <div>
                        <button className={ hasBeenPicked ? '' : 'hide-btn' } onClick={(evt) => { changePackingStatus(orderId, false, evt) }}>Undo packing status</button>
                        <button className={ hasBeenPicked ? 'hide-btn' : '' } onClick={(evt) => { changePackingStatus(orderId, true, evt) }}>Mark as packed</button>
                    </div>

                </div>);
            }));
        } else {
            console.log('convertArrayToElements, no orders.');
        }
        console.log('getItems error');
        return null;
    }

    /*
    fetchFromStorage = () => {
        const stringified = sessionStorage.getItem('orderDocs');
        if (stringified) {
            return JSON.parse(stringified);
        }
        return null;
    }
    */

    const fillMenu = () => {
        const productsArrayString = localStorage.getItem('wineProducts5');
        if (!productsArrayString) return;
        const productsArray = JSON.parse(productsArrayString);
        if (!productsArray) return;

        productsArray.forEach(product => {
            const { id } = product;
            productMenu[id] = product;
        });
    }

    const renderOrdersToElements = (ordersArray) => {
        if (ordersArray) {
            let reversed = [];
            for (let i=ordersArray.length-1; i>=0; i--) {
                reversed.push(ordersArray[i]);
            }
            return convertArrayToElements(reversed);
        }
    }

    fillMenu();

    const adminToken = localStorage.getItem('adminToken');
    if (!adminToken) {
        console.log('redirect');
        window.location.href = '/admin-archie-96/login';
    }

    const unixCurrent = Math.floor(Date.now()/1000);
    
    let lastFetchedText = 'No orders found...';
    if (stateLastFetched) {
        let timeDifference = unixCurrent-stateLastFetched;
        let units = 'seconds';
        if (timeDifference > 120) {
            timeDifference = Math.floor(timeDifference/60);
            units = 'minutes';
            if (timeDifference > 120) {
                timeDifference = Math.floor(timeDifference/60);
                units = 'hours';
                if (timeDifference > 24) {
                    timeDifference = Math.floor(timeDifference/24);
                    units = 'days';
                }
            }
        }
        lastFetchedText = "Last fetched: ";
        if (timeDifference) {
            lastFetchedText += timeDifference + " " + units + " ago";
        } else {
            lastFetchedText += "just now";
        }
    }

    return (
        <div id="admin-dhl-orders-all" className="testPage containerFluidMedium">
            <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>
            <h6>Orders - all</h6>
            <p>
                <button type="text" onClick={fetchFromApi}>Refresh</button><br />
                <br />
                {lastFetchedText}
            </p>
            <div id="all-orders-container" className="color-even">
                {renderOrdersToElements(stateOrders)}
            </div>
        </div>
    );

}

export default AdminProductIdToStockPage;