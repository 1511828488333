import React from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './careers.css';
import { Link } from 'react-router-dom';
//import tape from '../../../materials/images/image2.png';

class Careers extends React.Component {

    
    render() {
        updateNav();

        const archieLeft = '/uploads/emails/archie.jpg';

        const archieRight = '/uploads/emails/archie-blep.jpg';

        return (
            <div className=" careersPage linkHighlight">
                <h1 className="containerFluid" id="careersHeader">Careers</h1>

                <div className="corkTapeContainer">
                    <div className="corkTape"></div>
                </div>

                <div className="containerFluid contentContainer">
                    <div className="standardFontResponsive padY paperBlack">
                        <p>Experience creators. Dreamers. Achievers. Story-tellers. Adventure seekers. Thrill riders. Pleasure seekers. Peace makers. Wine worshippers.</p>
                        <p>That’s who we are - could you be part of the Grand Cru? Pun intended.</p>
                        <p>If you’re interested in a Career at Carafe it’s important to know what we’re about, take a seat kid and grab yourself a cuppa whilst you take a read;</p>
                    </div>
                </div>

                <div className="containerFluid contentContainer ">
                    <div className="standardFontResponsive paperBrown paperBrownTighter lightenBg top-pad-20">
                        <p>Carafe was established to pic ‘n’ mix the best wines for those living the suburban and rural life, bringing small and interesting producers of tasty grape juice to their doorstep.
                        The supermarket selection can be a repetitive snooze fest - we want interesting and we want it now. Carafe saw how unparallel the fantastic wine we drink in restaurants and wine bars is to the wine readily available in shops for us to buy. The quality shouldn’t stop when you leave the restaurant or venture out of a city, that’s backwards.</p>

                        <p>We just want to sell good wine and without the bollocks may we add. It’s no secret that wine is not the most approachable subject, consumers get buried under wine jargon and arrogant sommeliers who present wine as some sort of elite club that you need to be nominated into and once you are in the door you have to pledge with swooshing, swishing, gurgling wine and name all the regions of Burgundy and Bordeaux. Whether you are a novice or a newbie at Carafe you are welcome, you don’t have to wait for the golden ticket, we’ll be the Willy Wonka’s of the wine world and everyone can go crazy like kids in a candy shop.</p>

                        <p>You may have also noticed that we have quite a green thumb here at Carafe. When curating our company we thought that Corporate Social Responsibility shouldn’t be an afterthought - a lot of this was branded into the DNA of the company inspired by the founders previous employment at TOMS, good old Blake Mycoskie. Giving back should be stitched into who we are.</p>

                        <p>To make this run true Carafe signed up to be part of the ‘1% For The Planet’ movement, contributing at least 1% of all annual sales to environmental charities. We also have nominated charity wines where a generous percentage of the profit of each bottle sold goes to a great cause - read more <Link to="/help/charity">here.</Link></p>

                        <p>We want to be lead by example, striving to be as sustainable as possible - every part of our packaging is recyclable and we strategically choose with diversity and supporting local at the forefront of our mind from selecting our wine suppliers down to details like the printing of our marketing material.</p>
                    </div>
                </div>

                <div className="containerFluid contentContainer">
                    <div className="standardFontResponsive paperBlack whiteText padY padYMoreMobile" id="companyValuesText">
                        <h4>Company Values</h4>
                        <p>The <b>Carafe</b> mission statement - To create the best interpersonal experience possible for our guests with interesting quality wines. Wines by friends, for friends.</p>

                        <h6>1= We are experience creators</h6>
                        <p>Hey, that’s what it’s all about. That’s why we do anything for leisure and why we continue to return to certain places - the experience that is created. And hey you, you’re a creator now! You are in the driving seat. Carafe is your stage and it’s time to deliver a memorable performance, note: the best ones are genuine. </p>

                        <h6>2= We make wine & the extended family approachable</h6>
                        <p>We’ve all had it. I did in the early days, looking completely bewildered and quite frankly terrified looking at a wine list praying that the server didn’t visit me too quickly… and when they did just pointing at the top of the menu, not daring to try and pronounce the wine, just hoping I liked it.</p>
                        <p>Let’s be the server we wish we had. We will be approachable and introduce our guests into the delights of Orange, Pet Nats, Lambrusco… new grape varieties… whatever they are willing to try.</p>
                        <p>Let’s be a place of discovery not ridicule.</p>

                        <h6>3= We care</h6>
                        <p>We give something back to those around us. We are not a corporate machine. We share in the local spirit and are ethical. </p>

                        <h6>4= We are human</h6>
                        <p>That’s life, we all have our down days and sometimes we won’t feel on top form. To hell with it, we may even oversleep our alarm one time. The best policy is honesty.</p>
                        <p>A repeat occurrence of this then i’ll ask you what’s up and take a course of action. But really, we all have these days, just own up, own it and we can help.</p>
                        <p>It’s also normal to feel overwhelmed or mess up sometimes at work; from breaking a glass, giving the wrong bottle or charging for the wrong item - just tell us what happened so we can sort it together. I’d prefer to just cut the bullshit. We are human after all.</p>

                        <h6>5= We want to learn</h6>
                        <p>Call us Dora the Explorer. There are so many lands and adventures we haven’t been on yet.</p>
                        <p>Think you know all about wine? There are over 10,000 varieties so think again. </p>
                        <p>We are inquisitive in nature and want to know more. The best sommeliers are storytellers; they transport you back to the vineyard to the point you can visualise the family harvesting grapes on the hill, you can almost smell the soil and see the family dog walking along the vines with them.</p> 
                        <p>Let us learn more to enrich ourselves and to impart this newfound knowledge to our colleagues and guests about it. We want to learn as this is our passion. To be experts in our sector we need to keep learning and keep innovating.</p>

                        <h6>6= We strive to be excellent, not perfect</h6>
                        <p>Excellence not perfection. The first is a goal, the second is a straight jacket. We deliver results.</p>

                        <h6>7= We are resilient</h6>
                        <p>Resilience. The reed bends in turbulent winds; the oak stands stiff and breaks. We adapt.</p>

                        <h6>8= We have a 0 tolerance policy for arseholes</h6>
                        <p>No trade is worth being talked down to, belittled, insulted and made to feel uncomfortable.</p>
                        <p>I have been part of many companies before that turned a blind eye to the treatment of staff from their guests to gain the cash. Not at this company, we are a family and this is our home. If they can’t be nice, we will choose not to trade with them.</p>

                        <h6>9= We are sharers not hoarders</h6>
                        <p>Ideas are common currency and we share them. No work is the result of just one person. Everyone will have played a part. It’s “WE” not “ME”. Together we are stronger.</p>

                        <h6>10= We do not distinguish between work and play</h6>
                        <p>A difference between work and play implies that work cannot be fun. I do not want to create an environment that is not enjoyable. </p>
                        <p>We are at our creative best when we get to bring our whole and true selves to work. Let us know your skills and we can try to utilise them anyway we can.</p>
                        <p>Big art lover, let’s talk about a gallery pop up. Good at graphic design, look out social media. Keen eye for great films, hello movie night. We can be a powerhouse together. Let’s integrate and innovate. Our work is our calling rather than a job, let it feed your creativity.</p>

                        <div id="archie-staggered-container">
                            <img className="archie-left" src={archieLeft} />
                            <img className="archie-right" src={archieRight} />
                        </div>

                        <br></br>
                        <p>Right, you made it to the bottom, we didn’t scare you off, so you must be deadly keen for an In at Carafe.
                        We are always interested in finding new talent and that may just be you! The main thing we look for is passion and passion can’t be taught.</p>

                        <p>Give us a line at <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk</a> with your resume attached, tell us the kind of role you are looking for and what you could bring to Carafe then we’ll take it from there. Take it easy Chief.</p>
                    </div>
                </div>

                <div className="corkTapeContainer bottomCorkTape">
                    <div className="corkTape"></div>
                </div>
            </div>
        )
    }
}

export default Careers;