import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import { Link } from 'react-router-dom';
import './contact.css';
import winePour from '../../../materials/images/blueWine.png';
import toastError from '../../../materials/icons/toast-error.png';
import toastSuccess from '../../../materials/icons/toast-success.png';
//import SnackBar from '../../../components/generic/snackbar/snackbar';
import { isValidEmail } from '../../../utils/emailUtils';
import axios from 'axios';

class Contact extends Component {

    keyUp = (e) => {
        e.preventDefault();
        if (e.keyCode === 13) {
            document.getElementById("submitButton").click();
        }
    }

    submitForm = async() => {
        const emailInput = document.getElementById('userEmail');
        const email = emailInput.value.toLowerCase();
        emailInput.disabled = true;

        const submitBtn = document.getElementById('submitButton');
        submitBtn.disabled = true;

        await new Promise(r => setTimeout(r, 100));

        if (!email) {
            this.toast('All inputs are required', "#b33", "eee", toastError);
            submitBtn.disabled = false;
            emailInput.disabled = false;
            return;
        }

        if (!isValidEmail(email)) {
            this.toast('Please enter valid email', "#b33", "eee", toastError);
            submitBtn.disabled = false;
            emailInput.disabled = false;
            return;
        }
        
        // Post email to API
        try {
            const url = '/api/mailinglist/signup';
            const consent = true;
            const response = await axios({
                url: url,
                method: 'POST',
                data: { email, consent }
            });
        
            const { data } = response;
            const { success } = data;
        
            if (success !== true) {
                const errorMessage = data.message || 'Server error';
                this.toast(errorMessage, "#b33", "eee", toastError);
                submitBtn.disabled = false;
                emailInput.disabled = false;
                return;
            } else {
                this.toast('Signed up!', "#5a5", "#fff", toastSuccess);
                emailInput.value = '';
                emailInput.disabled = true;
                submitBtn.disabled = true;
                submitBtn.innerText = 'Submitted';
                emailInput.placeholder = 'Thank you for signing up!';
                localStorage.setItem('mailingListSignup', '1');
            }
        } catch (error) {
            console.log(error);
            this.toast('Server error', "#b33", "eee", toastError);
            submitBtn.disabled = false;
            emailInput.disabled = false;
        }
    };

    // Display toast in top right corner
    toast = (message, bgColor, textColor, img) => {
        const toastDuration = this.toastDurationCalc(message);
        var x = document.getElementById("snackbar");
        var xIcon = document.getElementById('toast-icon');
        var xText = document.getElementById('toast-text');
        xIcon.src = img;
        xText.style.color = textColor;
        xText.innerText = message;
        x.style.backgroundColor = bgColor;
        x.className = "show";
        setTimeout(function(){ 
            x.className = x.className.replace("show", "");
        }, toastDuration);
    }

    toastDurationCalc = (message) => {
        const minDuration = 2000;
        const idealMs = message.length * 100;
        const maxDuration = 7000;
        return Math.max(minDuration, Math.min(idealMs, maxDuration));
    }

    render() {
        updateNav();
        const privacyUrl = '/help/privacy-policy';
        return (
            <div className="bodyContainer">

                <div className="containerFluidMedium" id="contactContainer">
                    <img src={winePour} title="Blue Wine Image" alt="Blue Wine" id="blueWineImage" />

                    <div className="containerFluid" id="contactTextContainer">
                        <div className="paperBlack">
                            <div className="contentArea">
                                <div className="standardFontResponsive whiteText linkHighlight" id="contactText">
                                    <p>Wanna chat? We’d love to hear from you!</p>
                                    <p>You may find what you are looking for on our <Link to="/help/faq">FAQ page</Link> but if not zoom over a message to <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk </a> and a member of the Carafe family will be in touch with you soon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="containerFluidMedium">
                    <div className="paperOffWhite lightenBg paperOffWhiteLooser mailingList">
                        <h2 className="bridgesTownHeader" id="contactHeader">Experiencing Real FOMO?</h2>
                        <div className="standardFontResponsive">
                            <p>(Fear of missing out)</p>
                            <p>We got you homie, sign up to our mailing list to keep updated on the latest juice;</p>
                        </div>

                        <div>
                            <input type="email" max="30" id="userEmail" name="userEmail" placeholder="Enter email here... " onKeyUp={this.keyUp} />
                            <button type="submit" id="submitButton" name="submitButton" onClick={this.submitForm}>Submit</button>
                        </div>
                        <p className="terms-container">
                            By entering your email address, you agree to receive our marketing offers in accordance with our&nbsp;
                            <Link className="terms-link" to={privacyUrl}>Privacy Policy</Link>.
                        </p>
                    </div>
                </div>

            </div>
        )
    }
}

export default Contact;