import $ from 'jquery';

export function showSignupError(errorMessage) {
  const $submitbtn = $('.submitButton');
  $submitbtn.prop('disabled', false);
  try {
    $('#signup-error-container').text(errorMessage);
  } catch (error) {
    console.log(error);
  }
}