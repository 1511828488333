import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import axios from 'axios';


class AdminOrderSendEmailPage extends Component {

    sendEmail = async () => {
        const adminToken = localStorage.getItem('adminToken') || '';
        const $input = $('#order-id');
        const hiddenId = $input.val();
        const url = '/api/admin/order-email/by-order-hidden-id';
        const response = await axios({
            url,
            method: 'POST',
            data: { adminToken, hiddenId }
        });
        if (response) {
            const { data } = response;
            if (data) {
                const { orderDocs } = data;
                sessionStorage.setItem('orderDocs', JSON.stringify(orderDocs));
            }
            console.log('Data error');
        } else {
            console.log('API response error');
        }
        console.log('Fetch error');
        return null;
    }

    render() {

        const adminToken = localStorage.getItem('adminToken');
        if (!adminToken) {
            console.log('redirect');
            window.location.href = '/admin-archie-96/login';
        } else {
            console.log('got token:');
        }

        return (
            <div className="admin-page containerFluidMedium">
                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>
                <h6>Resend order confirmation email</h6>
                <br />
                <br />
                <input type="text" id="order-id" placeholder="ObjectID e.g: 605gW84..." style={ {"width": "300px"} }></input><br />
                <br />
                <button type="text" onClick={this.sendEmail}>RE-SEND</button>
            </div>
        );
    }

}

export default AdminOrderSendEmailPage;