import React from 'react';
import SignupForm from '../../../components/account/signup/signupForm';
import { updateNav } from '../../../utils/updateActivePage';
import './signup.css';

class SignupPage extends React.Component {
    render(){
        if (this.props.token) window.location.href = '/account/';
        updateNav();
        return(
            <div className="paperOffWhite lightenBgMore containerFluidNarrow signupPage">
                <SignupForm basket={this.props.basket} signUp={this.props.signUp} />
            </div>
        )
    }
}

export default SignupPage;