import React from 'react';
import './body.css';

import {
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

// Admin
import adminDashboardPage from '../../pages/admin/dashboard/dashboard';
import adminLoginPage from '../../pages/admin/login/login';

//import adminBulkAddPage from '../../pages/admin/dashboard/products/add/add';
//import adminStockZeroPage from '../../pages/admin/dashboard/products/zeroStock/zeroStock';
//import adminProductIdToStockPage from '../../pages/admin/dashboard/products/idToStock/idToStock';
//import AdminProductIdToInfoPage from '../../pages/admin/dashboard/products/idToInfo/idToInfo';
import AdminMailingListDecryptPage from '../../pages/admin/dashboard/mailingList/decrypt/decrypt';
import AdminProductUpdatePage from '../../pages/admin/dashboard/products/productUpdate/productUpdate';
import AdminStockUpdateWithNotePage from '../../pages/admin/dashboard/products/stockUpdate/stockUpdate';

import AdminPitpProductCopyPage from '../../pages/admin/dashboard/pitp/productCopy/productCopy';
import AdminPitpProductsAddPage from '../../pages/admin/dashboard/pitp/productsAdd/pitpProductsAdd';
import AdminPitPOrdersPage from '../../pages/admin/dashboard/pitp/orders/orders';



import AdminOrdersAll from '../../pages/admin/dashboard/orders/all/all';
import AdminOrdersToday from '../../pages/admin/dashboard/orders/today/today';
import AdminNotPickedPage from '../../pages/admin/dashboard/orders/notPicked/notPicked';
import AdminOrdersUpdatePicked from '../../pages/admin/dashboard/orders/updatePicked/updatePicked';
import AdminProductsLow from '../../pages/admin/dashboard/products/viewLow/viewLow';
import AdminOrderSendEmail from '../../pages/admin/dashboard/orders/sendEmail/sendEmail';
import AdminDhlSetPickup from '../../pages/admin/dashboard/orders/setPickup/setPickup';
import AdminDhlLabels from '../../pages/admin/dashboard/orders/getLabels/getLabels';

// Shop
import SearchPage from '../../pages/shop/search/search';
import ProductsPage from '../../pages/shop/products/products';
import ProductPage from '../../pages/shop/product/product';

// PitP
//import PubAllPage from '../../pages/shop/pubAll/pubAll';
//import QuickCheckoutPage from '../../pages/shop/quickCheckout/quickCheckout';

// Checkout
import BasketPage from '../../pages/shop/basket/basket';
import CheckoutDeliveryPage from '../../pages/shop/checkoutDelivery/checkoutDelivery';
import CheckoutPaymentPage from '../../pages/shop/checkoutPayment/checkoutPayment';
import CheckoutFinishedPage from '../../pages/shop/checkoutFinished/checkoutFinished';
import CheckoutGuestPage from '../../pages/account/loginOrGuest/loginOrGuest';

// Help
import FAQPage from '../../pages/help/faq/faq';
import PrivacyPolicyPage from '../../pages/help/privacypolicy/privacypolicy';
import CookiePolicyPage from '../../pages/help/cookiepolicy/cookiepolicy';
import TermsOfServicePage from '../../pages/help/termsofservice/termsofservice';
import DeliveryPage from '../../pages/help/delivery/delivery';
import CareersPage from '../../pages/help/careers/careers';
import SustainabilityPage from '../../pages/help/sustainability/sustainability';
import CharityPage from '../../pages/help/charity/charity';
import Covid19Page from '../../pages/help/covid19/covid19';

// Main
import HomePage from '../../pages/main/home/home';
import ContactPage from '../../pages/main/contact/contact';
import PicksOfTheWeekPage from '../../pages/main/picksOfTheWeek/picksOfTheWeek'
import SubscriptionsPage from '../../pages/main/subscriptions/subscriptions';
import UnderConstructionPage from '../../pages/main/underConstruction/underConstruction';
import WhoWeArePage from '../../pages/main/whoWeAre/whoWeAre';

// Account
import LoginPage from '../../pages/account/login/login';
import ForgotPasswordPage from '../../pages/account/forgotPassword/forgotPassword';
import ResetPasswordPage from '../../pages/account/resetPassword/resetPassword';
import EmailVerifyPage from '../../pages/account/verifyEmail/verifyEmail';
import SignupPage from '../../pages/account/signup/signup';
import OrdersPage from '../../pages/account/orders/orders';
import AccountOverview from '../../pages/account/accountOverview/accountOverview';

// Redirect
import NotFoundPage from '../../pages/notfound/notfound';

// temp Test
//import TestPage from '../../pages/test/test';

import VerifyGooglePage from '../../pages/verifygoogle/verifygoogle'

class Body extends React.Component {

    paths = {
        admin: '/admin-archie-96',
        shop: '/shop',
        shopProduct: '/shop/product',
        help: '/help',
        main: '/',
        notFound: '/oops',
        account: '/account'
    };

    render() {
        const { paths, props } = this;

        const { state, productsToStateInitiate, addRemoveToBasket, updateStateProducts, login, logout, addGiftToRow, addDiscountCodes, signUp, onChangeAdditionalNotes, onSuccessfulCheckout, onFailCheckout, updateDeliveryAddress, updateDeliveryAddressWhole, updateBillingAddress, 
        updateBillingAddressWhole, updateOrderHistory, updateBasketNote, updateBasketKnotWraps, updateBasketGiftBoxes, removeDiscountCode, updatePrimaryAddress, postcodeToUpdateDeliveryCost } = props;

        const { account, lastAccountCheck, products, lastProductsCheck, basket, lastBasketCheck, checkout, subscriptions, blackboards, orders } = state;

        account.lastAccountCheck = lastAccountCheck;
        //products.lastProductsCheck = lastProductsCheck;
        basket.lastBasketCheck = lastBasketCheck;
        const { details } = account;
        const { primaryAddress, email, canMaybeHaveFreeFirstOrderDelivery } = details; // for checkout autofill
        const { token } = account;
        const shop = { products, basket, addRemoveToBasket, addGiftToRow }; // for navbar and shop
        //const { clientSecret, paymentMethodReq, stripePromise } = checkout;

        // React-router-dom   component=...   vs   render=...

        // component=.. automatically gets "path" data in props.match.params :
        //              render() { return this.props.match.params.productname }

        // render=... you need to manually set "props => " to then access "path" from the matched Route ( <Route path="/product/:productname" ...) :
        //              <Component productname={props.match.params.productname}

        // When we want to add in additional props (most of the time), use render=...


            // old
        // <Route exact path={paths.admin + "/dashboard/bulk-add"} component={adminBulkAddPage} />
        // <Route exact path={paths.admin + "/dashboard/product-stock-zero"} component={adminStockZeroPage} />
        // <Route exact path={paths.admin + "/dashboard/id-to-stock"} component={adminProductIdToStockPage} />
        // <Route exact path={paths.admin + "/dashboard/id-to-info"} component={AdminProductIdToInfoPage} />



            // new
        //<Route exact path={paths.admin + "/dashboard/orders/all"} component={AdminOrdersAll} />
        //<Route exact path={paths.admin + "/dashboard/orders/today"} component={AdminOrdersToday} />
        //<Route exact path={paths.admin + "/dashboard/orders/not-picked"} component={AdminOrdersNotPicked} />
        //<Route exact path={paths.admin + "/dashboard/orders/update-picked"} component={AdminOrdersUpdatePicked} />

        //<Route exact path={paths.admin + "/dashboard/products/low"} component={AdminProductsLow} />

            // PitP
        // /admin-archie-96/dashboard/pitp/copy-product
        // AdminPitpProductCopyPage

        return (
            <Switch>

                <Route exact path={paths.notFound} component={NotFoundPage} />

                <Route path={'/googlefbb8c304a48c4d47.html'} component={VerifyGooglePage} />

                <Route path={paths.admin}>
                    <Switch>
                        <Route exact path={paths.admin + "/login"} component={adminLoginPage} />
                        <Route exact path={paths.admin + "/dashboard"} component={adminDashboardPage} />
                        <Route exact path={paths.admin + "/dashboard/orders/all"} component={AdminOrdersAll} />
                        <Route exact path={paths.admin + "/dashboard/orders/today"} component={AdminOrdersToday} />
                        <Route exact path={paths.admin + "/dashboard/orders/not-picked"} component={AdminNotPickedPage} />
                        <Route exact path={paths.admin + "/dashboard/orders/update-picked"} component={AdminOrdersUpdatePicked} />
                        <Route exact path={paths.admin + "/dashboard/products/low"} component={AdminProductsLow} />
                        <Route exact path={paths.admin + "/dashboard/mailinglist"} component={AdminMailingListDecryptPage} />
                        <Route exact path={paths.admin + "/dashboard/orders/send-email"} component={AdminOrderSendEmail} />
                        <Route exact path={paths.admin + "/dashboard/dhl/set-pickup"} component={AdminDhlSetPickup} />
                        <Route exact path={paths.admin + "/dashboard/dhl/labels"} component={AdminDhlLabels} />
                        <Route exact path={paths.admin + "/dashboard/products/update-by-id"} component={AdminProductUpdatePage} />
                        <Route exact path={paths.admin + "/dashboard/products/update-stock-with-note"} component={AdminStockUpdateWithNotePage} />

                        <Route exact path={paths.admin + "/dashboard/pitp/copy-product"} component={AdminPitpProductCopyPage} />
                        <Route exact path={paths.admin + "/dashboard/pitp/add-products"} component={AdminPitpProductsAddPage} />
                        <Route exact path={paths.admin + "/dashboard/pitp/orders"} component={AdminPitPOrdersPage} />
                        
                        <Redirect from='/' to={paths.notFound} />
                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </Route>

                <Route path={paths.shop}>
                    <Switch>
                        <Route exact path={paths.shop + "/search"} render={props => <SearchPage {...props} shop={shop} /> } />
                        <Route exact path={paths.shop + "/basket"} render={props => <BasketPage {...props} shop={shop} token={token} addDiscountCodes={addDiscountCodes} canMaybeHaveFreeFirstOrderDelivery={canMaybeHaveFreeFirstOrderDelivery} updateBasketNote={updateBasketNote} updateBasketKnotWraps={updateBasketKnotWraps} updateBasketGiftBoxes={updateBasketGiftBoxes} removeDiscountCode={removeDiscountCode} />} />
                        <Route exact path={paths.shop + "/checkout/guest"} render={props => <CheckoutGuestPage {...props} token={token} login={login} /> } />
                        <Route exact path={paths.shop + "/checkout/delivery"} render={props => <CheckoutDeliveryPage {...props} checkout={checkout} primaryAddress={primaryAddress} updateDeliveryAddress={updateDeliveryAddress} updateDeliveryAddressWhole={updateDeliveryAddressWhole} onChangeAdditionalNotes={onChangeAdditionalNotes} token={token} updateBasketNote={updateBasketNote} postcodeToUpdateDeliveryCost={postcodeToUpdateDeliveryCost} /> } />
                        <Route exact path={paths.shop + "/checkout/confirm-and-pay"} render={props => <CheckoutPaymentPage {...props} shop={shop} checkout={checkout} basket={basket} primaryAddress={primaryAddress} email={email} token={token} updateBillingAddress={updateBillingAddress} updateBillingAddressWhole={updateBillingAddressWhole} onSuccessfulCheckout={onSuccessfulCheckout} onFailCheckout={onFailCheckout} /> } />
                        <Route exact path={paths.shop + "/checkout/complete/:success"} render={props => <CheckoutFinishedPage success={props.match.params.success} /> } />
                        <Route path={paths.shopProduct}>
                            <Switch>
                                <Route path={paths.shopProduct + '/:name'} render={props => <ProductPage {...props} shop={shop} name={props.match.params.name} updateStateProducts={updateStateProducts} lastProductsCheck={lastProductsCheck} /> } />
                            </Switch>
                        </Route>
                        <Route exact path={paths.shop + "/"} render={props => <ProductsPage {...props} productsToStateInitiate={productsToStateInitiate} shop={shop} /> } />
                        <Redirect from='/' to={paths.notFound} />
                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </Route>

                <Route path={paths.account}>
                    <Switch>
                        <Route exact path={paths.account + "/login"} render={props => <LoginPage {...props} token={token} login={login} />} />
                        <Route exact path={paths.account + "/forgot-password"} component={ForgotPasswordPage} />
                        <Route exact path={paths.account + "/reset-password/:resetToken"} render={props => <ResetPasswordPage resetToken={props.match.params.resetToken} /> } />
                        <Route exact path={paths.account + "/verify-email/:verifyToken"} render={props => <EmailVerifyPage verifyToken={props.match.params.verifyToken} /> } />
                        <Route exact path={paths.account + "/signup"} render={props => <SignupPage {...props} basket={basket} signUp={signUp} token={token} />} />
                        <Route exact path={paths.account + "/orders"} render={props => <OrdersPage {...props} account={account} token={token} />} />
                        <Route exact path={paths.account + "/"} render={props => <AccountOverview {...props} account={account} orders={orders} logout={logout} token={token} updateOrderHistory={updateOrderHistory} updatePrimaryAddress={updatePrimaryAddress} />} />
                        <Redirect from='/' to={paths.notFound} />
                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </Route>

                <Route path={paths.help}>
                    <Switch>
                        <Route exact path={paths.help+"/faq"} component={FAQPage} />
                        <Route exact path={paths.help+"/privacy-policy"} component={PrivacyPolicyPage} />
                        <Route exact path={paths.help+"/cookie-policy"} component={CookiePolicyPage} />
                        <Route exact path={paths.help+"/terms-of-service"} component={TermsOfServicePage} />
                        <Route exact path={paths.help+"/delivery"} component={DeliveryPage} />
                        <Route exact path={paths.help+"/careers"} component={CareersPage} />
                        <Route exact path={paths.help+"/sustainability"} component={SustainabilityPage} />
                        <Route exact path={paths.help+"/charity"} component={CharityPage} />
                        <Route exact path={paths.help+"/covid19"} component={Covid19Page} />
                        <Redirect from='/' to={paths.notFound} />
                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </Route>

                <Route exact path="/home" render={props => <HomePage {...props} blackboards={blackboards} products={products} /> } />

                <Route path={paths.main}>
                    <Switch>
                        <Route exact path={paths.main + "contact"} component={ContactPage} />
                        <Route exact path={paths.main + "picks-of-the-week"} component={PicksOfTheWeekPage} />
                        <Route exact path={paths.main + "subscriptions"} render={props => <SubscriptionsPage {...props} subscriptions={subscriptions} />} />
                        <Route exact path={paths.main + "under-construction"} component={UnderConstructionPage} />
                        <Route exact path={paths.main + "who-we-are"} component={WhoWeArePage} />
                        <Route exact path={paths.main} render={props => <HomePage {...props} blackboards={blackboards} products={products} /> } />
                        <Redirect from='/' to={paths.notFound} />
                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                </Route>

            </Switch>
        )
    }

}

export default Body;