import React from 'react';

class VerifyGooglePage extends React.Component {

    render() {
        return (
            'google-site-verification: googlefbb8c304a48c4d47.html'
        )
    }
}

export default VerifyGooglePage;