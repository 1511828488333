import React from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './products.css';
import Tile from '../tile/tile.js';
import $ from 'jquery';
import { quickSortBySplit } from '../../../utils/objectUtils';
import utf16Utils, { toPlainAscii } from '../../../utils/utf16Utils';
import accentFuncs from '../../../utils/accentFormatter';

const { formatAccentsToElementArray } = accentFuncs;

const storageStrings = {
    wineProducts: 'wineProducts5',
    lastProductsCheck: 'lastProductsCheck5',
    filters: 'shopFilters',
    filteredProducts: 'shopFilteredProducts',
    searchTerm: 'shopSearchTerm',
    productsAfterSearch: 'shopProductsAfterSearch',
    sortingBy: 'shopSortingBy',
    sortedProducts: 'shopSortedProducts',
    currentPage: 'shopCurrentPage',
    allFilterLabels: 'shopAllFilterLabels'
}

const defaultTitle = "Tasty Grape Juice";
const defaultQuote = ["\“You people. You think you can just buy your way into this. Take a few lessons. Grow some grapes. Make some good wine.", "You cannot do it that way… you have to have it in your blood.", "You have to grow up with the soil beneath your nails, and the smell of the grape in the air that you breathe.", "The cultivation of the vine is an art form. The refinement of its juice is a religion that requires pain and desire and sacrifice\”", "Gustavo, Bottleshock (2008)"];

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                inclusiveF: {
                    "category": {} // "Orange": true, etc
                },
                charityOnly: false,
                exclusiveF: {
                    "country": {}, // "France": true, etc
                    "region": {},
                    "producer": {},
                    "grapeType": {},
                    "vintage": {}
                },
                exclusiveFOrder: [ 'country', 'region', 'producer', 'grapeType', 'vintage' ],
                specificsTerms: {}, // "Minimal sulfites": true, "Vegan": deleted, etc
                checkboxLabels: { // For render checkboxes and labels and true/false
                    'category': {},
                    'country': {},
                    'region': {},
                    'producer': {},
                    'grapeType': {},
                    'vintage': {},
                    'specifics': {}
                },
            },
            filteredProducts: [],
            searchTerm: '',
            productsAfterSearch: [],
            sortingBy: "ne",
            sortedProducts: [],
            currentPage: 1,
            specificsConversions: [ 
                { display: 'Biodynamic', dbName: 'biodynamic' },
                { display: 'Minimal sulfites', dbName: 'minimalS' },
                { display: 'Organic', dbName: 'organic' },
                { display: 'Sustainable', dbName: 'sustainable' },
                { display: 'Unfiltered', dbName: 'unfiltered' },
                { display: 'Unfined', dbName: 'unfined' },
                { display: 'Vegan', dbName: 'vegan' },
            ],
            allFilterLabels: { // One-time mount populate for when no filters are applied, Save on processing power
                'category': {},
                'country': {},
                'region': {},
                'producer': {},
                'grapeType': {},
                'vintage': {},
                'specifics': {}
            }
        };
    };

    componentDidMount() {
        const isMobile = window.innerWidth < 800;
        if (isMobile) {
            // Hide filters on load
            $('.filterContainer').removeClass('showFilters');
            $('#shopProductsContainer').removeClass('showFilters');
            $('.filterTitleContainer').removeClass('showFilters');
            $('.filtersShowText').removeClass('showFilters');
        }
        $('#shopTileBlBoard').addClass('defaultCategory');

        const hasProducts = this.props.shop.products.length > 0;
        if (!hasProducts) {
            console.log('componentDidMount does not have products');
        }
        
        this.setStateFromStorage();
    }



    /*      Default Filters     */

    handleInitFiltersInRender = () => {
        if ( this._defaultFiltersNotYetPopulated() ) {
            const { allFilterLabels, filters } = this.state;
            const { exclusiveFOrder } = filters;
            let products = this.props.shop.products || [];
            if (products.length === 0) {
                console.log('No products in props.shop');
                products = storageStrings.wineProducts || [];
                if (products.length === 0) {
                    console.log('No products in storage either');
                } else {
                    this.setState({ sortedProducts: products });
                }
            }
            const productsLen = products.length;
            if (productsLen) {
                // Has products
                this._initialPopulateOfFilterLabelsFromAllProducts(allFilterLabels, products, exclusiveFOrder);
                this.setState({ allFilterLabels });
            } else {
                // Warning, no products found, may cause errors
                console.log('Danger: no products from props or storage');
            }
        }
    }

    _defaultFiltersNotYetPopulated = () => { // Checks if the default list on 'no filters activated' list has been populated by checking the category part of the state object
        const { allFilterLabels } = this.state;
        const { category } = allFilterLabels;
        const cateogryKeys = Object.keys(category) || [];
        const cateogryKeysLen = cateogryKeys.length;

        const notYetPopulated = cateogryKeysLen === 0;
        return notYetPopulated;
    }




    /*      Filters     */

    _shopIsFiltering = (filters) => {
        const { inclusiveF, charityOnly, exclusiveF, specificsTerms } = filters;

        // check charityOnly
        if (charityOnly) return true;

        // check inclusiveF
        if (inclusiveF) {
            const isFilteringByInclusive = this._loopThroughFilterObjsToCheckIfFiltering(inclusiveF);
            if (isFilteringByInclusive) return true;
        }

        // check exclusiveF
        if (exclusiveF) {
            const isFilteringByExclusive = this._loopThroughFilterObjsToCheckIfFiltering(exclusiveF);
            if (isFilteringByExclusive) return true;
        }

        // check specificsTerms
        if (specificsTerms) {
            const isFilteringBySpecifics = this._filterHasActiveFilterTerms(specificsTerms);
            if (isFilteringBySpecifics) return true;
        }

        // if reached here, cannot find any applied filters at all. So, user is not filtering
        return false;
    }

    _loopThroughFilterObjsToCheckIfFiltering = (filterObjs) => { // returns true if any user filter terms found
        // filterObjs e.g. inclusiveF = { "catagory": {} }
        const filterObjsArrayToLoop = Object.keys(filterObjs) || []; // e.g. [ "country", "region", etc ]
        const filterObjsArrayToLoopLen = filterObjsArrayToLoop.length;

        for (let i=0; i<filterObjsArrayToLoopLen; i++) {
            // Loop through each filter category and call helper method to see if user is filtering by that filter category
            const filterObjectName = filterObjsArrayToLoop[i]; // e.g. "country"
            const filterObject = filterObjs[filterObjectName]; // e.g. { "Orange": true }
            const isFiltering = this._filterHasActiveFilterTerms(filterObject);
            if (isFiltering) {
                return true;
            }
        }
        // Looped through all filter objects, cannot find filtering (yet, may have to check charity and specifics still)
        return false;
    }

    _filterHasActiveFilterTerms = (filterTermsObject) => { // Checks filter object to see if it has any terms that the user is filtering by
        // Keys of terms (empty array if not filtering)
        const termKeysArray = Object.keys(filterTermsObject) || [];
        // Length of array of terms
        const termKeysArrayLen = termKeysArray.length;
        if (termKeysArrayLen > 0) {
            return true;
        }
        return false;
    }

    _toShopTop = () => {
        try {
            $("html, body").animate({ scrollTop: $("#topOfShop").offset().top }, "fast");    
        } catch (error) {
            //
        }
    }

    callApi = () => {
        this.props.productsToStateInitiate();
    }

    GetJSON = (item) => {
        return JSON.parse(localStorage.getItem(item));
    }

    handleChangePage = (e) => {
        let { currentPage } = this.state;
        currentPage = e.target.id;
        const currentPageNumber = parseInt(currentPage.substring(4));

        sessionStorage.setItem(storageStrings.currentPage,
            currentPageNumber );

        this._toShopTop();
        this.setState({ currentPage: currentPageNumber });
    }

    /*      Filter change       */

    // Step 0 handle click filter checkbox
    handleFilterCheckbox = (event) => {
        const filterTitle = event.target.name || ''; // category, grapeType, vintage etc
        const filterTerm = event.target.value; // France, Pinot Grigio, 2018 etc
        const newBool = $(event.target).prop('checked') ? true : false; // true or false

        this.handleNewFilterBehaviour(filterTitle, filterTerm, newBool);
    }

    // STEP 1       filter
    handleNewFilterBehaviour = (filterTitle, filterTerm, newBool) => {
        // Call when user clicks on a filter checkbox
        // Pass this new half-state with current search term to master handler function to update state

        // Get old state
        const { filters, searchTerm } = this.state;
        const { inclusiveF, exclusiveF, specificsTerms } = filters;
        let { charityOnly } = filters;

        // Update state accordingly
        // Using objects as either true if ticked, or delete if false
        switch (filterTitle) {

            case "category": if (newBool) {
                inclusiveF[filterTitle][filterTerm] = true;
            } else {
                delete inclusiveF.category[filterTerm];
            }
            filters['inclusiveF'] = inclusiveF;
            break;

            case "charity": charityOnly = newBool;
            filters['charityOnly'] = charityOnly;
            break;

            case "specifics": if (newBool) {
                specificsTerms[filterTerm] = true;
            } else {
                delete specificsTerms[filterTerm];
            }
            filters['specificsTerms'] = specificsTerms;
            break;

            default: if (newBool) {
                exclusiveF[filterTitle][filterTerm] = true;
            } else {
                delete exclusiveF[filterTitle][filterTerm];
            }
            filters['exclusiveF'] = exclusiveF;
            break;
        }

        // Should work out products from new state, and return products/state vars to then update new state after searchTerm:
        const allProducts = this.props.shop.products || [];
        const { filteredProducts, newFilterState } = this.convertNewFilterStateToProducts(filters, allProducts);

        // Now filter by search term if present
        this.applySearchTerm(searchTerm, newFilterState, filteredProducts);
    }

    // STEP 2       filter
    convertNewFilterStateToProducts = (filterState, allProducts) => {
        // Master new filter handler function
        // Turns new checkbox state + searchTerm to build new state of filtered products and checkboxs that are valid

        // Check if filtering
        const { inclusiveF, charityOnly, exclusiveF, specificsTerms, exclusiveFOrder } = filterState;
        const categoryTerms = Object.keys(inclusiveF["category"]) || []; // e.g. [red, white, orange, sparkling]
        const categoryTermsLen = categoryTerms.length;
        
        let filteredProducts = [];

        const checkboxLabels = {
            'category': {},
            'country': {},
            'region': {},
            'producer': {},
            'grapeType': {},
            'vintage': {},
            'specifics': {}
        };

        // Populate Category checkboxes as false initially
        const { allFilterLabels } = this.state;
        if (allFilterLabels) {
            const allFilterLabelsCategory = allFilterLabels['category'];
            if (allFilterLabelsCategory) {
                const catKeys = Object.keys(allFilterLabelsCategory);
                for (let i=0; i<catKeys.length; i++){
                    const categoryName = catKeys[i];
                    checkboxLabels['category'][categoryName] = false; // e.g. "Red"
                }
            }
        }

        /*  Get products from Category filters  */

        // Get category products
        if (categoryTermsLen) {
            // Is filtering (by category at least)
            const firstExclusive = exclusiveFOrder[0]; // "country"
            // Fetch products that match  category filter
            for (let i=0; i<allProducts.length; i++) {
                const loopProduct = allProducts[i];
                const loopProductCategory = loopProduct['category'] || ''; // e.g. this wine in the loop is a "Red"

                const match = inclusiveF["category"][loopProductCategory] || false; // match only if the filterNew inclusiveFilter for "category" obj has user-selected true
                if (match) { // Product matches the category filter(s)
                    // Correct category

                    if (charityOnly) { // Check if user also wants 
                        // Only add if charity also
                        const isCharity = loopProduct['charity'] || false;
                        if (!isCharity) continue; // User only wants charity products, so don't add to Filtered Products
                    }

                    // If reached this code, tests passed, add to filtered array
                    filteredProducts.push(loopProduct);

                    // Populate labels for next filter as false (helps with filtering later)
                    const firstExclusiveTerm = loopProduct[firstExclusive]; // e.g. "France"
                    checkboxLabels[firstExclusive][firstExclusiveTerm] = false;
                }
            }
            // Category Checkbox labels
            for (let i=0; i<categoryTermsLen; i++) {
                // Category labels populate the true vals
                const term = categoryTerms[i]; // e.g. "Red": true
                if (term) {
                    checkboxLabels['category'][term] = true;
                }
            }
        } else {
            // No category filters applied

            // Check charity
            if (charityOnly) {
                // User wants only charity products, check all products
                // Fetch products that match  category filter
                for (let i=0; i<allProducts.length; i++) {
                    const loopProduct = allProducts[i];
                    const charityMatch = loopProduct['charity'] || false;
                    if (charityMatch) {
                        // If code reached here, filtering by charity and product is charity, so add to filtered products array
                        filteredProducts.push(loopProduct);

                        // Populate labels for next filter as false (helps with filtering later)
                        const firstExclusive = exclusiveFOrder[0]; // "country"
                        const firstExclusiveTerm = loopProduct[firstExclusive]; // e.g. "France"
                        checkboxLabels[firstExclusive][firstExclusiveTerm] = false;
                    }
                }
            } else {
                // Not filtering by Category, nor by charity, so filtered products so far and all products
                filteredProducts = [...allProducts];

                // Populate labels for next filter as false (helps with filtering later) with all false for all possible countries from cached default
                const firstExclusive = exclusiveFOrder[0]; // "country"
                const allFilterLabelsFirstExclusive = allFilterLabels[firstExclusive]; // e.g. { "France": false, "Germany": false ... etc } all false
                if (allFilterLabelsFirstExclusive) {
                    const countryKeys = Object.keys(allFilterLabelsFirstExclusive);
                    for (let i=0; i<countryKeys.length; i++){
                        const countryName = countryKeys[i]; // e.g. "France"
                        checkboxLabels[firstExclusive][countryName] = false;
                    }
                }
            }
        }
        // Got filtered products from category
        // Now filter by country, if any selected


        /*  Get products from middle filters  */

        // Greate labels by looping through exclusive filter
        for (let i=0; i<exclusiveFOrder.length; i++) {
            const filterName = exclusiveFOrder[i]; // i of: [ 'country', 'region', 'producer', 'grapeType', 'vintage' ]
            // e.g. 'country'

            // Get old modified state (which may contain invalid labels, if previous filter has narrowed results down further)
            // Generate labels and if they are ticked
            // Loop through remaining products to get potential labels
            // When multiple selected, choose either/or
            const filterTermsObj = exclusiveF[filterName]; // e.g. { "England": true }
            const filterTerms = Object.keys(filterTermsObj) || []; // e.g. [ "England", "France" , ... ]

            let isFilteringByCurrentSection = false;
            // Check if we are filtering by this type of filter
            // Loop through the current "section" of the user's filters (true values within the section) and check if we actually have those pre-populated (from last step) false values to then switch
            //  If at least one of them can be found as a false value, then we can futher filter by this section.
            //  If none are found (i.e. a previous Category filter has been disabled and we lose this Country), then we just skip this "section"
            
            // Loop through current section's user's true selections, to see if we can further filter by this section due to changes where true value could now be hidden
            const filterTermsLen = filterTerms.length;
            for (let j=0; j<filterTermsLen; j++) {
                const egFrance = filterTerms[j];
                const falseOrNull = checkboxLabels[filterName][egFrance]; // If products are available, it will be false. If not: null or undefined maybe?
                if ( falseOrNull === false) {
                    // We can filter by this section, break
                    isFilteringByCurrentSection = true;
                    break;
                }
            }

            if (isFilteringByCurrentSection) {
                // We -ARE- (and CAN) filtering by this type i.e. by 'country' such as 'France' OR 'Germany'
                let filteredProductsTemp = [];
                for (let j=0; j<filteredProducts.length; j++) {
                    // Go through each remaining product and generate labels and check boxes for this level
                    const filteredProd = filteredProducts[j]; // e.g. { id: 1, name: "Wine A" }
                    const prodLabel = filteredProd[filterName]; // e.g. prod['country'] e.g. "France"

                    // Check if label is in user's selection (true)
                    const isFilteringBy = filterTermsObj[prodLabel];
                    if (isFilteringBy && prodLabel) {
                        // Checkbox ticked, add to matched products
                        filteredProductsTemp.push(filteredProd);
                        checkboxLabels[filterName][prodLabel] = true;

                        // Populate all next filter's terms as false
                        // if we are not at the end of the loop (so we don't go out of array bounds)
                        if ( i < exclusiveFOrder.length-1 ) {
                            // Still within exclusive filters (but not at the last one)
                            const nextInteration = i+1;
                            const nextFilterName = exclusiveFOrder[nextInteration]; // e.g. "Region"
                            const prodNextLabel = filteredProd[nextFilterName]; // e.g. "Paris"
                            if (prodNextLabel) checkboxLabels[nextFilterName][prodNextLabel] = false;
                        } else {
                            // At the last exclusive filter (vintage)
                            //  Populate available specifics checkboxes as false from current filtered product set
                            const { specificsConversions } = this.state;
                            for (let k=0; k<specificsConversions.length; k++) {
                                const filterLabelObj = specificsConversions[k]; // e.g. { display: 'Minimal sulfites', dbName: 'minimalS' }
                                const egVegan = filterLabelObj['dbName']; // e.g. 'vegan'
                                const egVeganDisplay = filterLabelObj['display']; // e.g. 'Vegan'
                                const isPotentialLabel = filteredProd[egVegan];
                                if (isPotentialLabel) {
                                    // Check current product to see if any of the specifics (from a loop) could apply
                                    //  mark as false for now
                                    checkboxLabels['specifics'][egVeganDisplay] = false;
                                }
                            }
                        }
                    } else {
                        // Not filtered by, add unticked label but don't add product
                        const alreadyTicked = checkboxLabels[filterName][prodLabel] || false;
                        if (!alreadyTicked && prodLabel) checkboxLabels[filterName][prodLabel] = false;
                    }
                }
                // Overwrite remaining filtered products
                filteredProducts = filteredProductsTemp;
                // end if
            } else {
                // Not filtering
                //  Keep all current filteredProducts as they are
                //  populate all next filter's terms as false
                //  if we are not at the end of the loop (so we don't go out of array bounds)
                if ( i < exclusiveFOrder.length-1 ) {
                    // Still within exclusive filters (but not at the last one)
                    const nextInteration = i+1;
                    const nextFilterName = exclusiveFOrder[nextInteration]; // e.g. "Region"
                    for (let j=0; j<filteredProducts.length; j++) {
                        // Go through each remaining product and generate labels and default false check boxes for this level
                        const filteredProd = filteredProducts[j];
                        const prodLabel = filteredProd[nextFilterName]; // e.g. "Paris"
                        if (prodLabel) checkboxLabels[nextFilterName][prodLabel] = false;
                    }
                } else {
                    // At the last exclusive filter (vintage)
                    //  Populate available specifics checkboxes as false from current filtered product set
                    for (let j=0; j<filteredProducts.length; j++) {
                        // Go through each remaining product and generate labels and default false check boxes for this level
                        const filteredProd = filteredProducts[j];
                        // Check current product to see if any of the specifics (from a loop) could apply
                        //  mark as false for now
                        const { specificsConversions } = this.state;
                        for (let k=0; k<specificsConversions.length; k++) {
                            const filterLabelObj = specificsConversions[k]; // e.g. { display: 'Minimal sulfites', dbName: 'minimalS' }
                            const egVegan = filterLabelObj['dbName']; // e.g. 'vegan'
                            const egVeganDisplay = filterLabelObj['display']; // e.g. 'Vegan'
                            const isPotentialLabel = filteredProd[egVegan];
                            if (isPotentialLabel) {
                                checkboxLabels['specifics'][egVeganDisplay] = false;
                            }
                        }
                    }
                }
            }
            // End if/else isFiltering
        } // End of looping through exclusive filters

        // Looped through all "exlusive filters"
        // Finally populate specifics

        // Onto specifics as final step
        // Loops through each possible specific dbTerm and check each product as inner loop
        // Must match all if multiple, but that's for later step
        const specificsTermsKeys = Object.keys(specificsTerms) || []; // e.g. user's true filters [ 'Minimal Sulfites': true, 'Vegan': true ]

        let filteredProductsAfterSpecifics = [];

        let isFilteringBySpecifics = false;
        // Is only filtering by specifics if at least one of the user-selection specific filters is present in the avialble false-labels from earlier
        //  If no user-selected specific filters are available from false-labels, just show all products filtered so far
        for (let i=0; i<specificsTermsKeys.length; i++) {
            const labelDisplay = specificsTermsKeys[i]; // e.g. user wants to filter by 'Minimal Sulfites'
            const falseOrNull = checkboxLabels['specifics'][labelDisplay]; // If products are available, it will be false. If not: null or undefined maybe?
            if ( falseOrNull === false ) {
                // We can filter by this section, break
                isFilteringBySpecifics = true;
                checkboxLabels['specifics'][labelDisplay] = true;
            }
        }

        /*  Get products from specifics filters  */

        if (isFilteringBySpecifics) {
            // Is filtering by specifics, each remaining item so far needs to hit all terms
            for (let i=0; i<filteredProducts.length; i++) {
                const remainingProduct = filteredProducts[i];
                let productMatches = true;
                for (let j=0; j<specificsTermsKeys; j++) {
                    const labelDisplay = specificsTermsKeys[j];
                    const labelDb = this._specificNameToDbField(labelDisplay);
                    if (!remainingProduct[labelDb]) {
                        // Match not found, flag to discard (continue outer loop) and break out of this, no need to check other specifics filters for this product
                        productMatches = false;
                        break;
                    }
                }
                if (productMatches) {
                    // If code reached here, passed all specific filters, so add to final array
                    filteredProductsAfterSpecifics.push(remainingProduct);
                }
            }
        } else {
            // Not filtering by specifics, just add all products so far
            // Also leave all labels as false
            filteredProductsAfterSpecifics = [...filteredProducts];
        }

        filterState['checkboxLabels'] = checkboxLabels;

        // Done all filtering by checkboxes
        // Pass on args to then filter by search
        return {
            filteredProducts: filteredProductsAfterSpecifics,
            newFilterState: filterState
        }
    } // end of convertNewFilterStateToProducts



    // Step 0       search
    handleSearchKeyUp = async (event) => {
        const searchTermRaw = event.target.value || '';

        if (searchTermRaw === '') {
            // Blank search term, show already filtered products
            const { filters, filteredProducts, sortingBy } = this.state;
            // If no search term, just pass in empty array for productsAfterSearch - as it will just ignore them and use filteredProducts (since productsAfterSearch would just be unecessary duplate varaible of filteredProducts, thereby wasting RAM)
            const productsAfterSearch = [];
            const $spinner = $('#searchSpinner');
            $spinner.removeClass('spin');
            this.applySorting('', filters, filteredProducts, productsAfterSearch, sortingBy);
        }

        // Activate spinner and wait for stop typing
        const $spinner = $('#searchSpinner');
        $spinner.addClass('spin');
        setTimeout(() => {
            const $searchbox = $('#searchBar');
            const newTermRaw = $searchbox.val() || '';
            if (newTermRaw === searchTermRaw) {
                this.handleNewChangeSearchTerm(searchTermRaw);
            }
        }, 500);
        this.setState({ searchTerm: searchTermRaw });
    }

    // STEP 1       search
    handleNewChangeSearchTerm = (newSearchTerm) => {
        const $spinner = $('#searchSpinner');
        $spinner.removeClass('spin');

        // Call when user finished typing, updates state with new products (eventually)
        const { filters, filteredProducts } = this.state;
        this.applySearchTerm(newSearchTerm, filters, filteredProducts);
    }


    // STEP 3       both filter and search
    applySearchTerm = (newSearchTerm, filters, filteredProducts) => {
        // Take products and search term, return products ready for sorting
        // search for wine name, or grape type... any more?
        let productsAfterSearch = [];

        if (newSearchTerm) {
            // Is searching
            const attributesToSearch = [ 'name', 'producer', 'grapeType' ];
            // Loop products
            for (let i=0; i<filteredProducts.length; i++) { // each product
                const product = filteredProducts[i];
                // Loop which attributes to check for containing
                for (let j=0; j<attributesToSearch.length; j++) { // e.g. 'grapeType'
                    const attribute = attributesToSearch[j];
                    const val = product[attribute] || null;
                    if (val) {
                        // We have a valid value to check
                        // Sanitise search term & product val
                        const searchTermPlainLower = toPlainAscii(newSearchTerm).toLowerCase();
                        const valPlainLower = toPlainAscii(val).toLowerCase();
                        const matchFound = valPlainLower.includes(searchTermPlainLower);
                        if (matchFound) {
                            // Match found, no need to check other attr, add and break out inner loop, onto next product
                            productsAfterSearch.push(product);
                            break;
                        }
                    }
                }
            }
        }

        const { sortingBy } = this.state;
        // Pass on products for sorting
        this.applySorting(newSearchTerm, filters, filteredProducts, productsAfterSearch, sortingBy);
    }


    // STEP 4 final       sorting, after filter and search
    applySorting = (searchTerm, filters, filteredProducts, productsAfterSearch, sortingBy) => {
        // If no search term, productsAfterSearch will be empty to save RAM, so use filteredProducts if no search term
        const products = searchTerm ? productsAfterSearch : filteredProducts;

        let sorted = [];
        switch (sortingBy) {
            case "az":
                sorted = this._handleQuickSortBySplit(products, "name"); break;
            case "za":
                sorted = this._handleQuickSortBySplit(products, "name");
                sorted.reverse(); break;
            case "lh":
                sorted = this._handleQuickSortBySplit(products, "price"); break;
            case "hl":
                sorted = this._handleQuickSortBySplit(products, "price");
                sorted.reverse(); break;
            case "ne":
                sorted = this._handleQuickSortBySplit(products, "arrivalTime");
                sorted.reverse(); break;
            case "po":
                sorted = this._handleQuickSortBySplit(products, "viewCount");
                sorted.reverse(); break;
            default:
                sorted = this._handleQuickSortBySplit(products, "arrivalTime");
                sorted.reverse(); break;;
        }

        const sortedSplit = this._forceOutOfStockToEnd(sorted);
        
        const firstPage = 1;

        const state = this.state;
        state.filters = filters;
        state.productsAfterSearch = productsAfterSearch;
        state.sortedProducts = sortedSplit;
        state.searchTerm = searchTerm;
        state.sortingBy = sortingBy;
        state.currentPage = firstPage;

        const allFilterLabels = state.allFilterLabels;

        this.cacheStateToStorage(
            filters, filteredProducts,
            searchTerm, productsAfterSearch,
            sortingBy, sortedSplit,
            allFilterLabels, firstPage
        );

        this.setState({
            filters,
            filteredProducts,
            productsAfterSearch,
            sortedProducts: sortedSplit,
            searchTerm,
            sortingBy,
            currentPage: 1
        });
    }

    _handleQuickSortBySplit = (products, sortBy) => {
        const { sorted } = quickSortBySplit(products, sortBy);
        return sorted;
    }

    _initialPopulateOfFilterLabels = (allFilterLabels, product, exclusiveFOrder) => {  // don't confuse "initial" with page mount, this is first flase pass for active filtering
        // Takes filter object by reference
        // Takes a product (usually in a loop through allProducts)
        // Strips out all country/region/grapeType etc and populates list to be used for generating potential filter checkbox labels
        // Default to False, since user filter choices will overwrite them with True later ( I think )

        // Exlusive filters
        for (let j=0; j<exclusiveFOrder.length; j++) {
            const attribute = exclusiveFOrder[j];
            const term = product[attribute] || '';
            if (term) allFilterLabels[attribute][term] = false;
        }

        // Inclusive filter (category)
        const categoryTerm = product['category'] || '';
        if (categoryTerm) allFilterLabels['category'][categoryTerm] = false;
    }
    _initialPopulateOfFilterLabelsFromAllProducts = (allFilterLabels, products, exclusiveFOrder) => {
        // See above, this just goes through all products and calls method to generate labels for filters
        const { specificsConversions } = this.state;
        const specificsConversionsLen = specificsConversions.length;

        for (let i=0; i<products.length; i++) {
            const product = products[i];
            this._initialPopulateOfFilterLabels(allFilterLabels, product, exclusiveFOrder);
            
            // Specifics edge case:
            const specificsNotYetFull = Object.keys(allFilterLabels['specifics']).length < specificsConversionsLen;
            if (specificsNotYetFull) {
                for (let j=0; j<specificsConversionsLen; j++) {
                    const filterSpecificObj = specificsConversions[j]; // { display: 'Organic', dbName: 'organic' }
                    const dbName = filterSpecificObj['dbName'] || ''; // e.g. "organic" property
                    const productHasThisSpecific = product[dbName];
                    if (productHasThisSpecific) {
                        const label = filterSpecificObj['display'] || ''; // e.g. "Organic" label
                        allFilterLabels['specifics'][label] = false;
                    }
                }
            }
        }
    }

    resetFilters = () => {
        const filters = this.state.filters;
        filters.inclusiveF = {
            "category": {}
        };
        filters.charityOnly = false;
        filters.exclusiveF = {
            "country": {},
            "region": {},
            "producer": {},
            "grapeType": {},
            "vintage": {}
        };
        filters.specificsTerms = {};
        filters.checkboxLabels = this.state.allFilterLabels;

        const products = this.props.shop.products || [];
        const noSearchTerm = '';
        const productsAfterSearch = [];
        const sortingBy = 'ne';

        this._clearShopStateFromStorage();

        this.applySorting(noSearchTerm, filters, products, productsAfterSearch, sortingBy);
    }

    _clearShopStateFromStorage = () => {
        sessionStorage.removeItem(storageStrings.filters);
        sessionStorage.removeItem(storageStrings.filteredProducts);

        sessionStorage.removeItem(storageStrings.searchTerm);
        sessionStorage.removeItem(storageStrings.productsAfterSearch);

        sessionStorage.removeItem(storageStrings.sortingBy);
        sessionStorage.removeItem(storageStrings.sortedProducts);

        sessionStorage.removeItem(storageStrings.currentPage);
    }

    /*      Helper methods      */

    _specificNameToDbField = (name) => {
        if ( name === 'Minimal sulfites' ) {
            return 'minimalS';
        } else {
            return name.toLowerCase();
        }
    }

    // specificDbFieldToName = (dbField) => {
    //     if ( dbField === 'minimalS' ) {
    //         return 'Minimal sulfites';
    //     } else {
    //         return this.capitalizeFirst(dbField);
    //     }
    // }

    // capitalizeFirst = (s) => {
    //     if (typeof s !== 'string') return ''
    //     return s.charAt(0).toUpperCase() + s.slice(1)
    // }

    getSortingOptions = () => {
        const optionsArray = [
            { val: "ne", text: "Newest" },
            { val: "az", text: "Name (A to Z)" },
            { val: "za", text: "Name (Z to A)" },
            { val: "lh", text: "Price (Low to High)" },
            { val: "hl", text: "Price (High to Low)" },
            { val: "po", text: "Popularity" }
        ];

        const returnOptions = [];

        for (let i=0; i<optionsArray.length; i++) {
            const option = optionsArray[i];
            const { val, text } = option;
            const el = <option value={val}>{text}</option>;
            returnOptions.push(el);
        }

        return returnOptions;
    }

    getTilesForThisPage = () => {
        const { sortedProducts, currentPage } = this.state;
        let productTiles = [];

        const { addRemoveToBasket } = this.props.shop;
        // Product count to display per page
        const windowWidth = window.innerWidth;
        const tabletOrSmaller = windowWidth < 1192;
        const productsPerPage = tabletOrSmaller ? 8 : 16;
        // Get loop data
        const sortedProductsLen = sortedProducts ? sortedProducts.length : 0;
        const startingIndex = sortedProductsLen > productsPerPage ? (currentPage - 1) * productsPerPage : 0;
        const endingIndex = sortedProductsLen > productsPerPage * currentPage ? currentPage * productsPerPage : sortedProductsLen;
        // Loop
        for (let i = startingIndex; i < endingIndex; i++) {
            const isDarkBg = this.isDarkBg(windowWidth, i);
            const product = sortedProducts[i] || {};
            productTiles.push(<Tile
                key={'tile-' + product.id}
                product={product}
                addRemoveToBasket={addRemoveToBasket}
                isDarkBg={isDarkBg}
            />);
        }
        return productTiles;
    }

    isDarkBg = (windowWidth, i) => {
        // n
        let modulo = 8;
        let darkIndexes = [4, 5];
        i = i%16;
        // breakpoints
        if (windowWidth < 1450) {
            modulo = 4;
            darkIndexes = [0]
            i = i%16;
        } else if (windowWidth < 1801) {
            modulo = 6;
            darkIndexes = [3, 4];
        }
        const elPosition = (i+1)%modulo;
        if (darkIndexes.includes(elPosition)) {
            return true;
        }
        return null;
    }

    handleChangeSortBy = (event) => {
        const sortingBy = event.target.value;

        const { searchTerm, filters, filteredProducts, productsAfterSearch } = this.state;
        this.applySorting(searchTerm, filters, filteredProducts, productsAfterSearch, sortingBy);
    }

    formatNameSync = (name) => {
        if (name) {
            const titleHtml = formatAccentsToElementArray(name);
            return titleHtml;
        }
        return name;
    }

    getShopTitleAndQuote = () => {
        const classes = {
            defaultCategory: "defaultCategory",
            orange: "orange",
            charity: "charityQuoteSmaller"
        }

        // Chairty overwrite
        const charityOnly = this.state.filters.charityOnly;
        if (charityOnly) {
            const title = "Charity";
            const quote = [
                "\“Unless someone like you cares a whole awful lot, nothing is going to get better. It’s not\” Dr.Seuss", " ",
                "In the core values of Carafe, ‘We care’ is one of our most integral values. Giving back is part of our DNA and rightly so. We do not want to be a corporate machine, we strive to share in the local spirit and contribute how we can.",
                "As part of this in addition to our ‘1% for the Planet initiative’ membership we have coined the idea of ‘Charity wines’. We choose a different wine in each core category every month as our ‘Charity wines’ and with every purchase of these wines 25% of the profits will go to our nominated charity. We rotate the charities on a regular basis so that we can share the love with as many causes as possible.", " ",
                "Current nominated Charity Wine beneficiary: LoveCycle"
            ];
            const className = classes.charity;
            return { title, quote, className };
        }

        const categoryObj = this.state.filters.inclusiveF['category'] || {};
        const keys = Object.keys(categoryObj) || [];
        const keysLen = keys.length;

        if (keysLen) {
            // Filtering by category
            const index = keysLen - 1;
            const categoryAccented = keys[index];
            const categoryPlain = toPlainAscii(categoryAccented);
            const { title, quote, className } = this.categoryToTitleObject(categoryPlain);
            const titleFormatted = this.formatNameSync(title);
            return { title: titleFormatted, quote, className };
        }

        // Not filtering
        const defaultTitleAndQuote = {
            title: defaultTitle,
            quote: defaultQuote,
            className: classes.defaultCategory
        }
        return defaultTitleAndQuote;
    }

    categoryToTitleObject = (category) => {
        let title = "";
        let quote = [];
        let className = "";
        const classes = {
            defaultCategory: "defaultCategory",
            orange: "orange",
            charity: "charityQuoteSmaller"
        }
        // $('#shopTileBlBoard').addClass('orange');

        switch (category) {
            case "White":
                title = "White";
                quote = ["\“I don’t want to get you drunk, but, ah, that’s a very fine Chardonnay you’re not drinking\”", "Patrick Bateman, American Psycho (2000)"];
                break;
            case "Orange":
                title = "Orange";
                quote = ["Disclaimer - No oranges were harmed in the making of these wines!",
                    "What is orange wine you may be asking? Well i’ll tell you *dramatically blows bubblegum*",
                    "Orange wine is white wine made with red wine making techniques - the skin contact gives the wine an orange hue, the same way rosé is pink from contact with red grape skins.",
                    "Many orange wines are low-intervention wines and some are made traditionally in amphoras (big ceramic vessels).",
                    "They are usually robust and bold and can present aromas such as honeyed jackfruit, sourdough, juniper, bruised apple and hazelnut.",
                    "Orange wines can differ massively in style from producer-producer and grape to grape - they are an experience, a wine to try for the first time sitting down."];
                className = classes.orange;
                break;
            case "Rose":
                title = "Rosé";
                quote = ["\“Wine is like people. The wine takes all the influences in life all around it, absorbs them and it gets its personality.\”", "Luc (1995) French Kiss"];
                break;
            case "Sparkling":
                title = "Sparkling";
                quote = ["\“I love how wine continues to evolve, how every time I open a bottle it’s going to taste different than if I had opened it any other day.", "Because a bottle of wine is actually alive - it’s constantly evolving and gaining complexity.", "That is, until it peaks - like your ‘61 - and begins its steady, inevitable decline. And it tastes so f**king good.\”", "Maya, Sideways(2004)"];
                className = classes.orange;
                break;
            default:
                title = "Red";
                quote = ["\“A census taker once tried to test me. I ate his liver with some fava beans and a nice chianti\”",
                "Hannibal Lecter, Silence of the Lambs (1991)"];
                break;
        }

        return { title, quote, className };
    }

    CreatePageNumbers = (productsCount) => {
        const tabletOrSmaller = window.innerWidth < 1192;
        const productsPerPage = tabletOrSmaller ? 8 : 16;
        const numberOfPages = Math.ceil(productsCount / productsPerPage) || 1;
        let returnJSX = []
        for (let i = 0; i < numberOfPages; i++) {
            returnJSX.push(<div key={`page${i + 1}`} className="pageNumberContainer"><div onClick={this.state.currentPage == i + 1 ? null : this.handleChangePage} id={`page${i + 1}`} className={this.state.currentPage == i + 1 ? "pageNumber currentPage" : "pageNumber"}>&nbsp;{i + 1}&nbsp;</div>|</div>);
        }
        return returnJSX;
    }


    hideCarts = (evt) => {
        $('div.tileOverlay').slideUp();
    }

    setStateFromStorage = () => {
        let state = this.state;
        // Filter
        let filters = state.filters;
        let filteredProducts = state.filteredProducts;
        // Search
        let searchTerm = state.searchTerm;
        let productsAfterSearch = state.productsAfterSearch;
        // Sorting
        let sortingBy = state.sortingBy;
        let sortedProducts = state.sortedProducts;
        // Extra
        let allFilterLabels = state.allFilterLabels;
        let currentPage = state.currentPage;

        let hasFetched = false;

        // Products
        const storageSortedProdsString = sessionStorage.getItem(storageStrings.sortedProducts) || '';
        if (storageSortedProdsString) {
            const parsed = JSON.parse(storageSortedProdsString);
            if (parsed) {
                sortedProducts = parsed;
                hasFetched = true;
            }
        }
        if (!hasFetched) {
            // Force re-render on first mount
            const allProductsString = localStorage.getItem(storageStrings.wineProducts);
            if (allProductsString) {
                const parsed = JSON.parse(allProductsString);
                if (parsed) {
                    const sorted = this._handleQuickSortBySplit(parsed, "arrivalTime").reverse();
                    const sortedSplit = this._forceOutOfStockToEnd(sorted);
                    filteredProducts = sortedSplit;
                    sortedProducts = sortedSplit;
                    hasFetched = true;
                }
            }
        }

        // Filter
        const storageFiltersString = sessionStorage.getItem(storageStrings.filters) || '';
        if (storageFiltersString) {
            const parsed = JSON.parse(storageFiltersString);
            if (parsed) { filters = parsed; hasFetched = true; }
        }
        const storageFilterProdsString = sessionStorage.getItem(storageStrings.filteredProducts) || '';
        if (storageFilterProdsString) {
            const parsed = JSON.parse(storageFilterProdsString);
            if (parsed) { filteredProducts = parsed; hasFetched = true; }
        }
        // Search
        const storageSearchString = sessionStorage.getItem(storageStrings.searchTerm) || '';
        if (storageSearchString) {
            const parsed = JSON.parse(storageSearchString);
            if (parsed) { searchTerm = parsed; hasFetched = true; }
        }
        const storageSearchProdsString = sessionStorage.getItem(storageStrings.productsAfterSearch) || '';
        if (storageSearchProdsString) {
            const parsed = JSON.parse(storageSearchProdsString);
            if (parsed) { productsAfterSearch = parsed; hasFetched = true; }
        }
        // Sorting
        const storageSortingString = sessionStorage.getItem(storageStrings.sortingBy) || '';
        if (storageSortingString) {
            sortingBy = storageSortingString;
            hasFetched = true;
        }
        // Extra
        const storageAllShopLabelsString = sessionStorage.getItem(storageStrings.allFilterLabels) || '';
        if (storageAllShopLabelsString) {
            const parsed = JSON.parse(storageAllShopLabelsString);
            if (parsed) { allFilterLabels = parsed; hasFetched = true; }
        }
        const storageCurrentPageString = sessionStorage.getItem(storageStrings.currentPage) || '';
        if (storageCurrentPageString) {
            const parsed = parseInt(storageCurrentPageString);
            if (parsed) { currentPage = parsed; hasFetched = true; }
        }

        if (hasFetched) {
            this.setState({
                filters, filteredProducts,
                searchTerm, productsAfterSearch,
                sortingBy, sortedProducts,
                allFilterLabels, currentPage
            });
            return;
        }
        // Try to force render
        const shopRef = this.props.shop;
        const productsFromProps = shopRef['products'];
        if (productsFromProps) {
            // State from props
            this.initDefaultProducts(productsFromProps);
            return;
        }
        // State from storage
        const productskey = storageStrings.wineProducts || '';
        if (!productskey) {
            return;
        }
        const jsonString = localStorage.getItem(productskey) || '';
        if (!jsonString) {
            return;
        }
        const parsedProducts = JSON.parse(jsonString);
        if (parsedProducts) {
            this.initDefaultProducts(parsedProducts);
        }
    }

    initDefaultProducts = (products) => {
        const sorted = this._handleQuickSortBySplit(products, "arrivalTime").reverse();

        const stringified = JSON.stringify(sorted);
        sessionStorage.setItem(storageStrings.sortedProducts, stringified);

        this.setState({ sortedProducts: sorted, currentPage: 1, sortingBy: 'ne' });
        return;
    }

    cacheStateToStorage = ( filters, filteredProducts,
        searchTerm, productsAfterSearch,
        sortingBy, sortedProducts,
        allFilterLabels, pageNumber ) => {
        
        sessionStorage.setItem(storageStrings.filters,
            JSON.stringify(filters) );
        sessionStorage.setItem(storageStrings.filteredProducts,
            JSON.stringify(filteredProducts) );

        sessionStorage.setItem(storageStrings.searchTerm,
            JSON.stringify(searchTerm) );
        sessionStorage.setItem(storageStrings.productsAfterSearch,
            JSON.stringify(productsAfterSearch) );

        sessionStorage.setItem(storageStrings.sortingBy,
            sortingBy );
        sessionStorage.setItem(storageStrings.sortedProducts,
            JSON.stringify(sortedProducts) );

        sessionStorage.setItem(storageStrings.allFilterLabels,
            JSON.stringify(allFilterLabels) );
        sessionStorage.setItem(storageStrings.currentPage,
            pageNumber );
    }

    _forceOutOfStockToEnd = (products) => {
        products = products.length > 0 ? products : [];
        let returnProducts = [];
        let outOfStock = [];
        for (let i=0; i<products.length; i++) {
            const product = products[i];
            if (product.stock > 0) {
                returnProducts.push(product);
            } else {
                outOfStock.push(product);
            }
        }
        const splitArray = returnProducts.concat(outOfStock);
        return splitArray;
    }


    _shouldIncludeProduct = (product) => {
        const { filters } = this.state;
        for (let key in filters) {
            const filterObj = filters[key];
            // Loop through filters to include Product
            const filterTerms = filterObj[0] || [];
            const filterName = filterObj[1];

            if (filterTerms.length < 1) {
                // Empty filter, try next
                continue;
            }

            // Skip charity
            if (filterName !== 'charity') {
                // E.g. Category 
                const productAttribute = product[filterName];

                // Check if product attribute (category etc) is in filterTerms
                if (filterTerms.includes(productAttribute)) {
                    // Match
                    return true;
                }
            }
        }
        // Checked every filter, no matches
        return false;
    }

    _applyCharityFilters = (products) => {
        const { filters } = this.state;
        const filterTerms = filters['charityFilter'][0] || [];
        const userWantsCharityOnly = filterTerms.includes(true);
        if (userWantsCharityOnly) {
            // User wants charity only
            return products.filter(product => product.charity === true);
        } else {
            // Charity not selected
            return products;
        }
    }

    toggleFilters = () => {
        $('.filterContainer').toggleClass('showFilters');
        $('#shopProductsContainer').toggleClass('showFilters');
        $('.filterTitleContainer').toggleClass('showFilters');
        $('.filtersShowText').toggleClass('showFilters');
    }

    spinner = () => {
        return (
            <div className="spinnerContainer">
                <div id="searchSpinner" className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }

    getFilterLabelObjects = () => { // Called from render to, use as filter labels and checkbox states. Returns object of objects to be expanded
        const { filters } = this.state;
        const shopIsFiltering = this._shopIsFiltering(filters);

        if (shopIsFiltering) {
            // Filters are being applied, get labels from filters

            const { checkboxLabels } = filters;
            const categoryLabelObj = checkboxLabels['category'];
            const charityOnly = filters.charityOnly;
            // Exclusive
            const countryLabelObj = checkboxLabels['country'];
            const regionLabelObj = checkboxLabels['region'];
            const producerLabelObj = checkboxLabels['producer'];
            const grapeTypeLabelObj = checkboxLabels['grapeType'];
            const vintageLabelObj = checkboxLabels['vintage'];
            // Specifics
            const specificsLabelObj = checkboxLabels['specifics'];

            const returnObj = {
                categoryLabelObj,
                charityOnly,
                countryLabelObj,
                regionLabelObj,
                producerLabelObj,
                grapeTypeLabelObj,
                vintageLabelObj,
                specificsLabelObj
            };
            return returnObj;

        } else {
            // Just return full list of default labels

            const { allFilterLabels } = this.state;
            const categoryLabelObj = allFilterLabels['category'] || {};
            const charityOnly = filters.charityOnly || false;
            // Exclusive
            const countryLabelObj = allFilterLabels['country'] || {};
            const regionLabelObj = allFilterLabels['region'] || {};
            const producerLabelObj = allFilterLabels['producer'] || {};
            const grapeTypeLabelObj = allFilterLabels['grapeType'] || {};
            const vintageLabelObj = allFilterLabels['vintage'] || {};
            // Specifics
            const specificsLabelObj = allFilterLabels['specifics'] || {};

            const returnObj = {
                categoryLabelObj,
                charityOnly,
                countryLabelObj,
                regionLabelObj,
                producerLabelObj,
                grapeTypeLabelObj,
                vintageLabelObj,
                specificsLabelObj
            };
            return returnObj;
        }
    }

    render() {
        updateNav();
        let products = this.props.shop.products || [];
        if (!products || products.length < 1) {
            products = this.GetJSON(storageStrings.wineProducts);
            if (!products) {
                this.callApi();
            }
            setTimeout(function () {
                if (!products) {
                    document.location.reload();
                }
            }, 2000);
        }

        this.handleInitFiltersInRender();

        // Shop title and quote
        const titleQuoteObj = this.getShopTitleAndQuote();
        const shopTitle = this.formatNameSync(titleQuoteObj.title);
        let quoteKeyInt = 0;
        const shopQuotes = <div>{titleQuoteObj.quote.map(quote => { return <p key={'quote-' + quoteKeyInt++}>{quote}</p> })}</div>;
        const blackboardClassName = "standardFontResponsive " + titleQuoteObj.className;
        
        // Select options
        const sortingBy = sessionStorage.getItem(storageStrings.sortingBy) || this.state.sortingBy;
        const sortingOptions = this.getSortingOptions();

        // Get tiles
        const tilesToShow = this.getTilesForThisPage();
        const totalProductCount = this.state.sortedProducts.length;

        // Gen page elements
        const productPages = this.CreatePageNumbers(totalProductCount);

        // Filters
        const { categoryLabelObj,
            charityOnly,
            countryLabelObj, 
            regionLabelObj, 
            producerLabelObj, 
            grapeTypeLabelObj, 
            vintageLabelObj, 
            specificsLabelObj
        } = this.getFilterLabelObjects();

        return (
            <div className="shopPage">
                <div id="shopTileBlBoard" className={blackboardClassName}>
                    <h4>{shopTitle}</h4>
                    {shopQuotes}
                </div>
                <div className="sortByContainer">
                    <form id="additionalShopSearchForm">
                        <div className="additionalShopSearchMethods">
                            {this.spinner()}
                            <label>Search: </label>
                            <input type="text" id="searchBar" placeholder="search by name" onChange={this.handleSearchKeyUp} value={this.state.searchTerm} />
                        </div>
                        <div className="additionalShopSearchMethods">
                            <label>Sort by: </label>
                            <select onChange={this.handleChangeSortBy} value={sortingBy}>
                                {sortingOptions}
                            </select>
                        </div>
                    </form>
                </div>
                <div id="topOfShop" className="productsPage">
                    <div className="filterTitleContainer showFilters">
                        <h5 className="courierText" id="filterHeader" onClick={this.toggleFilters}><span className="filtersShowText showFilters">Show&nbsp;</span>Filters</h5>
                    </div>

                    <div className="filterContainer showFilters">
                        <form className="filtersForm">
                            <br />
                            <div className="filterCollection">
                                <label className="courierText filterName">Category: </label>
                                <div className="filterSpacer" id="mainCategoryFilter">
                                    {Object.keys(categoryLabelObj).sort().map(category => {
                                        return <div key={"categoryFilter-" + category}><input type="checkbox" id={"categoryFilter-" + category} value={category} name="category" onChange={this.handleFilterCheckbox} checked={categoryLabelObj[category]} />
                                            <label htmlFor={"categoryFilter-" + category}>{category}</label></div>
                                    })}
                                </div>
                            </div>

                            <div className="filterCollection">
                                <label className="courierText filterName">Charity: </label>
                                <div>
                                    <input type="checkbox" id="charityFilter-yes" name="charity" onChange={this.handleFilterCheckbox} checked={charityOnly} />
                                    <label htmlFor="charityFilter-yes">Yes</label>
                                </div>
                            </div>

                            <div className="filterCollection">
                                <label className="courierText filterName">Country: </label>
                                <div className="filterScroll">
                                    {Object.keys(countryLabelObj).sort().map(country => {
                                        return <div key={"countryFilter-" + country}><input type="checkbox" id={"countryFilter-" + country} value={country} name="country" onChange={this.handleFilterCheckbox} checked={countryLabelObj[country]} />
                                            <label htmlFor={"countryFilter-" + country}>{country}</label></div>
                                    })}
                                </div>
                            </div>

                            <div className="filterCollection">
                                <label className="courierText filterName">Region: </label>
                                <div className="filterScroll">
                                    {Object.keys(regionLabelObj).sort().map(region => {
                                        return <div key={"regionFilter-" + region}><input type="checkbox" id={"regionFilter-" + region} value={region} name="region" onChange={this.handleFilterCheckbox} checked={regionLabelObj[region]} />
                                            <label htmlFor={"regionFilter-" + region}>{region}</label></div>
                                    })}
                                </div>
                            </div>

                            <div className="filterCollection">
                                <label className="courierText filterName">Producer: </label>
                                <div className="filterScroll">
                                    {Object.keys(producerLabelObj).sort().map(producer => {
                                        return <div key={"producerFilter-" + producer}><input type="checkbox" id={"producerFilter-" + producer} value={producer} name="producer" onChange={this.handleFilterCheckbox} checked={producerLabelObj[producer]} />
                                            <label htmlFor={"producerFilter-" + producer}>{producer}</label></div>
                                    })}
                                </div>
                            </div>

                            <div className="filterCollection">
                                <label className="courierText filterName">Type: </label>
                                <div className="filterScroll">
                                    {Object.keys(grapeTypeLabelObj).sort().map(grapeType => {
                                        return <div key={"grapeFilter-" + grapeType}><input type="checkbox" id={"grapeFilter-" + grapeType} value={grapeType} name="grapeType" onChange={this.handleFilterCheckbox} checked={grapeTypeLabelObj[grapeType]} />
                                            <label htmlFor={"grapeFilter-" + grapeType}>{grapeType}</label></div>
                                    })}
                                </div>
                            </div>

                            <div className="filterCollection">
                                <label className="courierText filterName">Vintage: </label>
                                <div className="filterScroll">
                                    {Object.keys(vintageLabelObj).sort().map(vintage => {
                                        return <div key={"vintageFilter-" + vintage}><input type="checkbox" id={"vintageFilter-" + vintage} value={vintage} name="vintage" onChange={this.handleFilterCheckbox} checked={vintageLabelObj[vintage]} />
                                            <label htmlFor={"vintageFilter-" + vintage}>{vintage}</label></div>
                                    })}
                                </div>
                            </div>  

                            <div className="filterCollection">
                                <label className="courierText filterName">Specifics: </label>
                                <div className="">
                                    {Object.keys(specificsLabelObj).sort().map(otherAttribute => {
                                        return <div key={"specificsFilter-" + otherAttribute}><input type="checkbox" id={"specificsFilter-" + otherAttribute} value={otherAttribute} name="specifics" onChange={this.handleFilterCheckbox} checked={specificsLabelObj[otherAttribute]} />
                                            <label htmlFor={"specificsFilter-" + otherAttribute}>{otherAttribute}</label></div>
                                    })}
                                </div>
                            </div>

                            <br />
                            
                            <p onClick={this.resetFilters} id="resetCBButton">Reset Filters</p>

                        </form>
                    </div>
                    <div id="shopProductsContainer" className="shopContainer showFilters" onMouseEnter={this.hideCarts} onMouseLeave={this.hideCarts}>
                        {tilesToShow}
                    </div>
                </div>
                <div className="shopPageContainer">
                    Page: {productPages}
                </div>
            </div>
        )
    }
}

export default Products;