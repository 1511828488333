export function getFromSessionStorage(key) {
    try {
        const valueStr = sessionStorage.getItem(key);
        if (valueStr) {
            return JSON.parse(valueStr);
        }
        return null;
    } catch (error) {
        return null;
    }
}

export function setInSessionStorage(key, obj) {
    if (!obj) {
        console.error('Error: Object is missing');
        return false;
    }
    try {
        sessionStorage.setItem(key, JSON.stringify(obj));
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}

export function removeFromSessionStorage(key) {
    try {
        sessionStorage.removeItem(key);
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
}