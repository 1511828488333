import React from 'react';
import { Link } from 'react-router-dom';
import { updateNav } from '../../../utils/updateActivePage';
import BasketRow from '../../../components/checkout/basket/basketRow';
import $ from 'jquery';
import axios from 'axios';
import './basket.css';
import Tile from '../tile/tile';
import { isArray } from 'lodash';
import { toTop } from '../../../utils/updateActivePage';

import Wrap01 from '../../../materials/images/gifts/wrap-01.png';
import Wrap02 from '../../../materials/images/gifts/wrap-02.png';
import Wrap03 from '../../../materials/images/gifts/wrap-03.png';

import GiftboxSingle from '../../../materials/images/gifts/giftbox-single.png';
import GiftboxDouble from '../../../materials/images/gifts/giftbox-double.png';

let imageI = 0

let lastUnixUpdate = Math.floor(Date.now() / 1000);

let charityWineName = '';
let charityWineUrl = '';

let initialHideGifts = true;

class BasketPage extends React.Component {

    // Send token to API and timestamp to get recent changes
    fetchStripePublicKeyFromApi = async () => {
        try {
            const url = `/api/shop/stripe/public-key`;
            const response = await axios({
                url: url,
                method: 'GET'
            });
            const { data } = response;
            const { success, publicKey } = data;

            if (success) {
                localStorage.setItem('stripePublicKey', publicKey);
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    FormatPrice(price) {
        return '£' + (price / 100).toFixed(2);
    }

    handleAddDiscountCodes = async (evt) => {
        evt.preventDefault();
        //console.log('prevnt default');
        this.disableButtons();
        //console.log('disablebutton');
        const value = $('#discountCodeInput').val();
        if (!value) {
            //console.log('no val');
            this.enableButtons();
            return;
        }
        //console.log('has val');
        const discountCodes = [value];
        const $extraInfo = $('#extraInfo');
        const extraInfoVal = $extraInfo.val();
        if (extraInfoVal) discountCodes.push(extraInfoVal);
        const { addDiscountCodes } = this.props;
        //console.log('to app js');
        const placeholder = await addDiscountCodes(discountCodes);
        //console.log('returned');
        this.enableButtons();
        if (placeholder) {
            if (placeholder === 'clear-all') {
                this.hideDiscountExtraInfo();
                $('#discountCodeInput').val('');
            } else {
                $extraInfo.val('');
                $extraInfo.attr("placeholder", placeholder);
                $('#extraInfoContainer').slideDown();
                //this.disableDiscountMain();
            }
        } else {
            $('#discountCodeInput').val('');
        }
    }

    handleRemoveProductCode = async () => {
        const { removeDiscountCode } = this.props;
        if (removeDiscountCode) {
            removeDiscountCode('product');
        }
    }

    handleRemoveDeliveryCode = async () => {
        const { removeDiscountCode } = this.props;
        if (removeDiscountCode) {
            removeDiscountCode('delivery');
        }
    }

    generateBasketRows = (itemsIn) => {
        //console.log('itemsIn', itemsIn);
        const productLines = itemsIn || [{}];
        const { addRemoveToBasket } = this.props.shop;
        let basketRows = [];
        let rowKey = 0;
        productLines.forEach(productLine => {
            if (!productLine) {
                return;
            }
            const { quantity } = productLine;
            if (quantity === undefined) {
                return;
            }
            const { nameNoWhSpc, price } = productLine;
            const url = `/shop/product/${nameNoWhSpc}`;
            const sum = price * quantity;
            const sumFormatted = this.FormatPrice(sum);
            basketRows.push(<BasketRow
                key={'basketRow' + rowKey}
                product={productLine}
                url={url}
                quantity={quantity}
                sum={sumFormatted}
                addRemoveToBasket={addRemoveToBasket}
            />);
            rowKey++
        });

        return basketRows;
    }

    notInBasket = (charityProductId) => {
        const { basket } = this.props.shop;
        const { productLines } = basket;
        if (productLines.length === 0) {
            return false;
        }
        const productIDs = productLines.map(prodLine => { return prodLine.id });
        // search basket for that product
        return !productIDs.includes(charityProductId);
    }

    nameRandomChairtyWine = (products) => {
        const len = products.length;
        if (len < 1) {
            return;
        }
        const i = Math.floor(Math.random() * len);
        const product = products[i];
        charityWineName = product.name;
        charityWineUrl = `/shop/product/${product.nameNoWhSpc}`;
        return charityWineUrl;
    }

    randomCharityWineTiles = () => {
        // Get all charity wines
        const { products } = this.props.shop;
        const notInBasket = this.notInBasket;
        const charityWines = products.filter(product => product.charity === true);
        // Get categories
        let charityCategories = {};
        charityWines.forEach(product => {
            if (product['category']) {
                const catName = product['category'];
                if (!isArray(charityCategories[catName])) {
                    // Instantiate array
                    charityCategories[catName] = [];
                }
                // Push to array
                charityCategories[catName].push(product);
            }
        });

        let tileProducts = [];
        // Get a charity wine from each category
        for (const value of Object.values(charityCategories)) {
            // key = "red"
            // value = [product]
            const products = value;
            // Gen list of charity wines not yet in basket
            const notInBaskets = products.filter(product => notInBasket(product.id));
            const hasCharityWinesInCategory = notInBaskets.length > 0;
            if (hasCharityWinesInCategory) {
                // List isn't empty, pick a random wine
                const notInBasketsLength = notInBaskets.length;
                // Pick index
                const randIndex = Math.floor(Math.random() * notInBasketsLength);
                // Push random wine
                tileProducts.push(notInBaskets[randIndex]);
            }
        }

        this.nameRandomChairtyWine(tileProducts);

        //const { addRemoveToBasket } = this.props.shop;
        const { addToCart } = this;

        // Generate <Tile>s
        let tileElements = [];
        let charityIndex = -1;
        tileProducts.forEach(product => {
            charityIndex++;
            const chIndex = parseInt(charityIndex);
            const element = <div key={"charity-" + chIndex} className="basketTile" onMouseEnter={this.hideCarts} onMouseLeave={this.hideCarts}>
                <Tile
                    key={'tile' + product.id}
                    product={product}
                    addRemoveToBasket={addToCart}
                />
            </div>
            tileElements.push(element);
        });

        while (tileElements.length > 3) {
            const tileElsLen = tileElements.length;
            const ranIndex = Math.floor(Math.random() * tileElsLen);
            tileElements.splice(ranIndex, 1);
        }

        return tileElements;
    }

    addToCart = async (product) => {
        this.props.shop.addRemoveToBasket(product, 1, false);
    }

    slideGifts = () => {
        $('.giftOptions').slideToggle();
    }

    // componentDidMount() {
    //     console.log('Mount 1');
    //     this.fetchStripePublicKeyFromApi();
    //     toTop();
    //     initialHideGifts = true;
    // }

    getShippingPriceText = () => {
        const price = this.getShippingPrice();
        if (price > 0) {
            return this.FormatPrice(price).toString();
        } else {
            return "Free";
        }
    }

    getShippingPrice = () => {
        const englandPrice = 599;
        const { basket } = this.props.shop;
        const basketHasShippingCost = basket['deliveryCost'] !== undefined && basket['deliveryCost'] !== null;
        const price = basketHasShippingCost ? basket['deliveryCost'] : englandPrice;
        return price;
    }

    hideCarts = (evt) => {
        $('div.tileOverlay').slideUp();
    }

    hideHoverText = () => {
        $('.hoverText').slideUp();
    }

    showMembershipForm = () => {
        $('#discountAddButton').on('click', () => {
            if ($('#discountCodeInput').val() === "TNCarafe") {
                $('#extraDiscountInfoForm').slideToggle();
            };
        });
    }

    enableButtons = () => {
        $('button').attr('disabled', false);
        $('input').attr('disabled', false);
    }
    disableButtons = () => {
        $('button').prop('disabled', true);
        //$('input').prop('disabled', true);
    }
    disableDiscountMain = () => {
        $('#discountCodeInput').prop('disabled', true);
    }

    showDiscountExtraInfo = () => {
        $('#extraInfo').slideDown();
        setTimeout(() => {
            $('#extraInfo').show();
        }, 1000)
    }
    hideDiscountExtraInfo = () => {
        $('#extraInfo').val('');
        $('#extraInfo').slideUp();
    }

    // componentDidMount() {
    //     this.fetchStripePublicKeyFromApi();
    //     console.log('Mount 2');
    //     $('#extraInfoContainer').hide();
    // }

    cycleGiftImg = () => {
        const unixNow = Math.floor(Date.now() / 1000);
        if (unixNow < lastUnixUpdate + 6) {
            this.initCycleGitImg();
            return;
        }
        const $image = $('#gift-knot-imgs');
        let images = [Wrap01, Wrap02, Wrap03];

        imageI < images.length - 1 ?
            imageI += 1
            : imageI = 0;
        $image.attr("src", images[imageI]);
        lastUnixUpdate = Math.floor(Date.now() / 1000);
        this.initCycleGitImg();
    }

    initCycleGitImg = () => {
        setTimeout(this.cycleGiftImg, 2000);
    }

    handleOnChangeGiftNote = (evt) => {
        const { updateBasketNote } = this.props;
        if (updateBasketNote) {
            let value = evt.target.value || '';
            if (value.length > 1000) {
                value = value.substring(0, 1000);
            }
            updateBasketNote('gift', value);
        }
    }

    componentDidMount() {
        //console.log('Mount 3');
        this.fetchStripePublicKeyFromApi();
        this.initCycleGitImg();
        toTop();
    }

    tickGiftShow = () => {
        $('#gift-checkbox').prop("checked", true);
    }

    // Knot wraps
    knotDec = (evt) => {
        evt.preventDefault();
        this.tickGiftShow();
        const { updateBasketKnotWraps } = this.props;
        if (updateBasketKnotWraps) {
            const giftWrapId = 1;
            const set = false;
            updateBasketKnotWraps(giftWrapId, -1, set);
        }
    }
    knotSet = (evt) => {
        this.tickGiftShow();
        const { value } = evt.target;
        const { updateBasketKnotWraps } = this.props;
        if (updateBasketKnotWraps) {
            const giftWrapId = 1;
            const set = true;
            updateBasketKnotWraps(giftWrapId, value, set);
        }
    }
    knotInc = (evt) => {
        evt.preventDefault();
        this.tickGiftShow();
        const { updateBasketKnotWraps } = this.props;
        if (updateBasketKnotWraps) {
            const giftWrapId = 1;
            const set = false;
            updateBasketKnotWraps(giftWrapId, 1, set);
        }
    }

    // Gift single
    giftSingleDec = (evt) => {
        evt.preventDefault();
        this.tickGiftShow();
        const { updateBasketGiftBoxes } = this.props;
        if (updateBasketGiftBoxes) {
            const giftBoxId = 1;
            const set = false;
            updateBasketGiftBoxes(giftBoxId, -1, set);
        }
    }
    giftSingleSet = (evt) => {
        this.tickGiftShow();
        const { value } = evt.target;
        const { updateBasketGiftBoxes } = this.props;
        if (updateBasketGiftBoxes) {
            const giftBoxId = 1;
            const set = true;
            updateBasketGiftBoxes(giftBoxId, value, set);
        }
    }
    giftSingleInc = (evt) => {
        evt.preventDefault();
        this.tickGiftShow();
        const { updateBasketGiftBoxes } = this.props;
        if (updateBasketGiftBoxes) {
            const giftBoxId = 1;
            const set = false;
            updateBasketGiftBoxes(giftBoxId, 1, set);
        }
    }

    // Gift double
    giftDoubleDec = (evt) => {
        evt.preventDefault();
        this.tickGiftShow();
        const { updateBasketGiftBoxes } = this.props;
        if (updateBasketGiftBoxes) {
            const giftBoxId = 2;
            const set = false;
            updateBasketGiftBoxes(giftBoxId, -1, set);
        }
    }
    giftDoubleSet = (evt) => {
        const { value } = evt.target;
        this.tickGiftShow();
        const { updateBasketGiftBoxes } = this.props;
        if (updateBasketGiftBoxes) {
            const giftBoxId = 2;
            const set = true;
            updateBasketGiftBoxes(giftBoxId, value, set);
        }
    }
    giftDoubleInc = (evt) => {
        evt.preventDefault();
        this.tickGiftShow();
        const { updateBasketGiftBoxes } = this.props;
        if (updateBasketGiftBoxes) {
            const giftBoxId = 2;
            const set = false;
            updateBasketGiftBoxes(giftBoxId, 1, set);
        }
    }

    handleShowHideGifts = (evt) => {
        //console.log('hello');
        const checkboxVal = evt.target.checked;
        //console.log(checkboxVal);
        if (checkboxVal) {
            initialHideGifts = false;
            // Show
            $('#gift-options-container').removeClass('gift-hide');
            $('#gift-options-container').addClass('gift-show');
        } else {
            // Hide
            initialHideGifts = true;
            $('#gift-options-container').removeClass('gift-show');
            $('#gift-options-container').addClass('gift-hide');
        }
    }

    showHideGifts = () => {
        if (initialHideGifts) {
            // Show
            initialHideGifts = false;
            $('#gift-options-container').removeClass('gift-hide');
            $('#gift-options-container').addClass('gift-show');
        } else {
            // Hide
            initialHideGifts = true;
            $('#gift-options-container').removeClass('gift-show');
            $('#gift-options-container').addClass('gift-hide');
        }
    }

    render() {
        this.enableButtons();
        updateNav();
        // fetch basket contents from localStorage or state/props
        const { basket } = this.props.shop;
        const { productLines, productSubtotal,
            subtotalWithDiscount, savingWithDiscount,
            subtotalWithProductCode, savingWithProductCode,
            subtotalWithGiftWrap, giftWrapCost,
            subtotalWithGiftBoxes, giftBoxCost,
            deliveryCost, totalWithDelivery,
            giftWrapIds, giftBoxes, giftNote } = basket;
        //const savingFromMultiBuy = productSubtotal - subtotalWithDiscount;
        //const savingFromProductDiscount = subtotalWithDiscount - subtotalWithProductCode;
        const productDiscountApplied = basket['productDiscountApplied'] || {};
        const deliveryDiscountApplied = basket['deliveryDiscountApplied'] || {};
        let appliedDiscount = false;
        if (productDiscountApplied) {
            appliedDiscount = true;
        } else if (deliveryDiscountApplied) {
            appliedDiscount = true;
        }

        const hasProductDiscount = productDiscountApplied['code'] ? true : false;
        const hasDeliveryDiscount = deliveryDiscountApplied['code'] ? true : false;

        const shippingPriceText = this.getShippingPriceText();

        const hasProductsInBasket = productLines.length > 0;



        const reducer = (accumulator, productLine) => accumulator + productLine.quantity;
        const bottleQuantity = productLines.reduce(reducer, 0);
        //const bottleQuantity = 5;

        // const giftWrapSinglePrice = 250;
        // const giftWrapReducer = (accumulator, productLine) => accumulator + (productLine.giftQuantity * giftWrapSinglePrice);
        // const giftWrapCost = productLines.reduce(giftWrapReducer, 0) || 0;
        // const giftWrapPluralS = giftWrapCost > giftWrapSinglePrice ? 's' : '';

        //const giftWrapPluralS = 's';
        //const giftWrapCost = 250;

        const checkoutLink = this.props.token ? '/shop/checkout/delivery' : '/shop/checkout/guest';


        //const shippingText = deliveryCost ? "Shipping to Mainland England on first order" : "Standard shipping to Mainland England";
        let shippingText = "Standard shipping to Mainland England";
        if (deliveryCost) {
            // Standard message
            if (deliveryCost === 599) {
                shippingText = "Standard shipping to Mainland England";
            } else {
                shippingText = "Standard shipping outside of Mainland England";
            }
        } else {
            // Delivery discount of some kind
            shippingText = "Shipping";
            if (deliveryDiscountApplied) {
                if (deliveryDiscountApplied['message']) {
                    shippingText = "Delivery discount code, " + deliveryDiscountApplied['code'].toString();
                }
            } else {
                shippingText = "Shipping to Mainland England on first order";
            }
        }

        const hasGifts = giftWrapIds.length + giftBoxes.length > 0;

        let singleBoxes = 0;
        let doubleBoxes = 0;
        for (let i = 0; i < giftBoxes.length; i++) {
            const giftBox = giftBoxes[i];
            if (giftBox.id === 1) {
                singleBoxes++;
            } else if (giftBox.id === 2) {
                doubleBoxes++;
            }
        }

        const giftWrapAndBoxesCombinedCost = giftWrapCost + giftBoxCost;

        let psstText = "another item";
        const extraNeeded = 6 - bottleQuantity;
        if (extraNeeded > 1) {
            psstText = extraNeeded + " more items";
        }

        return (
            <div className="basketPage containerFluidMedium">
                <div>
                    <h4>Basket</h4>

                    <div className="basketTopRow" onMouseEnter={this.hideHoverText} onMouseLeave={this.hideHoverText}>
                        {productLines.length > 0 ? <div className="basketItemsCol col-8"><table>
                            <thead>
                                <tr>
                                    <td className="tbl-item">Item</td>
                                    <td className="tbl-quantity">Quantity</td>
                                    <td className="tbl-price">Price</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.generateBasketRows(productLines)}
                                <tr><td></td></tr>

                                <tr className="multiDiscountRow">
                                    <td className="discountText" colSpan="2">
                                        {savingWithDiscount ? `Saving for x${bottleQuantity} bottles:` :
                                            bottleQuantity > 2 ? <em>Psst, if you add {psstText}, we'll give you a discount!</em> : null}
                                    </td>
                                    <td className="tbl-price greenText">
                                        {savingWithDiscount ? "-" + this.FormatPrice(savingWithDiscount) : null}
                                    </td>
                                </tr>


                                {savingWithProductCode ? <tr className="multiDiscountRow">
                                    <td className="discountText" colSpan="2">Discount code saving:</td>
                                    <td className="tbl-price greenText">-{this.FormatPrice(savingWithProductCode)}</td>
                                </tr>
                                    : null}

                                {giftWrapAndBoxesCombinedCost ? <tr className="giftWrapTotalRow">
                                    <td className="giftWrapRowText" colSpan="2">Gift wrap and boxes:</td>
                                    <td className="tbl-price">{this.FormatPrice(giftWrapAndBoxesCombinedCost)}</td>
                                </tr>
                                    : null}

                                <tr className="shippingRow">
                                    <td id="shippingText" colSpan="2">{shippingText}:</td>
                                    <td id="shippingRate" className="tbl-price">{shippingPriceText}</td>
                                </tr>
                                <tr><td></td></tr>
                                <tr className="subtotal">
                                    <td></td>
                                    <td>Total:</td>
                                    <td className="tbl-price"><span className="total">{this.FormatPrice(totalWithDelivery)}</span></td>
                                </tr>
                            </tbody>
                        </table></div> : <div className="col-8"><p>There are no items in your cart yet.</p></div>}

                        <div className="discountsCol col-4">
                            {appliedDiscount ? <div>
                                <p>Discounts applied:</p>
                                <ul>
                                    {hasProductDiscount ?
                                        <li className="disountCodeLi" onClick={this.handleRemoveProductCode}>
                                            <span className="disountCodeSpan" id="prodDiscount" >{productDiscountApplied.code.split('~')[0]}: {productDiscountApplied.message || ''}</span>
                                            <span className="cancel-discount">(remove)</span>
                                        </li>
                                        : null}
                                    {hasDeliveryDiscount ?
                                        <li className="disountCodeLi" onClick={this.handleRemoveDeliveryCode}>
                                            <span className="disountCodeSpan" id="deliveryDiscount">{deliveryDiscountApplied.code.split('~')[0]}: {deliveryDiscountApplied.message}</span>
                                            <span className="cancel-discount">(remove)</span>
                                        </li>
                                        : null}
                                </ul>
                            </div> : <p>No discounts applied.</p>}

                            <form id="discountForm" onSubmit={this.handleAddDiscountCodes}>
                                <input id="discountCodeInput" type="text" placeholder="Discount code"></input>
                                <div id="extraInfoContainer">
                                    <input id="extraInfo" type="text" placeholder="Extra info needed"></input>
                                </div>
                                <button type="submit" id="discountAddButton">Add</button>
                            </form>
                        </div>
                    </div>

                    <div className="smallPrint">
                        <p>Tax and packaging costs included, delivery calculated at checkout.</p>
                        {this.props.token ?
                            <p>We offer 1-3 working day delivery to England for £5.99, <b>free</b> on first order.</p>
                            : <p>We offer 1-3 working day delivery to England for £5.99, <b>free</b> on first order with <Link to="/account/signup">account creation</Link>.</p>}
                        <p>International deliveries coming soon.</p>
                    </div>
                </div>

                { hasProductsInBasket ? <hr /> : null}

                <br />

                { hasProductsInBasket ?
                    <div id="gift-component">

                        <h4>Gifts</h4>

                        <div id="gift-container">
                            <br />
                            <p id="gift-checkbox-container" className={hasGifts ? "gift-question-hide" : "gift-question-show"}>
                                <input id="gift-checkbox" type="checkbox" onChange={this.handleShowHideGifts} ></input>
                                <label htmlFor="gift-checkbox">&nbsp;Would you like to make any of your purchases a gift?</label>
                            </p>

                            {hasGifts ?
                                <p id="gift-show-hide-btn" onClick={this.showHideGifts}>Show/hide gifts</p>
                                : null}

                            <div id="gift-options-container" className={initialHideGifts ? "gift-hide" : "gift-show"}>

                                <div id="gift-tile-container">
                                    <div className="gift-tile">
                                        <div className="gift-tile-img-container">
                                            <img id="gift-knot-imgs" src={Wrap01} />
                                        </div>
                                        <div className="gift-description">
                                            <div>
                                                <h6>Vintage wine knot wrap: £2.50</h6>
                                                <div className="gift-tile-quantity">
                                                    <button onClick={this.knotDec}>-</button>
                                                    <input type="number" value={giftWrapIds.length} onChange={this.knotSet} />
                                                    <button onClick={this.knotInc}>+</button>
                                                </div>
                                            </div>
                                            <p>A stylish, sustainable and unique way to gift your wine to your loved one! Plus the bonus of a fashionable new accessory you could use as a hair piece, scarf, send on the love and wrap up another gift with it. We’re all eco-friendly here at Carafe, let’s look after the earth and re-use. We source vintage scarves to dress around the wine bottles, every one you receive will be one of a kind, making the gift even more personal.</p>

                                        </div>

                                    </div>

                                    <div className="gift-tile">
                                        <div className="gift-tile-img-container">
                                            <img id="gift-box-single" src={GiftboxSingle} />
                                        </div>
                                        <div className="gift-description">
                                            <div>
                                                <h6>Carafe bow gift box (single bottle): £4.00</h6>
                                                <div className="gift-tile-quantity">
                                                    <button onClick={this.giftSingleDec}>-</button>
                                                    <input type="number" value={singleBoxes} onChange={this.giftSingleSet} />
                                                    <button onClick={this.giftSingleInc}>+</button>
                                                </div>
                                            </div>
                                            <p>A minimalistic arty gift box with a painted bow suitable for a single bottle. Inside your chosen wine will be wrapped up snug-as-a-bug-in-a-rug with a one-of-a-kind knot wrap we have sourced, repurposing vintage scarves. A bonus as after the lucky receiver can reuse this at their leisure whether for gifting or as a fashion accessory. A sustainable and stylish twist to their gift.</p>
                                        </div>

                                    </div>

                                    <div className="gift-tile">
                                        <div className="gift-tile-img-container">
                                            <img id="gift-knot-imgs" src={GiftboxDouble} />
                                        </div>
                                        <div className="gift-description">
                                            <div>
                                                <h6>Carafe bow gift box (double bottle): £6.50</h6>
                                                <div className="gift-tile-quantity">
                                                    <button onClick={this.giftDoubleDec}>-</button>
                                                    <input type="number" value={doubleBoxes} onChange={this.giftDoubleSet} />
                                                    <button onClick={this.giftDoubleInc}>+</button>
                                                </div>
                                            </div>
                                            <p>A minimalistic arty gift box with a painted bow suitable for two bottles. Inside your chosen duo will be wrapped up snug-as-a-bug-in-a-rug with one-of-a-kind knot wrap we have sourced, repurposing vintage scarves. A bonus as after the lucky receiver can reuse this at their leisure whether for gifting or as a fashion accessory. A sustainable and stylish twist to their gift.</p>
                                        </div>

                                    </div>
                                </div>

                                <br />

                                <h6 className="heading-center">Gift instructions:</h6>
                                <textarea id="gift-notes" rows={14} cols={60} value={giftNote} onChange={this.handleOnChangeGiftNote} placeholder="Please state any requirements you have for your gift items e.g. which items (if any) you would like packaging in gift boxes and/or knot wrap, as well as any notes you wish to be included." maxLength="1000"></textarea>

                            </div>

                        </div>


                        <hr />

                    </div>
                    : null}





                { hasProductsInBasket ? <div className="containerFluidMedium charityWineSuggestions linkHighlight">
                    <div className="charitySuggestionTitle">
                        {charityWineName && charityWineUrl ?
                            <p>Can we tempt you with a charity wine? Give back with every sip you take to <Link to={charityWineUrl}>{charityWineName}</Link>.</p>
                            : null}
                        <p>25% of the profits from every purchase of these babies will go directly to the nominated charity - give back with your booze!</p>
                    </div>
                    <div className="charityWineTiles" onMouseEnter={this.hideCarts} onMouseLeave={this.hideCarts}>
                        {this.randomCharityWineTiles()}
                    </div>
                </div>
                    : null}

                { hasProductsInBasket ? <div className="containerFluidMedium">
                    <p className="checkoutLink flRight">Proceed to&nbsp;<Link to={checkoutLink}>Checkout -{'>'}</Link></p>
                    <br />
                </div>
                    : null}
            </div>
        )
    }
}

export default BasketPage;

/*




<div id="gift-options-container">

                        <div id="gift-tile-container">
                            <div className="gift-tile">
                                <img id="gift-knot-imgs" src={Wrap01} />
                                <p>Knot wrap £2.50</p>
                                <div className="gift-tile-quantity">
                                    <button onClick={this.knotDec}>-</button>
                                    <input type="number" value={giftWrapIds.length} onChange={this.knotSet} />
                                    <button onClick={this.knotInc}>+</button>
                                </div>
                            </div>

                            <div className="gift-tile">
                                <img id="gift-box-single" src={GiftboxSingle} />
                                <p>Gift box (single) £5.00</p>
                                <div className="gift-tile-quantity">
                                    <button onClick={this.knotDec}>-</button>
                                    <input type="number" value={giftWrapIds.length} onChange={this.knotSet} />
                                    <button onClick={this.knotInc}>+</button>
                                </div>
                            </div>

                            <div className="gift-tile">
                                <img id="gift-knot-imgs" src={GiftboxDouble} />
                                <p>Gift box (double) £8.00</p>
                                <div className="gift-tile-quantity">
                                    <button onClick={this.knotDec}>-</button>
                                    <input type="number" value={giftWrapIds.length} onChange={this.knotSet} />
                                    <button onClick={this.knotInc}>+</button>
                                </div>
                            </div>
                        </div>






*/