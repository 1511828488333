import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery'
import './login.css';

class LoginPage extends Component {

    login = async() => {
        const email = $('#email-input').val();
        const password = $('#password-input').val();

        const url = '/api/admin/login';
        const response = await axios({
            url,
            method: 'POST',
            data: { email, password }
        });
        if (response) {
            const { data } = response;
            if (data) {
                const { adminToken, navExtraButtonText, navExtraButtonLink } = data;
                localStorage.setItem('adminToken', adminToken);
                localStorage.setItem('navExtraButtonText', navExtraButtonText);
                localStorage.setItem('navExtraButtonLink', navExtraButtonLink);
                window.location.href = navExtraButtonLink;
            }
            console.log('Data error');
        } else {
            console.log('API response error');
        }
        console.log('Fetch error');
        return null;
    }
    
    render(){
        return (
            <div className="admin-page containerFluidMedium">
                <h1>Admin login:</h1>
                <p>Please login to see admin dashboard.</p>
                <br />
                <label htmlFor="email-input">Email</label><br />
                <input id="email-input" type="text" /><br />
                <br />
                <label htmlFor="password-input">Password</label><br />
                <input id="password-input" type="password" /><br />

                <button type="text" onClick={this.login}>Login</button>
            </div>
        )
    }
}

export default LoginPage;