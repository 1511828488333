import React, { Component } from 'react';
import CountryInput from '../../generic/formInput/countryInput/countryInput';
import './addressInput4.css';

export default class AddressInputBilling extends Component {

    onChangeHandler = (evt) => {
        const { updateCheckoutAddress } = this.props;
        if (updateCheckoutAddress) {
            const addressType = 'billing';
            const { target } = evt;
            const { id, value } = target;
            updateCheckoutAddress(id, value);
        }
        return;
    }

    // <input id={"email"} type="email" name="email" placeholder={'Email*'} value={email} onChange={this.onChangeHandler} required />

    render() {
        const { billingAddress, token, disabled } = this.props;
        const { address1, address2, city, county, country, postcode, email } = billingAddress;

        return (
            <fieldset className="addressForm">
                <legend>Billing</legend>
                <input id={"address1"} type="text" name="address-line1" placeholder={'Address Line 1*'} value={address1} onChange={this.onChangeHandler} required disabled={disabled} />
                <input id={"address2"} type="text" name="address-line2" placeholder={'Address Line 2'} value={address2} onChange={this.onChangeHandler} disabled={disabled} />
                <input id={"city"} type="text" name="address-locality" placeholder={'Town/City*'} value={city} onChange={this.onChangeHandler} required disabled={disabled} />
                <input id={"county"} type="text" name="address-county" placeholder={'Region/County*'} value={county} onChange={this.onChangeHandler} required disabled={disabled} />
                <CountryInput id="country" country={country} onChange={this.onChangeHandler} disabled={disabled} />
                <p id="mainlandOnly" className="errorPara">We are only processing orders for Mainland UK and N. Ireland at the moment, please check back soon!</p>
                <input id={"postcode"} type="text" name="address-postcode" placeholder={'Postcode*'} value={postcode} onChange={this.onChangeHandler} required disabled={disabled} />
                { token ? 
                null 
                : <input id={"email"} type="email" name="email" placeholder={'Email*'} value={email} onChange={this.onChangeHandler} required disabled={disabled} /> }
            </fieldset>
        );
    }
}