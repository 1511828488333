import React, { Component } from 'react';
import { csvToJson2 } from '../../../../../utils/csvUtils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

class PitPProductsAddPage extends Component {

    returnJsonToScreenMulti2 = () => {
        const response = csvToJson2($('#csv').val().toString().trim());
        if (response) {
            console.log(response);
            $('#json').text(JSON.stringify(response));
        }
    }

    sendToApiMulti = async() => {
        const $responseContainer = $('#responseContainer');
        $responseContainer.text('Sending...');
        try {
            const url = '/api/admin/pitp/add-products-csv';
            const csvString = $('#csv').val().toString().trim();
            const adminToken = localStorage.getItem('adminToken');
            const response = await axios({
                url,
                method: 'POST',
                data: { csvString, adminToken }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    console.log(data);
                    const { success } = data;
                    if (success) {
                        const { responseObject } = data;
                        if (responseObject) {
                            console.log(responseObject);
                            $responseContainer.text('Success');
                            return;
                        }
                    }
                }
                console.log('Error with response');
                $responseContainer.text('Error');
            } else {
                console.log('No response')
                $responseContainer.text('Error');
            }
        } catch (error) {
            console.log(error);
            $responseContainer.text('Error');
        }
    }

    render() {
        const sampleCsv = 
`id,     stock,  name
number, number, string
1,      9,      WineA
2,      6,      WineB
101,    0,      WineZ`;


        return (
            <div className="testPage containerFluidMedium">

                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>

                <div>
                    <h6>CSV Source:</h6>
                    <textarea id="csv" rows="10" cols="50" defaultValue={sampleCsv}>
                    </textarea>
                </div>

                <br />

                <button onClick={this.returnJsonToScreenMulti2}>CONVERT Multiple fields</button><br />

                <div>
                    <h6>JSON output:</h6>
                    <div id="json" style={{ "minHeight": "300px", "backgroundColor": "#eee" }}>

                    </div>
                </div>

                <br />

                <p id="responseContainer"></p>
                <button onClick={this.sendToApiMulti}>SEND TO API Multiple fields</button>

            </div>
        )
    }

}

export default PitPProductsAddPage;