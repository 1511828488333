import { Link } from 'react-router-dom';
import $ from 'jquery';
import './orderSummary.css';

const OrderSummary = ({ basket }) => {

    const genBasketHeadings = () => {
        return (
            <tr>
                <td className="tbl-centre">Quantity</td>
                <td>Product</td>
                <td className="tbl-centre">Price</td>
            </tr>
        );
    }

    const genBasketRows = () => {
        if (!basket || !basket.productLines || basket.productLines.length === 0) {
            return null;
        }

        const trs = basket.productLines.map(productLine => {
            const linePrice = (productLine.quantity * productLine.price);
            return (
                <tr>
                    <td className="tbl-centre">
                        {productLine.quantity}x
                    </td>
                    <td>
                        {productLine.name}
                    </td>
                    <td className="tbl-right">
                        £{ (linePrice/100).toFixed(2) }
                    </td>
                </tr>
            )
        });
        return trs;
    }

    const genTotal1 = () => {
        if (!basket || !basket.productLines || basket.productLines.length === 0) {
            return null;
        }

        const { productSubtotal, subtotalWithDiscount, subtotalWithProductCode, deliveryCost, totalWithDelivery } = basket;

        //const giftPrice = totalWithDelivery - deliveryCost - subtotalWithProductCode;

        //const linePrice = productSubtotal + giftPrice;

        return productSubtotal ? (
            <tr>
                <td>
                </td>

                <td className="tbl-right bold">
                    Subtotal:
                </td>

                <td className="tbl-right bold">
                    £{ (productSubtotal/100).toFixed(2) }
                </td>
            </tr>
        )
        : null;
    }

    const genTotal2 = () => {
        if (!basket || !basket.productLines || basket.productLines.length === 0) {
            return null;
        }

        const { productSubtotal, subtotalWithDiscount, subtotalWithProductCode, deliveryCost, totalWithDelivery } = basket;

        const saving = productSubtotal-subtotalWithDiscount;

        return saving ? (
            <tr>
                <td>
                </td>

                <td className="tbl-right green-text">
                    Multi-bottle discount:
                </td>

                <td className="tbl-right">
                    £-{ (saving/100).toFixed(2) }
                </td>
            </tr>
        )
        : null;
    }

    const genTotal3 = () => {
        if (!basket || !basket.productLines || basket.productLines.length === 0) {
            return null;
        }

        const { productSubtotal, subtotalWithDiscount, subtotalWithProductCode, deliveryCost, totalWithDelivery } = basket;

        const saving = subtotalWithDiscount-subtotalWithProductCode;

        return saving ? (
            <tr>
                <td>
                </td>

                <td className="tbl-right green-text">
                    Discount code saving:
                </td>

                <td className="tbl-right">
                    £-{ (saving/100).toFixed(2) }
                </td>
            </tr>
        )
        : null;
    }

    const genTotalWrap = () => {
        if (!basket || !basket.productLines || basket.productLines.length === 0) {
            return null;
        }

        const { giftWrapCost } = basket;

        return giftWrapCost ? (
            <tr>
                <td>
                </td>

                <td className="tbl-right">
                    Gift wraps:
                </td>

                <td className="tbl-right">
                    £{ (giftWrapCost/100).toFixed(2) }
                </td>
            </tr>
        )
        : null;
    }

    const genTotalBoxes = () => {
        if (!basket || !basket.productLines || basket.productLines.length === 0) {
            return null;
        }

        const { giftBoxCost } = basket;

        return giftBoxCost ? (
            <tr>
                <td>
                </td>

                <td className="tbl-right">
                    Gift boxes:
                </td>

                <td className="tbl-right">
                    £{ (giftBoxCost/100).toFixed(2) }
                </td>
            </tr>
        )
        : null;
    }

    const genTotal4 = () => {
        if (!basket || !basket.productLines || basket.productLines.length === 0) {
            return null;
        }

        const { productSubtotal, subtotalWithDiscount, subtotalWithProductCode, deliveryCost, totalWithDelivery } = basket;

        return (
            <tr>
                <td>
                </td>

                <td className="tbl-right">
                    Delivery:
                </td>

                <td className="tbl-right">
                    { deliveryCost ? 
                    "£"+ (deliveryCost/100).toFixed(2) 
                    : "Free" }
                </td>
            </tr>
        );
    }

    const genTotal5 = () => {
        if (!basket || !basket.productLines || basket.productLines.length === 0) {
            return null;
        }

        const { productSubtotal, subtotalWithDiscount, subtotalWithProductCode, deliveryCost, totalWithDelivery } = basket;

        return (
            <tr>
                <td>
                </td>

                <td className="tbl-right bold">
                    Total:
                </td>

                <td className="tbl-right bold">
                    £{ (totalWithDelivery/100).toFixed(2) }
                </td>
            </tr>
        );
    }

    return (
        <table className="order-summary-table">
            <thead>
                {genBasketHeadings()}
            </thead>

            <tbody>
                {genBasketRows()}
                {genTotal1()}
                {genTotal2()}
                {genTotal3()}
                {genTotalWrap()}
                {genTotalBoxes()}
                {genTotal4()}
                {genTotal5()}
            </tbody>
        </table>)
}

export default OrderSummary;