import React, { Component } from 'react';
import { updateNav } from '../../utils/updateActivePage';
import './notfound.css'

class NotFoundPage extends Component {
    render(){
        updateNav();
        return (
            <div className="containerFluidMedium">
                <div id="notfound" className="content">
                    <h2>404 Error</h2>
                    <h4>Page not found.</h4>
                </div>
            </div>
        );
    }
};

export default NotFoundPage;