
function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function padOrderNumber(orderNumber) {
    const width = 5;
    const leading = '0';
    return pad(orderNumber, width, leading)
}

const orderUtils = { padOrderNumber };

export default orderUtils;