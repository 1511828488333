import $ from 'jquery';

export function updateNav() {
    $(() =>{
        const { location } = window;
        const { pathname } = location;

        if (pathname === '/') {
            setActives(pathname, true);
        } else {
            setActives(pathname, false);
        }
    });
}

export function toTop() {
    try {
        $("html, body").animate({ scrollTop: $("#topOfBody").offset().top }, "fast");
    } catch (error) {
        //
    }
}

function setActives(pathname, exact) {
    // Clear all active Nav
    const $nav = $('.navBarParent');
    const $navUls = $nav.find('ul');
    const $navUlAs = $navUls.find('a');
    clearAll($navUlAs);
    // Clear all active Footer
    const $footer = $('.footerLinks');
    const $footerUls = $footer.find('ul');
    const $footerUlAs = $footerUls.find('a');
    clearAll($footerUlAs);

    addClasses($navUlAs, pathname, exact);
    if (!exact) {
        addClasses($footerUlAs, pathname, exact);
    }
    if ( pathname.startsWith('/help/') ) {
        toTop();
    }
}

function clearAll($anchors) {
    $anchors.removeClass('active');
}

function addClasses($anchors, pathname, exact) {
    if (exact) {
        // Only for home /
        $anchors.filter(function() {
            const anchorUrl = $(this).attr('href');
            return anchorUrl === pathname;
        }).addClass('active');
    } else {
        // All other URLs
        $anchors.filter(function() {
            const anchorUrl = $(this).attr('href');
            if (anchorUrl.length < 2) {
                return false;
            } else {
                return pathname.startsWith(anchorUrl);
            }
        }).addClass('active');
    }
}