import React, { Component } from 'react';
import $ from 'jquery';
//import SnackBar from '../../../components/generic/snackbar/snackbar';
import axios from 'axios';
import toastError from '../../../materials/icons/toast-error.png';
import toastSuccess from '../../../materials/icons/toast-success.png';
import { isValidEmail } from '../../../utils/emailUtils';
import { updateNav } from '../../../utils/updateActivePage';
import './privacypolicy.css';

const sha256 = require('crypto-js/sha256');

class PrivacyPolicyPage extends Component {

    unsubscribePopUp() {
        //console.log('Working');
        $('.unsubscribe-container').toggleClass('show');
    }

    keyUp = function (e) {
        e.preventDefault();
        if (e.keyCode === 13) {
            document.getElementById("submitButton").click();
        }
    }

    submitForm = async () => {
        const emailInput = $('#userEmail');
        const email = emailInput.val().toLowerCase();
        emailInput.prop('disabled', true);

        const submitBtn = $('#submitButton');
        submitBtn.prop('disabled', true);

        await new Promise(r => setTimeout(r, 100));

        if (!email) {
            this.toast('All inputs are required', "#b33", "eee", toastError);
            emailInput.prop('disabled', false);
            submitBtn.prop('disabled', false);
            return;
        }

        if (!isValidEmail(email)) {
            this.toast('Please enter valid email', "#b33", "eee", toastError);
            emailInput.prop('disabled', false);
            submitBtn.prop('disabled', false);
            return;
        }

        const emailHash = sha256(email).toString();
        // Send hash to API for removal
        try {
            const url = '/api/mailinglist/remove';
            const response = await axios({
                url: url,
                method: 'DELETE',
                data: { emailHash }
            });

            const { data } = response;
            const { success } = data;

            if (success !== true) {
                const errorMessage = data.message || 'Server error';
                this.toast(errorMessage, "#b33", "eee", toastError);
                return;
            } else {
                this.toast(data.message || 'Success', "#5a5", "#fff", toastSuccess);
                emailInput.val('');
            }
        } catch (error) {
            console.log(error);
            this.toast('Server error', "#b33", "eee", toastError);
        }
        emailInput.prop('disabled', false);
        submitBtn.prop('disabled', false);
    };

    // Display toast in top right corner
    toast = (message, bgColor, textColor, img) => {
        const toastDuration = this.toastDurationCalc(message);
        var x = document.getElementById("snackbar");
        var xIcon = document.getElementById('toast-icon');
        var xText = document.getElementById('toast-text');
        xIcon.src = img;
        xText.style.color = textColor;
        xText.innerText = message;
        x.style.backgroundColor = bgColor;
        x.className = "show";
        setTimeout(function () {
            x.className = x.className.replace("show", "");
        }, toastDuration);
    }

    toastDurationCalc(message) {
        const minDuration = 2000;
        const idealMs = message.length * 100;
        const maxDuration = 7000;
        return Math.max(minDuration, Math.min(idealMs, maxDuration));
    }

    render() {
        updateNav();
        const company = "Carafe";

        const mailto = "mailto:info@carafewine.co.uk";
        const address = "info@CarafeWine.co.uk";

        const email = <a href={mailto}>{address}</a>;

        return (
            <div className="underConstructionPage">
                <div className="containerFluid paperBlack paperBlackTighter linkHighlight">
                    <div className="policy">
                        <h1 className="bridgestown">Privacy policy</h1>
                        <p>We are committed to safeguarding your privacy and want to be a brand you can trust.</p>

                        <p>When completing an order from us we will ask you for your;<br />
                    Name, Address, Phone Number, Email Address and Payment information. This information is required for us to facilitate a delivery to you
                    (e.g we can send you tracking updates via email and may need to call if you are not in for delivery). We will not keep this information on
                    record for any longer than required. We do not store your card or payment details.</p>

                        <p>{company} does not store any sensitive information on its servers. Data storage on our payment gateway (Stripe), and the communication
                    between our payment gateway and the worldwide banking networks, is regularly audited by the banking authorities to ensure a secure transaction
                    environment.</p>

                        <p>We may ask your age when visiting {company}’s website, however this is merely a legal precaution so we can ensure we do not sell alcohol
                    to minors - ensuring you are 18 years old or above.</p>

                        <p className="above-unsub">When obtaining your email address for orders there will be an option to whether you consent to adding to our mailing list.
                        Our mailing list is never spam, thoughtfully crafted emails to update you on promotional events and latest grape juice we have in store.
                    You can also subscribe to this mailing list via entering your email on various sections of our website. <br />
                    Of course we never want to give you unwanted emails or pester you - if you are quite frankly wined out for the while that is okay,
                    you are welcome to unsubscribe by clicking <span id="unsubscribe-link" className="linkHighlight" onClick={this.unsubscribePopUp}>here</span> if you wish to stop the
                    emails from us.</p>

                        <p className="unsubscribe-container">
                            <p className="unsubscribe-text">Enter your email address to unsubscribe from our mailing list:</p>
                            <input type="email" max="30" id="userEmail" name="email" placeholder="Enter email here... " onKeyUp={this.keyUp} />
                            <button id="submitButton" onClick={this.submitForm}>Unsubscribe</button>
                        </p>

                        <p className="below-unsub">In terms of any other personal data we receive from guests we will only use this to deliver and
                    improve {company}’s offering - for instance if data shows all guests favourite a certain wine we can ensure to provide more similar products. We will
                    also process and temporarily store IP address information for the purposes of keeping legitimate users safe and to detract
                    nefarious users from abusing our services - such data is automatically deleted regularly once its purpose has been exhausted.</p>

                        <p>We will not sell your data to any other companies. The only instance in which another company may see your data would be via our
                        delivery courier who will need to know your details in order to facilitate the delivery, specialist companies that host our website,
                        fraud prevention companies and when access to data is required by law via request from a law enforcement agency or police.</p>

                        <p>We are not responsible for the practices or content of websites that are operated by third parties that are linked to our site.
                        Such links are for convenience only. If we are linked to third party sites, then it does not constitute sponsorship, endorsement
                        or approval by {company} of the content, policies or practices of those sites. If you leave our site via such a link, you should
                        check the privacy policy of the third party site.</p>

                        <p>Data that a website transfers to an individual's hard drive for record-keeping purposes are called Cookies. {company} uses
                    industry-standard Cookies, which enables us to track usage patterns. We would do that usually to compile data so as to improve the content
                    of our site and to better target any advertising. If you do not want information collected through the use of Cookies, most browsers allow
                    for a simple procedure to deny or accept the Cookie feature. However, Cookies may be necessary to place orders.</p>

                        <p>We may make changes to this Privacy Policy from time to time and any changes will be posted on this page.</p>

                        <p>If you wish to ask us anything regarding our Privacy Policy please do not hesitate to contact us at {email}</p>
                    </div>
                </div>
            </div>
        );
    }
};

export default PrivacyPolicyPage;