import React, { Component } from 'react';
import $ from 'jquery';
import FormInput from '../../../generic/formInput/formInput';
import { pass, strong, veryStrong } from '../../../../utils/passFormat';
import './signupDetails.css';

export default class SignupDetails extends Component {

    onPassChange = (evt) => {
        const { target } = evt;
        const { value } = target;
        const isVeryStrong = veryStrong(value);
        const len1 = evt.target.value.length;
        if (len1<8) {
            $('#password').removeClass(['strong', 'pass']);
            $('#password').addClass('weak');
            $('.passwordMsg').text('Password must at least 8 characters');
            $('.passwordMsg').removeClass('pass');
            $('.passwordMsg').addClass('weak');
            return;
        }
        if (isVeryStrong){
            $('#password').removeClass(['weak', 'pass']);
            $('#password').addClass('strong');
            $('.passwordMsg').text('Very strong');
            $('.passwordMsg').removeClass('weak');
            $('.passwordMsg').addClass('pass');
            return;
        }
        const isStrong = strong(value);
        if (isStrong){
            $('#password').removeClass(['weak', 'pass']);
            $('#password').addClass('strong');
            $('.passwordMsg').text('Strong');
            $('.passwordMsg').removeClass('weak');
            $('.passwordMsg').addClass('pass');
            return;
        }
        const isPass = pass(value);
        if (isPass){
            $('#password').removeClass(['weak', 'strong']);
            $('#password').addClass('pass');
            $('.passwordMsg').text('');
            $('.passwordMsg').removeClass('weak');
            $('.passwordMsg').addClass('pass');
            return;
        }
        $('#password').removeClass(['strong', 'pass']);
        $('#password').addClass('weak');
        $('.passwordMsg').text('Password must an: Uppercase, lowercase and a number(0-9)');
        $('.passwordMsg').removeClass('pass');
        $('.passwordMsg').addClass('weak');
        return;
    }

    onPassConfirm = (evt) => {
        const pass1 = $('#password').val();
        const len1 = pass1.length;
        const pass2 = evt.target.value;
        const len2 = pass2.length;
        if (len1<8) {
            $('.passwordMsg').text('Password must at least 8 characters');
            $('#password').removeClass('strong');
            $('#password').addClass('weak');
            $('#password2').removeClass('strong');
            $('#password2').addClass('weak');
            $('.passwordMsg').removeClass('pass');
            $('.passwordMsg').addClass('weak');
            return;
        }
        if (len1 > len2) {
            // Only show error if pass2 has caught up with the pass1 length
            $('.passwordMsg').text('');
            $('#password2').removeClass(['strong', 'weak']);
            return
        }
        if (pass1 !== pass2) {
            // No match
            $('.passwordMsg').text('Paswords do not match');
            $('#password2').removeClass('strong');
            $('#password2').addClass('weak');
            $('.passwordMsg').removeClass('pass');
            $('.passwordMsg').addClass('weak');
        } else {
            // Match
            $('.passwordMsg').text('Passwords Match');
            $('#password2').removeClass('weak');
            $('#password2').addClass('strong');
            $('.passwordMsg').removeClass('weak');
            $('.passwordMsg').addClass('pass');
        }
    }

    render() {
        return (
            <fieldset className="customerDetailsForm">
                <legend>Personal details</legend>
                <FormInput id="title" type="text" name="title" placeholder={'Title'} autofocus={true} />
                <FormInput id="first-name" type="text" name="given-name" placeholder={'First name*'} required={true} />
                <FormInput id="last-name" type="text" name="family-name" placeholder={'Last name*'} required={true} />
                <FormInput id="phone-number" type="text" name="tel" placeholder={'Phone'} />
                <FormInput id="email" type="text" name="email" placeholder={'Email address*'} required={true} />
                <FormInput id="password" type="password" placeholder={'Password*'} autoComplete="new-password" required={true} onChange={this.onPassChange} />
                <FormInput id="password2" type="password" placeholder={'Confirm password*'} autoComplete="new-password" required={true} onChange={this.onPassConfirm} />
                <div className="passwordMsg"></div>
            </fieldset>
        );
    }
}