import React, { Component } from 'react';
import Roundel from './roundel/roundel';
import './checkoutProgress.css'

export default class CheckoutProgress extends Component {
    render() {
        const { props } = this;
        const { step } = props;
        return (
            <div className="checkoutProgress">
                <div className="horizontalLine"></div>
                <Roundel currentStep={step} stepNum={1} stepText="Delivery" />
                <Roundel currentStep={step} stepNum={2} stepText="Payment" />
                <Roundel currentStep={step} stepNum={3} stepText="Confirmation" />
            </div>
        );
    }
}