import React, { Component } from 'react';

import axios from 'axios';

class AdminProductIdToStockPage extends Component {

    fetchFromApi = async () => {
        const adminToken = localStorage.getItem('adminToken');
        const url = '/api/admin/';
        const response = await axios({
            url,
            method: 'POST',
            data: { adminToken }
        });
        if (response) {
            const { data } = response;
            if (data) {
                const { products } = data;
                return products;
            }
            console.log('Data error');
        } else {
            console.log('API response error');
        }
        console.log('Fetch error');
        return null;
    }

    getItems = () => {
        const products = this.fetchFromApi();
        if (products) {
            return (<table>
                <thead>
                    <tr><b>
                        <td>ID</td>
                        <td>Name</td>
                        <td>Stock</td>
                    </b></tr>
                </thead>
                <tbody>
                    {products.map(product => {
                        const { id, name, stock } = product;
                        return (<tr>
                            <td>{id}</td>
                            <td>{name}</td>
                            <td>{stock}</td>
                        </tr>);
                    })}
                </tbody>
            </table>);
        }
        console.log('getItems error');
        return null;
    }


    render() {

        const adminToken = localStorage.getItem('adminToken');
        if (!adminToken) {
            console.log('redirect');
            window.location.href = '/admin-archie-96/login';
        } else {
            console.log('got token:');
        }

        const htmlItems = this.getItems();

        return (
            <div className="admin-page containerFluidMedium">
                {htmlItems}
            </div>
        );
    }

}

export default AdminProductIdToStockPage;