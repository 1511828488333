import React from 'react'
import Confetti from 'react-confetti';

export default function confettiInner() {
  const confettiSource = { x: 0, y: 0, w: window.innerWidth, h: 0 };
  return (
    <div className="totalModal">
      <Confetti numberOfPieces={200} recycle={false} gravity={0.5} confettiSource={confettiSource} />
    </div>
  )
}