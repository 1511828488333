import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Confetti from '../../../components/shop/confetti/confetti';
//import SnackBar from '../../../components/generic/snackbar/snackbar';
import toastSuccess from '../../../materials/icons/toast-success.png';
import StainNew from '../../../materials/icons/stain-new-sm.png';
import StainCharity from '../../../materials/icons/stain-charity-sm.png';
import LoveCycle from '../../../materials/icons/lovecycle.png';
import { updateNav } from '../../../utils/updateActivePage';
import $ from 'jquery';
import './product.css';
import NotFoundPage from '../../notfound/notfound';
import { formatPrice } from '../../../utils/FormatPrice';
import { toTop } from '../../../utils/updateActivePage';
import accentFuncs from '../../../utils/accentFormatter';
import { updateSingleProduct, updateSingleProductByFields } from '../../../utils/objectUtils';
// import e from 'cors';
import Tickbox from '../../../materials/icons/tickbox.png';
import Tile from '../tile/tile';
// temp comment

const { formatAccentsToElementArray } = accentFuncs;

const TickBoxCont = <img src={Tickbox} alt="tickbox" />;

const storageStrings = {
    wineProducts: 'wineProducts5',
    lastProductsCheck: 'lastProductsCheck5'
}

class ProductPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toConfetti: false,
        }
        this.findProduct = this.findProduct.bind(this);
        this.throwConfetti = this.throwConfetti.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.CreateRecommendations = this.CreateRecommendations.bind(this);
    }

    slideToggleCharity = () => {
        $('#charity-info').slideToggle();
        //$('#charity-info').toggleClass('open-charity');
    }

    addToCart(product) {
        toTop();
        const quantity = parseInt($('#quantityProductSelect').val());
        const { props } = this;
        const { shop } = props;
        const { addRemoveToBasket } = shop;
        const { charity } = product;
        // Throw confetti
        if (charity) {
            this.throwConfetti();
        }
        // Add to cart
        let set = null;
        addRemoveToBasket(product, quantity, set);
        this.toast('Added to cart', "#5a5", "#efe", toastSuccess);
    }

    // AddSlideDownListener replacement
    slideProducerInfo = () => {
        $("#producerProductInfo").slideToggle();
        const $caret = $('#infoCaret');
        $caret.toggleClass('open');
        const isOpen = $caret.hasClass('open');
        if (isOpen) {
            $caret.html('&#9650;');
        } else {
            $caret.html('&#9660;');
        }
    }

    throwConfetti() {
        this.setState({ toConfetti: true });
        /*
        setTimeout(() => {
            this.setState({ toConfetti: false });
        }, 5000);
        */
    }

    stopConfetti = () => {
        if (this.state.toConfetti) {
            setTimeout(() => {
                this.setState({ toConfetti: false });
            }, 5000);
        }
    }

    // Display toast in top right corner
    toast = (message, bgColor, textColor, img) => {
        var x = document.getElementById("snackbar");
        var xIcon = document.getElementById('toast-icon');
        var xText = document.getElementById('toast-text');
        xIcon.src = img;
        xText.style.color = textColor;
        xText.innerText = message;
        x.style.backgroundColor = bgColor;
        x.className = "show";
        setTimeout(function () {
            x.className = x.className.replace("show", "");
        }, 3000);
    }

    findProduct() {
        const { props } = this;
        const { match, shop } = props;
        const { products } = shop;
        const urlName = match.params.name;
        let productReturn = null;
        if (!urlName) {
            return null;
        }
        products.forEach(product => {
            if (product && product.nameNoWhSpc) {
                const prodNameLow = product.nameNoWhSpc.toLowerCase();
                const urlNameLow = urlName.toLowerCase();
                if (prodNameLow === urlNameLow) {
                    productReturn = product;
                    return;
                }
            }
        });
        return productReturn;
    }

    async redirectToNotFound() {
        // Gives the page the chance to find the product before slowly revealing 'Not Found'
        setTimeout(() => {
            const trigger = window.document.getElementById('no-product');
            if (trigger) {
                trigger.style.opacity = '1';
            }
        }, 500);
    }

    getPaperBgClassName() {
        return 'paperOffWhite paperOffWhiteDark';
        /* Random background
        const backgroundIndex = Math.round(Math.random() * 4);
        console.log('backgroundIndex', backgroundIndex);
        switch (backgroundIndex) {
            case 1: return 'paperOffWhite'; break;
            case 2: return 'paperBrown'; break;
            case 3: return 'paperWhiteAngled'; break;
            default: return 'paperBlack'; break;
        }
        */
    }

    CreateRecommendations(productList, thisProduct) {
        const { props } = this;
        const { shop } = props;
        const { addRemoveToBasket } = shop;

        const propertyArray = ["category", "grapeType", "country"];
        const propertyArrayLen = propertyArray.length;
        let returnArray = [];
        let returnProductIdsOnly = [];
        let gotThreeRecommendations = false;
        for (let i = 0; i < propertyArrayLen; i++) {
            if (gotThreeRecommendations) {
                break;
            }
            const propertySearch = propertyArray[i];
            for (let j = 0; j < productList.length; j++) {
                const productOuter = productList[j];
                if (productOuter[propertySearch] === thisProduct[propertySearch] && productOuter["id"] !== thisProduct["id"]) {
                    if (
                        !returnProductIdsOnly.includes(productOuter["id"]) &&
                        !returnProductIdsOnly.includes(thisProduct["id"])
                    ) {
                        //console.log(`Pushing ID ${productOuter["id"]}, which doesn't match current page ID: ${thisProduct["id"]}`);
                        returnProductIdsOnly.push(productOuter.id);
                        returnArray.push(<Tile
                            key={'recc' + i}
                            product={productOuter}
                            addRemoveToBasket={addRemoveToBasket}
                            onClickOverwrite={toTop}
                        />
                        );
                        if (returnArray.length >= 3) {
                            gotThreeRecommendations = true;
                        }
                        break;
                    }
                }
            }
        }
        //console.log(returnProductIdsOnly);
        return returnArray;
    }

    /*
    formatTitle = () => {
        console.log('Format init');
        const $titles = $('.productTitle').children('h6');
        console.log($titles);
        if ($titles.length > 0) {
            console.log('titles found');
            for (let i=0; i<$titles.length; i++) {
                let $title = $($titles[i]);
                const titleHtml = formatAccents($title.html().toString());
                $titles.html(titleHtml);
            }
        }
    }
    */

    formatNameSync = (name) => {
        if (name) {
            const titleHtml = formatAccentsToElementArray(name);
            return titleHtml;
        }
        return name;
    }

    /*
    goBack() {
        window.history.go(-1);
        return false;
    }
    */

    updateViewCount = async (id, lastModified, lastChecked) => {
        if (!id || !lastChecked || !lastModified) {
            //console.log('updateViewCount args missing');
            return;
        }
        const unixNow = Math.floor(Date.now() / 1000);
        const secondsSinceLastCheck = unixNow - lastChecked;
        const oneMinute = 60;
        //const fiveSec = 5;
        if (secondsSinceLastCheck > oneMinute) {
            // Only check if our data is 1 minute old
            try {
                const url = `/api/products/single/${id}/${lastChecked}`;
                const response = await axios({
                    url: url,
                    method: 'GET'
                });
                // Do something with new product
                const { success, fullUpdate, product } = response.data;
                product.id = id;
                if (success && product) {
                    const productsFromStorage = JSON.parse(localStorage.getItem(storageStrings.wineProducts));
                    if (fullUpdate) {
                        // Replace product in storage and then state
                        const updatedProducts = updateSingleProduct(productsFromStorage, product);
                        if (updatedProducts) {
                            // Update storage items
                            this.SetJSON(storageStrings.wineProducts, updatedProducts);
                            // Update state
                            //this.props.updateStateProducts(updatedProducts);
                        }
                    } else {
                        // Just update fields in storage then state
                        const updatedProducts = updateSingleProductByFields(productsFromStorage, product);
                        if (updatedProducts) {
                            // Update storage items
                            localStorage.setItem(storageStrings.wineProducts, JSON.stringify(updatedProducts));
                            // Update state
                            //this.props.updateStateProducts(updatedProducts);
                        }
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    componentDidMount() {
        toTop();
    }

    getStains = (product) => {
        const { charity, isNew } = product;

        let stains = [];

        if (isNew) {
            const stain = <img src={StainNew} />;
            stains.push(stain);
        }
        if (charity) {
            const stain = <img src={StainCharity} />;
            stains.push(stain);
            const charityLogo = <img src={LoveCycle} />;
            stains.push(charityLogo);
        }

        if (stains.length > 0) {
            return (
                <div className="stainsContainer stainsContainerLarge">
                    {stains}
                </div>
            );
        }
        return null;
    }

    getQuantities = (stock) => {
        stock = stock ? stock : 0;
        stock = Math.min(stock, 10);
        let options = [];
        for (let i = 1; i <= stock; i++) {
            options.push(
                <option key={'quantity-' + i} value={`${i}`}>{i}</option>
            );
        }
        return options;
    }

    render() {
        this.stopConfetti();
        updateNav();
        const product = this.findProduct();

        this.redirectToNotFound();
        if (!product) {
            // Always loaded initially for a few ms, but opacity set to 0 until a wait period has passed.
            // If a product is found, page re-renders
            // If no page is found before timeout, Opacity is transitioned to 1
            // Might add a spinner for loading
            return <div id="no-product"><NotFoundPage /></div>
        }

        this.updateViewCount(product.id, product.lastModified, product.lastChecked);

        //const na = <span className="na">n/a</span>;
        const { products } = this.props.shop;
        const { id, producer, vintage, country, region, producerInfo, producerInfoArray, smell, smellArray, taste, tasteArray, vegan, sustainable, organic, biodynamic, minimalS, unfined, unfiltered, name, grapeType, stock, charity } = product;
        const abv = product.abv || null;
        //const { category, abv, ameNoWhSpc, charity, arrivalTime, lastModified, orderRank, viewCount, viewRank, volume, visible, stock } = product;
        let recommendationTiles = this.CreateRecommendations(products, product);

        const nameFormatted = this.formatNameSync(name);

        const imgUrl = `${id}.png`;
        const price = formatPrice(product.price);
        const { toConfetti } = this.state;

        const bgClass = this.getPaperBgClassName();

        const isOutOfStock = stock < 1;
        const outOfStock = isOutOfStock ? ' outOfStock' : '';

        const hasSmell = smell || (smellArray && smellArray.length > 0);
        const hasTaste = taste || (tasteArray && tasteArray.length > 0);

        console.log('smell',smell);
        console.log('smellArray',smellArray);

        const producerInfoToPage = producerInfoArray ?
            producerInfoArray.map(producerInfoP => {
                if (producerInfoP) {
                    return <span className="producerInfoParagraph">{producerInfoP}</span>
                }
            })
            : <span className="producerInfoParagraph">{producerInfo}</span>;

        //console.log(smellArray);
        const smellToPage = (smellArray && smellArray.length > 0) ?
            smellArray.map(smellP => {
                //console.log(smellP);
                if (smellP) {
                    return <span className="dataParagraph">{smellP}</span>
                }
            })
            : <span className="dataParagraph">{smell}</span>;

        //console.log(tasteArray);
        const tasteToPage = (tasteArray && tasteArray.length > 0) ?
            tasteArray.map(tasteP => {
                //console.log(tasteP);
                if (tasteP) {
                    return <span className="dataParagraph">{tasteP}</span>
                }
            })
            : <span className="dataParagraph">{taste}</span>;


        const loveCycleInsta = "https://www.instagram.com/lovecycle_";
        const loveCycleFb = "https://www.facebook.com/lovecycle20/";


        //console.log(recommendationTiles);
        return (
            <div>
                <div id="productPage">
                    <Confetti toConfetti={toConfetti} />
                    <div className="productContainer">

                        <div className="backButtonContainer">
                            <Link to="/shop/">&lt;- Back to Shop</Link>
                        </div>

                        <div className="grapeTape mobile"></div>

                        <div className="productTitle mobile">
                            <h6>"{nameFormatted}"</h6>
                        </div>

                        <div id="productImgBg" className={`productImgWrapper ${bgClass}`}>
                            <img src={"/uploads/products/" + imgUrl} className="productPageImg" title={name} alt={name} />
                            {this.getStains(product)}
                        </div>

                        <div className="productTableInfoContainer courierText">
                            <p><b>Grape:</b> {grapeType}</p>
                            <p><b>Region:</b> {region}</p>
                            <p><b>Country:</b> {country}</p>
                            { abv !== null ? <p><b>ABV:</b> {abv}%</p> : null }
                            {vintage ? <p><b>Vintage:</b> {vintage}</p> : null}
                        </div>

                        <div className="grapeTape" id="grapeTapeLeft"></div>
                    </div>

                    <div className="productContainer colRight">

                        <div className="grapeTape"></div>
                        <div className="productTitle">
                            <h6>"{nameFormatted}"</h6>
                        </div>

                        {charity ?
                            <div className="product-charity-board paperBrown paperBrownTighter lightenBg">
                                <p id="charity-extra-button" className="mt1 charity-heading" ><b><Link to="/help/charity">Charity wine</Link></b></p>
                                <a href={loveCycleInsta} target="_blank" rel="noreferrer"><img src={LoveCycle} /></a>
                                <p className="pointer" onClick={this.slideToggleCharity}>Our current nominated charity wine beneficiary is <b>LoveCycle</b>.</p>
                                <br />
                                <div id="charity-info">
                                    <p><b>LoveCycle</b> is a Grassroots charitable organisation aiming to provide reusable menstrual care to women and girls living in poverty. There are around 68,000 women living in poverty right now in the UK alone. This number consists of a huge womxn sleeping rough or living in hostels and temporary accommodation. The lifetime cost of a period is close to £4,800 which consists of nearly 11,000 disposable sanitary products over a lifetime. Through local fundraising LoveCycle has been able to distribute menstrual care packages to women and girls across the South East, which contain cost and waste-effective reusable menstrual cups. LoveCycle is charged with the goal to de-stigmatise menstruation while empowering young women, girls, and anyone who menstruates. A silent necessity that every woman and girl requires but might not necessarily ask for.</p>
                                    <br />
                                    <p>Residing local to Tunbridge Wells, founded by Best friends and Soul sisters, Tilly and Lucy, this cause is incredibly close to our heart. We are honoured to be able to help elevate their cause. If you would like any more information on upcoming events and ongoing projects related to LoveCycle, follow them on their instagram <a href={loveCycleInsta} target="_blank" rel="noreferrer">@lovecycle_</a> or Facebook page <a href={loveCycleFb} target="_blank" rel="noreferrer">LoveCycle</a>. Keep updated on their incredible journey.</p>
                                </div>
                            </div>
                            : null}

                        <div className="standardFontResponsive" id="productPageproducerInfo">
                            <div className="productDetails">
                                <p id="producerProductExpandButton" onClick={() => this.slideProducerInfo()}><b>Producer:</b> {producer} <span id="infoCaret">&#9660;</span></p>
                                <div id="producerProductInfo">{producerInfoToPage}</div>
                                {hasSmell ? <div className="mt1"><b>Smells like:</b> {smellToPage}</div> : null}
                                {hasTaste ? <div className="mt1"><b>Tastes like:</b> {tasteToPage}</div> : null}
                                {/*mood ? <p><b>Mood:</b> {mood}</p> : null*/}
                                {/*song ? <p><b>Song:</b> {song}</p> : null*/}
                                {/*food ? <p><b>Pairing:</b> {food}</p> : null*/}
                            </div>
                            <div className="tickboxContainer">
                                {vegan ? <p>{TickBoxCont}Vegan</p> : null}
                                {sustainable ? <p>{TickBoxCont}Sustainable</p> : null}
                                {biodynamic ? <p>{TickBoxCont}Biodynamic</p> : null}
                                {organic ? <p>{TickBoxCont}Organic</p> : null}
                                {minimalS ? <p>{TickBoxCont}Minimal sulfites</p> : null}
                                {unfined ? <p>{TickBoxCont}Unfined</p> : null}
                                {unfiltered ? <p>{TickBoxCont}Unfiltered</p> : null}
                            </div>
                        </div>
                        <div className="contentContainer contentWrap bridgesTown" id="addToCartContainer">
                            <p id="priceDisplay">{price}</p>
                            <form className="quantityProductForm" onSubmit={evt => evt.preventDefault()}>
                                {stock ?
                                    <label htmlFor="quantityProductSelect">Quantity: </label>
                                    : null
                                }
                                {stock ?
                                    <select id="quantityProductSelect" name="quantityProductSelect">
                                        {this.getQuantities(stock)}
                                    </select>
                                    : null
                                }
                                <button type="text" className={"addToCartButton bridgesTown" + outOfStock} onClick={isOutOfStock ? null : () => this.addToCart(product)}>{isOutOfStock ? 'Out of Stock' : 'Add To Cart'}</button>
                            </form>
                        </div>
                        <div className="productTaxInfo">
                            <p>Tax and packaging included - shipping calculated at checkout</p>
                        </div>
                    </div>
                </div>
                <div className="recommendationsSection">
                    <h2>If you like this Juice, we recommend these other drops, they’re Good As Hell</h2>
                    <div className="recommendationContainer">
                        {recommendationTiles}
                    </div>
                </div>

            </div>
        )
    }
}

export default ProductPage;