import React from 'react'
import $ from 'jquery';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import './mailingWidget.css';

class MailingWidget extends React.Component {

    toggleInput = () => {
        if ($('#navSubscribeLink').hasClass('open')) {
            // slide down first
            $('#navMailingForm').slideToggle();
            // then right
            setTimeout(function () {
                $('#navSubscribeLink').removeClass('open');
                $('#navMailing').prop('name', '');
            }, 300);
        } else {
            // go left, wait
            $('#navSubscribeLink').addClass('open');
            // slide up
            setTimeout(function () {
                $('#navMailingForm').slideToggle();
                setTimeout(function () {
                    $('#navMailing').focus().prop('name', 'email');
                }, 800);
            }, 200);
        }
    }

    privacyAgreeShow = (evt) => {
        evt.preventDefault();
        const emailVal = $('#navMailing').val();
        if (!emailVal) {
            return;
        }
        $('#privacyAgreeModal').slideDown();
        $('#privacyAgreeModalBg').addClass('modalIn');
        //this.submitEmail();
    }

    render() {
        const alreadySignedUp = localStorage.getItem('mailingListSignup');
        if (alreadySignedUp) {
            $('#navSubscribeLink').addClass('sideline');
            $('#navSubscribeLink').children('span.sub-text').text('');
            $('#navMailingForm').addClass('sideline')
        }

        return (
            <div className="mailingWidget">
                <div className="hideX">
                    <button type="text" className="btn-link" id="navSubscribeLink" onClick={this.toggleInput}>
                        <MailOutlineIcon fontSize="small" />&nbsp;<span className="sub-text">Subscribe to our mailing list</span>
                    </button>
                </div>
                <div id="navMailingForm">
                    <form onSubmit={this.privacyAgreeShow}>
                        <input id="navMailing" type="text" placeholder="Email address"></input>&nbsp;
                        <button id="navMailingBtn" type="submit">Submit</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default MailingWidget;