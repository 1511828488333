import React from 'react';

class OrdersPage extends React.Component {
    render(){
        return(
            <div className="ordersPage">

            </div>
        )
    }
}

export default OrdersPage;