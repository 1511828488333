import React, { Component } from 'react';
import { csvToJson, csvToJson2, csvToJsonPlain } from '../../../../../utils/csvUtils';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

class ProductCopyPage extends Component {

    sendToApi = async() => {
        const successMessageEl = $('#api-success');
        try {
            successMessageEl.text('');
            const productId = $('#productId').val() || 0;
            const productStock = $('#productStock').val() || 0;
            const urlBase = '/api/admin/pitp/copy-product-to-park/';
            const url = urlBase + productId;
            const adminToken = localStorage.getItem('adminToken');
            if (!adminToken) return;
            const response = await axios({
                url,
                method: 'POST',
                data: { adminToken, stock: productStock }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    console.log(data);
                    const { success } = data;
                    if (success) {
                        console.log('Product copied successfully');
                        successMessageEl.text('Success!');
                        return;
                    }
                }
                const msg = 'Error with response!';
                console.log(msg);
                successMessageEl.text(msg);
            } else {
                const msg = 'No respons!e';
                console.log(msg);
                successMessageEl.text(msg);
            }
        } catch (error) {
            console.log(error);
            const msg = 'Error!';
            successMessageEl.text(msg);
        }
    }

    render() {
        return (
            <div className="testPage containerFluidMedium">

                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>

                <div>
                    <h6>Copy Product to PitP Products:</h6>
                    <p id="api-success"></p>
                    <label>ID: </label>
                    <input id="productId" type="number" defaultValue={1} />
                    <br />
                    <label>Stock: </label>
                    <input id="productStock" type="number" defaultValue={0} />
                </div>

                <button onClick={this.sendToApi}>Copy Product to PitP Products</button>

            </div>
        )
    }

}

export default ProductCopyPage;