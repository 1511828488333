import React from 'react';
import './countryInput.css';

class CountryInput extends React.Component {

    autocomplete = () => {
        const id = this.props.id || 'country';
        const countries = JSON.parse(localStorage.getItem('allCountries')) || [];
        const inp = document.getElementById(id);
        /*the autocomplete function takes two arguments,
        the text field element and an countriesay of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the countriesay...*/
            for (i = 0; i < countries.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (countries[i].substr(0, val.length).toUpperCase() === val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + countries[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += countries[i].substr(val.length);
                    /*insert a input field that will hold the current countriesay item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + countries[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });
        /*click open*/
        inp.addEventListener("click", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            if (val) { return false; }
            closeAllLists();
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the countriesay...*/
            for (i = 0; i < countries.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (i < 10) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + countries[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += countries[i].substr(val.length);
                    /*insert a input field that will hold the current countriesay item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + countries[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode === 40) {
                /*If the countriesow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode === 38) { //up
                /*If the countriesow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode === 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt !== x[i] && elmnt !== inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    componentDidMount() {
        this.autocomplete();
    }

    onChangeHandler = (evt) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(evt);
        }
    }

    render() {
        const id = this.props.id || 'country';
        const country = this.props.country;
        const { disable, defaultValue } = this.props;
        const doNotTrackState = this.props.doNotTrackState;

        if (defaultValue) {
            return (
                <div className="autocompleteContainer">
                    <input
                        id={id}
                        className="formInput"
                        type="text"
                        name="address-country"
                        placeholder="Country*"
                        required={true}
                        disabled={disable}
                        onChange={this.onChangeHandler}
                        required
                        defaultValue={defaultValue}
                    />
                </div>
            );
        }

        if (doNotTrackState) {
            return (
                <div className="autocompleteContainer">
                    <input
                        id={id}
                        className="formInput"
                        type="text"
                        name="address-country"
                        placeholder="Country*"
                        required={true}
                        disabled={disable}
                        onChange={this.onChangeHandler}
                        required
                    />
                </div>
            );
        }

        return (
            <div className="autocompleteContainer">
                <input
                    id={id}
                    className="formInput"
                    type="text"
                    name="address-country"
                    placeholder="Country*"
                    required={true}
                    disabled={disable}
                    onChange={this.onChangeHandler}
                    value={country}
                    required
                />
            </div>
        );
    }
}

export default CountryInput;