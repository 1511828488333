import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './sustainability.css';

class SustainabilityPage extends Component {
    render() {
    updateNav();
        return (
            <div className="containerFluid">
                <div className="paperBrown standardFontResponsive lightenBg linkHighlight" id="sustainContainer">
                    <h4 className="centeredHeader">Sustainability</h4>
                    <p>We only have one Earth right? It does not make sense establishing a business in the 21st century without taking sustainability into account from day one and doing our part. Why not embrace being eco-friendly from the get-go opposed to having to pivot down the line and spend money on corrective press releases ‘now with no plastic packaging’... Silly silly.</p>

                    <p>We want to make our footprint as small as we viably can. When we set up Carafe in every decision from choosing suppliers, purchasing packaging tape and purchasing logo stickers we always checked ‘is this sustainable and eco-friendly?’. These details are important. Our logo stickers are biodegradable, all our paper packaging is recyclable - even the tape, we checked the adhesive and we strategically chose suppliers that resonate this message. </p>

                    <p>All of our packaging is recyclable and we use no plastics in our production or distribution. </p>

                    <p>We are part of the 1% for the Planet initiative founded by the creators of Patagonia and Blue Ribbon files. <span className="underline">This means at least 1% of Carafe’s annual sales will go to environmental organisations, protecting our planet.</span></p>

                    <p>The idea of the founders was simple: because companies profit from the resources they take from the earth, they should protect those resources. We hear that and we want to be part of that, from association by purchasing from Carafe you are part of that too.<br />
                    We want to take action and connect with those that do it best. <br />
                    Find out more about that amazing mission here; <a href="https://www.onepercentfortheplanet.org" target="_blank" rel="noreferrer">1% for the Planet</a>  </p>

                    <p>We love local so if you are an environmentally conscious business that is interested in partnering with us please get in touch and we can work together to make our sustainability stronger together. Contact us at <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk </a></p>
                </div>
            </div>
        );
    }
};

export default SustainabilityPage;