export function csvToJson(csvString) {
  if (!csvString) {
    console.log('No CSV input');
    return false;
  }
  if (typeof(csvString) !== 'string') {
    console.log('Input not a string');
    return false;
  }

  const delimiter = ',';

  const rowsArrays = csvString.split(',$£$\n');
  if (rowsArrays.length < 3) {
    console.log('Not enough rows');
    return false;
  }
  const headersString = rowsArrays.splice(0, 1);
  const typesString = rowsArrays.splice(0, 1);

  const headerRows = stringToArray(headersString[0], delimiter);
  const typeRows = stringToArray(typesString[0], delimiter);
  const bodyRows = rowsArrays.map(row => stringToArray(row, delimiter));

  const areAllSameLen = allSameLen(headerRows, typeRows, bodyRows);
  if (!areAllSameLen) {
    console.log('Rows not same length');
    return false;
  }

  const productObjects = bodyRows.map(row => {
    const rowObject = {};
    for (let i = 0; i < areAllSameLen; i++) {
      const key = headerRows[i];
      const type = typeRows[i];
      const valueString = row[i];
      const convertResponse = convertToDataType(valueString, type);
      const successfulConversion = convertResponse[0];
      if (successfulConversion && convertResponse[1] !== undefined) {
        const convertedValue = convertResponse[1];
        rowObject[key] = convertedValue;
      }
    }
    return rowObject;
  });

  const jsonResponse = {
    header: headerRows,
    products: productObjects
  }

  return jsonResponse;
}

export function csvToJsonPlain(csvString) {
  if (!csvString) {
    console.log('No CSV input');
    return false;
  }
  if (typeof (csvString) !== 'string') {
    console.log('Input not a string');
    return false;
  }
  const delimiter = ',';

  const rowsArrays = csvString.split('\n');
  if (rowsArrays.length < 3) {
    console.log('Not enough rows');
    return false;
  }
  const headersString = rowsArrays.splice(0, 1);
  const typesString = rowsArrays.splice(0, 1);

  const headerRows = stringToArray(headersString[0], delimiter);
  const typeRows = stringToArray(typesString[0], delimiter);
  const bodyRows = rowsArrays.map(row => stringToArray(row, delimiter));

  const areAllSameLen = allSameLen(headerRows, typeRows, bodyRows);
  if (!areAllSameLen) {
    console.log('Rows not same length');
    return false;
  }

  const productObjects = bodyRows.map(row => {
    const rowObject = {};
    for (let i = 0; i < areAllSameLen; i++) {
      const key = headerRows[i];
      const type = typeRows[i];
      const valueString = row[i];
      const convertResponse = convertToDataType(valueString, type);
      const successfulConversion = convertResponse[0];
      if (successfulConversion && convertResponse[1] !== undefined) {
        const convertedValue = convertResponse[1];
        rowObject[key] = convertedValue;
      }
    }
    return rowObject;
  });

  const jsonResponse = {
    header: headerRows,
    products: productObjects
  }

  return jsonResponse;
}

export function csvToStockUpdateObjs(csvString) {
  const csvPrepend = "id,stock\nnumber,number\n";
  const fullCsvString = csvPrepend + csvString;

  if (!fullCsvString) {
    console.log('No CSV input');
    return false;
  }
  if (typeof (fullCsvString) !== 'string') {
    console.log('Input not a string');
    return false;
  }
  const delimiter = ',';

  const rowsArrays = fullCsvString.split('\n');
  if (rowsArrays.length < 3) {
    console.log('Not enough rows');
    return false;
  }
  const headersString = rowsArrays.splice(0, 1);
  const typesString = rowsArrays.splice(0, 1);

  const headerRows = stringToArray(headersString[0], delimiter);
  const typeRows = stringToArray(typesString[0], delimiter);
  const bodyRows = rowsArrays.map(row => stringToArray(row, delimiter));

  const areAllSameLen = allSameLen(headerRows, typeRows, bodyRows);
  if (!areAllSameLen) {
    console.log('Rows not same length');
    return false;
  }

  const productObjects = bodyRows.map(row => {
    const rowObject = {};
    for (let i = 0; i < areAllSameLen; i++) {
      const key = headerRows[i];
      const type = typeRows[i];
      const valueString = row[i];
      const convertResponse = convertToDataType(valueString, type);
      const successfulConversion = convertResponse[0];
      if (successfulConversion && convertResponse[1] !== undefined) {
        const convertedValue = convertResponse[1];
        rowObject[key] = convertedValue;
      }
    }
    return rowObject;
  });

  return productObjects;
}

function stringToArray(rowString, delimiter) {
  const dirtyCols = rowString.split(delimiter);
  return dirtyCols.map(row => row.trim());
}

function allSameLen(headerRows, typeRows, bodyRows) {
  const headerLen = headerRows.length;
  //console.log(headerLen);
  const typesLen = typeRows.length;
  //console.log(typesLen);
  bodyRows.forEach(row => {
    const rowLen = row.length;
    //console.log(rowLen); 
    if (rowLen !== headerLen) { return false }
  });
  return headerLen === typesLen ? headerLen : 0;
}

function convertToDataType(data, type) {
  if (type === 'string') {
    console.log(data);
  }
  const numType = 'number';
  const stringType = 'string';
  const booleanType = 'boolean';
  const floatType = 'float';
  let result = [true];
  switch (type) {
    case numType: result.push(parseInt(data)); break;
    case stringType: result.push(data.toString()); break;
    case booleanType: result.push(parseBoolCustom(data)); break;
    case floatType: result.push(parseFloat(data)); break;
    default: result[0] = false; break;
  }
  return result;
}



function parseBoolCustom(string) {
  switch (string.toLowerCase()) {
    case 'true': return true; break;
    case 'yes': return true; break;
    case 'x': return true; break;
    case 'false': return false; break;
    case 'no': return false; break;
  }
  return false;
}

// - - - - - - - -

export function csvToJson2(csvString, delimiter, stringSurround, endOfLine) {
  delimiter = delimiter || ',';
  stringSurround = stringSurround || '###';
  endOfLine = endOfLine || '$£$';
  endOfLine = ',' + endOfLine + '\n';

  if (!csvString) {
    console.log('No CSV input');
    return false;
  }
  if (typeof(csvString) !== 'string') {
    console.log('Input not a string');
    return false;
  }

  const rowsArrays = csvString.split(endOfLine);
  if (rowsArrays.length < 3) {
    console.log('Not enough rows');
    return false;
  }
  const headersString = rowsArrays.splice(0, 1);
  //console.log(headersString);
  const typesString = rowsArrays.splice(0, 1);
  //console.log(typesString);


  const headerRows = stringToArray(headersString[0], delimiter);
  const typeRows = stringToArray(typesString[0], delimiter);
  const bodyRows = rowsArrays.map(row => stringToArray(row, delimiter));

  //console.log(bodyRows);

  const areAllSameLen = allSameLen(headerRows, typeRows, bodyRows);
  if (!areAllSameLen) {
    console.log('Rows not same length');
    return false;
  }

  const productObjects = bodyRows.map(row => {
    const rowObject = {};
    for (let i = 0; i < areAllSameLen; i++) {
      const key = headerRows[i];
      const type = typeRows[i];
      const valueString = row[i];
      if (valueString === undefined) continue;
      const convertResponse = convertToDataType2(valueString, type);
      const successfulConversion = convertResponse[0];
      if (successfulConversion && convertResponse[1] !== undefined) {
        const convertedValue = convertResponse[1];
        rowObject[key] = convertedValue;
      }
    }
    return rowObject;
  });

  console.log(productObjects);

  const jsonResponse = {
    header: headerRows,
    products: productObjects
  }

  return jsonResponse;
}

function stringToArray2(rowString, delimiter) {
  const dirtyCols = rowString.split(delimiter);
  return dirtyCols.map(row => row.trim());
}

function allSameLen2(headerRows, typeRows, bodyRows) {
  const headerLen = headerRows.length;
  //console.log(headerLen);
  const typesLen = typeRows.length;
  //console.log(typesLen);
  bodyRows.forEach(row => {
    const rowLen = row.length;
    //console.log(rowLen); 
    if (rowLen !== headerLen) { return false }
  });
  return headerLen === typesLen ? headerLen : 0;
}

function convertToDataType2(data, type) {
  const numType = 'number';
  const stringType = 'string';
  const booleanType = 'boolean';
  const floatType = 'float';
  let results = [true];
  const newLine = '\n';
  const shouldBeArray = data.includes(newLine);
  switch (type) {
    case numType: results.push(parseInt(data)); break;
    case stringType: 
      if (shouldBeArray) {
        const len = data.length;
        if ( data[0] === '"' && data[len-1] === '"' ) {
          data = data.substring(1, len-2)
        }
      }
      data = data.replace(/~/g, ',');
      data = data.replace(/_%\^/g, '"');
      data = data.replace(/_%\*/g, "'");
      data = data.replace(/_%\$/g, '‘');
      data = data.replace(/_%£/g, '’');
      results.push( shouldBeArray ? data.toString().split(newLine) : data.toString() ); break;
    case booleanType: results.push(parseBoolCustom(data)); break;
    case floatType: results.push(parseFloat(data)); break;
    default: results[0] = false; break;
  }
  return results;
}

function parseBoolCustom2(string) {
  switch (string.toLowerCase()) {
    case 'true': return true; break;
    case 'yes': return true; break;
    case 'x': return true; break;
    case 'false': return false; break;
    case 'no': return false; break;
  }
  return undefined;
}