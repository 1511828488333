import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

class PitPOrdersPage extends Component {

    getOrders = async () => {
        // compare storage timestamp, ignore if less than 2s diff ?
        const lastChecked = localStorage.getItem('pitpOrderLastChecked') || 0;
        const unixNow = Math.floor(Date.now() / 1000);
        if (unixNow - lastChecked < 5) {
            return;
        }
        const $statusContainer = $('#statusContainer');
        $statusContainer.text('Sending...');
        try {
            const url = '/api/admin/pitp/orders';
            const adminToken = localStorage.getItem('adminToken');
            const response = await axios({
                url,
                method: 'POST',
                data: { adminToken }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    //console.log(data);
                    const { success } = data;
                    if (success) {
                        const orders = data['waitings'] || [];
                        if (orders) {
                            const storageString = localStorage.getItem('pitpOrders') || "";
                            if (storageString) {
                                const parsed = JSON.parse(storageString);
                                if (parsed) {
                                    let shouldUpdate = false;
                                    for (let [k, v] of Object.entries(parsed)) {
                                        if (orders[k] !== v) {
                                            shouldUpdate = true;
                                            break;
                                        }
                                    }
                                    if (!shouldUpdate) {
                                        return;
                                    }
                                }
                            }
                            // Code reached here: no storage
                            const stringified = JSON.stringify(orders);
                            localStorage.setItem('pitpOrders', stringified);
                            localStorage.setItem('pitpOrderLastChecked', unixNow);
                            $statusContainer.text('Orders fetched');
                            window.location.reload();
                            return;
                        }
                    }
                }
                console.log('Error with response');
                $statusContainer.text('Error');
            } else {
                console.log('No response')
                $statusContainer.text('Error');
            }
        } catch (error) {
            console.log(error);
            $statusContainer.text('Error');
        }
    }

    convertArrayToElements = (orders) => {
        const changePackingStatus = this.changePackingStatus;
        const toggleTable = function () { };
        if (orders) {
            return (orders.map(order => {
                const { billingAddress, productLines, timestamp, orderReferenceNumber, orderTotal, paymentReceived, beenCollected } = order;
                const { fullName, address1, address2, city, county, country, postcode, email, phone } = billingAddress;

                const orderId = order.id;

                const unixNow = Math.floor(Date.now() / 1000);
                const daysSinceEpoch = Math.floor(unixNow / 86400);
                const unixThisMorning = daysSinceEpoch * 86400;
                const daysPassed = daysSinceEpoch - Math.floor(timestamp / 86400);
                const theDay = unixThisMorning < timestamp ? "Today" : `${daysPassed} days ago`;

                let unix_timestamp = timestamp
                // Create a new JavaScript Date object based on the timestamp
                // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                var date = new Date(unix_timestamp * 1000);
                // Hours part from the timestamp
                var hours = date.getHours();
                // Minutes part from the timestamp
                var minutes = "0" + date.getMinutes();
                // Seconds part from the timestamp
                var seconds = "0" + date.getSeconds();

                // Will display time in 10:30:23 format
                var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

                const greyedOutClass = beenCollected ? ' greyOut' : '';

                //const changePackingStatus = this.changePackingStatus;

                return (<div className={"admin-dhl-orders-all-order" + greyedOutClass}>

                    <p onClick={toggleTable}>
                        <strong>Time:</strong> {formattedTime}<br />
                        <strong>Day:</strong> {theDay}<br />
                        <strong>Order ID #:</strong> {order.id}<br />
                        <strong>Order Reference No:</strong> {orderReferenceNumber}<br />

                        <br />
                        {fullName}<br />
                        {address1}<br />
                        {address2}<br />
                        {city}<br />
                        {county}<br />
                        {country}<br />
                        {postcode}<br />
                        <br />
                        {email}
                    </p>

                    <div className="local-delivery-container">
                        {paymentReceived ? <span className="pastel-blue">Payment Received</span> : null}
                    </div>

                    <div className="packing-status-container">
                        {beenCollected ? <span className="pastel-green">Already collected</span> : <span className="pastel-red"><b>To be collected...</b></span>}
                    </div>

                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ "width": "20%" }}><strong><u>ID</u></strong></td>
                                    <td style={{ "width": "30%" }}><strong><u>Producer</u></strong></td>
                                    <td style={{ "width": "30%" }}><strong><u>Name</u></strong></td>
                                    <td style={{ "width": "20%" }}><strong><u>Quantity</u></strong></td>
                                </tr>
                            </thead>
                            <tbody>

                                {productLines.map(productLine => {
                                    const { id, name, quantity } = productLine;
                                    return (<tr>
                                        <td><strong>{id}</strong></td>
                                        <td>{/*productMenu[id]['producer'] || ''*/}</td>
                                        <td>{name}</td>
                                        <td>x{quantity}</td>
                                    </tr>);
                                })}

                            </tbody>
                        </table>

                    </div>

                    <div>
                        <button className={beenCollected ? '' : 'hide-btn'} onClick={(evt) => { changePackingStatus(orderId, false, evt) }}>Undo "Collected"" status</button>
                        <button className={beenCollected ? 'hide-btn' : ''} onClick={(evt) => { changePackingStatus(orderId, true, evt) }}>Mark as "Collected"</button>
                    </div>

                </div>);
            }));
        } else {
            console.log('convertArrayToElements, no orders.');
        }
        console.log('getItems error');
        return null;
    }

    changePackingStatus = async (orderId, newStatus, evt) => {
        // // /api/admin/pitp/update-order
        //evt.target
        try {
            const url = '/api/admin/pitp/update-order';
            const adminToken = localStorage.getItem('adminToken');
            const response = await axios({
                url,
                method: 'POST',
                data: { adminToken, orderId, newStatus }
            });
            if (response) {
                const data = response.data;
                if ( data['success'] || data['success1'] || data['success2'] ) {
                    const storageString = localStorage.getItem('pitpOrders') || "";
                    if (storageString) {
                        const parsed = JSON.parse(storageString);
                        if (parsed) {
                            for (let i = 0; i < parsed.length; i++) {
                                if (parsed[i]['id'] === orderId) {
                                    parsed[i]['beenCollected'] = newStatus;
                                    break;
                                }
                            }
                            const storageStringNew = JSON.stringify(parsed);
                            localStorage.getItem('pitpOrders', storageStringNew);
                            window.location.reload();
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }


        // hit api
        // update storage
        // reload
    }

    displayFromStorage = () => {
        const storageString = localStorage.getItem('pitpOrders') || "";
        if (storageString) {
            const parsed = JSON.parse(storageString);
            if (parsed) {
                const elements = this.convertArrayToElements(parsed);
                return elements;
            }
        }
        return null;
    }

    displayFromAPI = async () => {
        // hit API

        // store
        //localStorage.setItem('pitpOrders', obj)
        // reload
    }

    componentDidMount() {
        this.getOrders();
    }

    sampleOrders = () => {
        const orders = [{
            "productLines": [
                {
                    "_id": {
                        "$oid": "60e866330201db00151ac86d"
                    },
                    "id": 52,
                    "quantity": 1,
                    "name": "Susucaru Rosso",
                    "price": 2460,
                    "charity": false
                }
            ],
            "billingAddress": {
                "fullName": "Mr Pickles",
                "address1": "99 The Road",
                "address2": "",
                "city": "The Toon",
                "county": "Newcastle",
                "country": "GB",
                "postcode": "AB12 3CD",
                "email": "myemail@hotmail.co.uk",
                "phone": "0123456789"
            },
            "id": 1,
            "orderReferenceNumber": 268056,
            "timestamp": 1625843248,
            "orderTotal": 2460,
            "paymentReceived": true,
            "beenCollected": false,
            "last4": "4242",
        }];
        return orders;
    }

    initSotrage = (evt) => {
        const orders = this.sampleOrders();
        const stringified = JSON.stringify(orders);
        localStorage.setItem('pitpOrders', stringified);
        window.location.reload();
    }

    render() {
        const orders = this.displayFromStorage();
        console.log(orders);
        return (
            <div className="testPage containerFluidMedium">

                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>

                <p id="statusContainer"></p>

                <div>
                    <h6>Pub in the Park Orders:</h6>
                    <div id="orders-container"></div>
                    {orders ? <div>{orders}</div> : null}
                </div>

                <br />
                <br />
                <br />
                <button onClick={this.initSotrage}>Init test order storage</button>

            </div>
        )
    }

}

export default PitPOrdersPage;