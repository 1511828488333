import React from 'react';
import { isArray } from 'lodash';
import $ from 'jquery';
import axios from 'axios'
import { updateNav } from '../../../utils/updateActivePage';
import orderUtils from '../../../utils/orderUtils';
import './accountOverview.css';
import FormInput from '../../../components/generic/formInput/formInput';
import CountryInput from '../../../components/generic/formInput/countryInput/countryInput';

let ordersArray = [];

class AccountOverview extends React.Component {
    constructor(props) {
        super(props);
    }

    editAddress = (evt) => {
        evt.preventDefault();
        const $displayAddress = $('#address-display');
        const $editAddress = $('.editValue');

        $displayAddress.hide();
        $editAddress.show();
    }

    cancelEdit = (evt) => {
        evt.preventDefault();
        const $displayAddress = $('#address-display');
        const $editAddress = $('.editValue');

        $editAddress.hide();
        $displayAddress.show();
    }


    saveAddress = async() => {
        const { updatePrimaryAddress } = this.props;
        if (updatePrimaryAddress) {
            const title = $('#title').val() || '';
            const name = $('#edit-name').val() || '';
            const address1 = $('#address1').val() || '';
            const address2 = $('#address2').val() || '';
            const city = $('#city').val() || '';
            const county = $('#county').val() || '';
            const country = $('#country').val() || '';
            const postcode = $('#postcode').val() || '';

            const primaryAddress = {
                title,
                name,
                address1,
                address2,
                city,
                county,
                country,
                postcode
            };

            const res = updatePrimaryAddress(primaryAddress);
            const $displayAddress = $('#address-display');
            const $editAddress = $('.editValue');

            $displayAddress.show();
            $editAddress.hide();
        }
    }

    fetchOrders = async() => {
        const { token } = this.props;
        if (!token) return;

        const url = `/api/accounts/orders/summary`;
        try {
            const primaryAddress = await axios({
                url: url,
                method: 'POST',
                data: { sessionToken: token }
            });
            const { data } = primaryAddress;
            //console.log(data);
            const { success, orders } = data;
            if (success) {
                if (orders) {
                    this.props.updateOrderHistory(orders)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        this.fetchOrders();
    }

    showOrder = (evt) => {
        const $heading = $(evt.target);
        $heading.siblings().slideToggle();
    }

    ListOrders = (orders) => {
        if (!orders) {
            return false;
        }
        if (!isArray(orders)) {
            return false;
        }
        if (orders.length < 1) {
            return false;
        }
        if (orders) {
            return (
                <div className="order-container">
                        {orders.map(order => {
                            return (
                                <p className="order-line">
                                    <h6 onClick={this.showOrder}>Order #{orderUtils.padOrderNumber(order.id)}</h6>
                                    <ul>
                                        {order.productLines.map(productLine => {
                                            return (
                                                <li>#{productLine.id} {productLine.name} x{productLine.quantity}</li>
                                            )
                                        })}
                                    </ul>
                                    <ul>
                                        <li><b>Subtotal:</b> £{ (order.subtotalWithDiscount/100).toFixed(2) }</li>
                                        <li><b>Delivery:</b> £{ order.deliveryCost ? (order.deliveryCost/100).toFixed(2) : 'Free' }</li>
                                        <li><b>Total:</b> £{ (order.totalWithDelivery/100).toFixed(2) }</li>
                                    </ul>
                                </p>
                            );
                        })}
                </div>
            );
        } else {
            return false;
        }
    }

    noOrders = () => {
        return <p>You have no previous orders to display.</p>
    }


    handleLogout = async () => {
        const $logoutButton = $('#logoutButton');
        try {
            $logoutButton.prop('disabled', true);
        } catch (error) {
            console.log(error);
        }
        const { logout } = this.props;
        const logoutSuccess = await logout();
        if (logoutSuccess === false) {
            try {
                $logoutButton.prop('disabled', false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    render() {
        updateNav();

        /*
        const primaryAddress = {
            username: "carafeMod",
            email: "bob@dylan.com",
            phoneNumber: "01872304822",
            name: "Bob Dylan",
            address1: "Bob Dylan Lane",
            town: "Bob Dylan World",
            county: "Bob Dylan",
            postCode: "TN19 3LB"
        };
        */

        const { account, orders } = this.props;
        const { details } = account;
        const { email, firstName, lastName, primaryAddress } = details;
        const { addressName, title, name, address1, address2, city, county, country, phone, postcode } = primaryAddress;
        const welcomeName = firstName + ' ' + lastName;
        let fullAddressName = name;
        if (title) fullAddressName = title + ' ' + fullAddressName;

        // const primaryAddress = {
        //     email: email || "bob@dylan.com",
        //     phoneNumber: phone || "018-723-04822",
        //     name: fullName || "Bob Dylan",
        //     address1: address1 || "Bob Dylan Lane",
        //     town: city || "Bob Dylan World",
        //     county: county || "Bob Dylan",
        //     postCode: postcode || "TN19 3LB"
        // };

        //const orderContainers = this.ListOrders(orders) || this.noOrders();

        return (
            <div className="accountPage">
                <div className="containerFluid">
                    <div className="contentWrapper paperWhiteAngled accountOverviewSection flexCentered">


                        <div>
                            <h2 className="courierText centeredText">Account Overview</h2>
                            <h2 className="bridgesTownHeader centeredText accountWelcome">Welcome {welcomeName}</h2>
                        </div>

                        <div>
                            <h4 className="courierText centeredText">Address and Billing Information</h4>
                            <div className="detailsContainer standardFontResponsive">
                                <hr></hr>

                                <div className="detailRow">
                                    <div className="detailContainer">
                                        <span>Contact Details {'>'}</span>
                                    </div>
                                    <div className="detailContainer">
                                        <div className="detailSpacer">
                                            <div className="detailKey">
                                                <span><b>Email address</b></span>
                                            </div>
                                            <div className="detailValue">
                                                <span>{email}</span>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="detailSpacer">
                                            <div className="detailKey">
                                                <span><b>Phone Number</b></span>
                                            </div>
                                            <div className="detailValue">
                                                <span>{ phone ? phone : 'No number provided'}</span>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className="detailSpacer">
                                            <div className="detailKey">
                                                <span><b>{addressName} Address:</b></span>
                                            </div>
                                            <div id="address-display" className="detailValue">
                                                <span>{fullAddressName}</span>
                                                <span>{address1}</span>
                                                { address2 ? <span>{address2}</span> : null }
                                                <span>{ city }</span>
                                                <span>{county}</span>
                                                <span>{country}</span>
                                                <span>{postcode}<button id="edit-address-btn" type="text" onClick={this.editAddress}>Edit</button></span>
                                                <br></br>
                                            </div>
                                            <div className="editValue">
                                                <FormInput id="title" className={'title'} name="title" placeholder={'Title'} defaultValue={title} required={false} />
                                                <FormInput id="edit-name" name="full-name" placeholder={'Name*'} defaultValue={name} required={true} />
                                                <FormInput id="address1" name="address-line1" placeholder={'Address Line 1*'} defaultValue={address1} required={true} />
                                                <FormInput id="address2" name="address-line2" placeholder={'Address Line 2'} defaultValue={address2} required={false} />
                                                <FormInput id="city" name="address-locality" placeholder={'Town/City*'} defaultValue={city} required={true} />
                                                <FormInput id="county" name="address-county" placeholder={'Region/County*'} defaultValue={county} required={true} />
                                                <CountryInput placeholder={'Country*'} defaultValue={country} required={true} />
                                                <FormInput id="postcode" name="address-postcode" placeholder={'Postcode*'} defaultValue={postcode} required={true} />
                                                <button className="cancel" type="text" onClick={this.cancelEdit}>Cancel</button>
                                                <button className="submit-btn" type="text" onClick={this.saveAddress}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="containerFluidNarrow logoutContainer">
                    <button id="logoutButton" className="submitButton" type="text" onClick={this.handleLogout}>Logout</button>
                </div>
            </div>
        )
    }
}

export default AccountOverview;



/*






                <div className="containerFluid">
                    <div className="contentWrapper paperBrown accountOverviewSection flexCentered lightenBg">

                        <div>
                            <h2 className="bridgesTownHeader centeredText">Order Histroy</h2>
                        </div>
                        <hr></hr>

                        <div>
                            {orderContainers}
                        </div>

                    </div>
                </div>








*/