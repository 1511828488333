import React from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import  './search.css';

class SearchPage extends React.Component {
    render() {
updateNav();
        // Get serialised search params from URL?

        return (
            <div>
                <h4>Search</h4>
                <p>Search for your favourite wine</p>
            </div>
        )
    }
}

export default SearchPage;