import { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
//import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from "axios";
import Row from "./prebuilt/Row";
//import BillingDetailsFields from "./prebuilt/BillingDetailsFields";
import CheckoutProgress from '../../../components/checkout/checkoutProgress/checkoutProgress';
import OrderSummary from "./orderSummary/orderSummary";
import SummaryAddress from "./summaryAddress/summaryAddress";
import SubmitButton from "./prebuilt/SubmitButton";
import CheckoutError from "./prebuilt/CheckoutError";
import BillingForm from '../billingForm/billingForm'
import './checkoutform.css';

const CheckoutForm = ({ onSuccessfulCheckout, checkout, defaultEmail, updateCheckoutAddress, updateCheckoutAddressWhole, basket, token }) => {
  // State
  const [isProcessing, setProcessingTo] = useState(false);
  const [isAtConfirm, setAtConfirm] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [cardError, setCardError] = useState();
  const [stateClientSecret, setStateClientSecret] = useState('');
  const [cardIsComplete, setCardIsComplete] = useState(false);

  $('.roundelContainer a').on('click', function(evt) {
    if (evt.target.text === "Payment") {
      evt.preventDefault();
      setAtConfirm(false);
    }
  });

  // Stripe
  const stripe = useStripe();
  const elements = useElements();

  // Card styling
  const iframeStyles = {
    base: {
      color: "#333",
      fontSize: "16px",
      iconColor: "#666",
      "::placeholder": {
        color: "#ccc"
      }
    },
    invalid: {
      iconColor: "#dd2233",
      color: "#dd2233"
    },
    complete: {
      iconColor: "#cfc"
    }
  };

  // Card icon
  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
  };

  // If card error
  const handleCardDetailsChange = ev => {
    setAtConfirm(false);
    const isComplete = ev.complete;
    if (isComplete) {
      setCardIsComplete(true);
    } else {
      setCardIsComplete(false);
      setCardError(true);
    }

    if (ev.error) {
      setCheckoutError(ev.error.message);
      setCardError(true);
      setAtConfirm(false);
      setCardIsComplete(false);
    } else {
      setCheckoutError();
      setCardError(false);
    }
  };

  // Change address
  const onChangeHandler = (evt) => {
    updateCheckoutAddress(evt.target.id, evt.target.value);
  }

  // Not needed
  /*
  const handleSaveClientSecretAndPaymentMethod = (clientSecret, paymentMethodReq) => {
    console.log('Promise, saving to state: ', stripePromise);
    saveClientSecretAndPaymentMethod(clientSecret, paymentMethodReq, stripePromise);
  }
  */

  // HANDLER
  const handleFormSubmit = async ev => {
    ev.preventDefault();
    $('#payment-error-container').text('');

    if (!cardIsComplete) {
      setAtConfirm(false);
      return;
    }

    setProcessingTo(true);
    if (cardError) {
      setAtConfirm(false);
      setProcessingTo(false);
      return;
    }

    const name = $('#fullName').val();
    //const email = $('#email').val();
    const line1 = $('#address1').val();
    const city = $('#city').val();
    const state = $('#county').val();
    const country = $('#country').val();
    const postal_code = $('#postcode').val();
    const emailInput = $('#email').val() || '';
    const email = emailInput || defaultEmail;
    //console.log(name, email, city, line1, state, postal_code, country);
    if (!name || !line1 || !city || !state || !postal_code || !country || !email) {
      setProcessingTo(false);
      return;
    }
    const countryLower = country.toLowerCase();
    if ( countryLower !== 'england' && countryLower !== 'wales' && countryLower !== 'scotland' && countryLower !== 'united kingdom' && countryLower !== 'northern ireland' ) {
      $('#mainlandOnly').slideDown();
      setProcessingTo(false);
      return;
    }
    $('#mainlandOnly').hide();

    // Fork
    if (!isAtConfirm) {
      getClientSecret(email);
    } else {
      confirmStripePayment(name, email, city, line1, state, postal_code);
    }
  }; // handle form submit

  // SERVER
  const getClientSecret = async (email) => {
    if (!totalWithDelivery) {
      setProcessingTo(false);
      return;
    }
    let response = null;
    try {
      response = await axios.post("/api/shop/stripe/payment-intent", {
        amount: totalWithDelivery,
        sessionToken: token,
        basketLite: basket,
        shippingAddress: checkout.shippingAddress,
        billingAddress: checkout.billingAddress,
        giftMessage: basket.giftNote,
        deliveryMessage: checkout.deliveryAdditionalNotes,
        bodyEmail: email
      });
      if (response.data) {
        const { data } = response;
        //console.log(data);
        if (data.client_secret) {
          // Setup Success 
          const clientSecret = data.client_secret;
          $('#payment-error-container').text('');
          setStateClientSecret(clientSecret);
          setAtConfirm(true);
        } else if (response.data.toastMessage) {
          // No secret
          const toastMessage = response.data.toastMessage || 'Error';
          $('#payment-error-container').text(toastMessage);
        } else {
          // No error message
          $('#payment-error-container').text("Error with Carafe server");
        }
      }
      else {
        console.log('No response data for payment');
      }
    } catch (error) {
      console.log('Error:');
      console.log(error);
      console.log('Response:');
      console.log(response);
      setCheckoutError(error.message);
    }
    setProcessingTo(false);
  }

  // STRIPE
  const confirmStripePayment = async (name, city, line1, state, postal_code) => {
    setProcessingTo(true);

    const emailInput = $('#stripe-email').text() || '';
    const email = emailInput || defaultEmail;

    const billingDetails = {
      name,
      email,
      address: {
        city,
        line1,
        state,
        postal_code,
      }
    };
    const cardElement = elements.getElement("card");

    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: billingDetails
      });

      if (paymentMethodReq.error) {
        //$('#payment-error-container').text("Possible error with payment, please contact us");
        setCheckoutError(paymentMethodReq.error.message);
        setAtConfirm(false);
        setProcessingTo(false);
        return;
      }

      const paymentMethodId = paymentMethodReq.paymentMethod.id;
      const { paymentIntent, error } = await stripe.confirmCardPayment(stateClientSecret, {
        payment_method: paymentMethodId
      });

      if (error) {
        console.log(error);
        $('#payment-error-container').text("Possible error with payment, please contact us");
        setAtConfirm(false);
        setCheckoutError(error.message);
        setProcessingTo(false);
        return;
      }
      //console.log('paymentIntent', paymentIntent);

      onSuccessfulCheckout();
    } catch (err) {
      console.log(err);
      $('#payment-error-container').text("Possible error with payment, please contact us");
      setAtConfirm(false);
      setProcessingTo(false);
      setCheckoutError(err.message);
    }

  }

  // Page info
  const { billingAddress, deliveryAdditionalNotes, shippingAddress } = checkout;
  const { totalWithDelivery } = basket;
  const displayPrice = (totalWithDelivery / 100).toFixed(2);

  const containerClass = isAtConfirm ? "atConfirm" : "";

  return (
    <form onSubmit={handleFormSubmit}>

      <CheckoutProgress step={isAtConfirm ? 3 : 2} />

      {/* Billing card page */}
      <div id="checkout-payment-container" className={containerClass}>
        <BillingForm
          billingAddress={billingAddress}
          updateCheckoutAddress={updateCheckoutAddress}
          updateCheckoutAddressWhole={updateCheckoutAddressWhole}
          email={defaultEmail}
          toDisable={isAtConfirm}
          token={token}
        />

        <Row>
          <input id={"fullName"} className="card-name" type="text" name="full-name" placeholder={'Full name*'} value={checkout.billingAddress.fullName} onChange={onChangeHandler} required />
          <div className="CardElementContainer">
            <CardElement
              options={cardElementOpts}
              onChange={handleCardDetailsChange}
              onKeyUp={handleCardDetailsChange}
            />
          </div>
        </Row>

        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}

        <Row>
          {/* TIP always disable your submit button while processing payments */}
          <SubmitButton disabled={isProcessing || !stripe}>
            {isProcessing ? "Processing..." : `Pay £${displayPrice}`}
          </SubmitButton>
        </Row>

      </div>

      { /* Confirmation Page */}
      <div id="checkout-confirm-container" className={containerClass + " containerFluid"}>

        { /* Container */}
        <div id="checkout-summary-flex">

          { /* Product col */}
          <div id="order-summary" className="summary-col summary-col-10">
            <OrderSummary
              basket={basket}
              checkout={checkout}
            />
          </div>

          { /* Addresses col */}
          <div id="payment-summary" className="summary-col summary-col-5">

            <div className="summary-address">
              <h6>Shipping:</h6>
              <SummaryAddress
                address={shippingAddress}
                deliveryAdditionalNotes={deliveryAdditionalNotes}
              />
            </div>

            <div className="summary-address">
              <h6>Billing:</h6>
              <SummaryAddress
                address={billingAddress}
              />
            </div>

            <SubmitButton
              disabled={isProcessing || !stripe}>
              {isProcessing ? "Processing..." : `Send £${displayPrice}`}
            </SubmitButton>

          </div>

        </div>
      </div>

    </form>
  ); // render return end
} // Class end

export default CheckoutForm;