import { useState } from "react";
import $ from 'jquery';
import Confetti from '../../../components/shop/confetti/confettiInner';
import { updateNav } from '../../../utils/updateActivePage';
import CelebrateGif from '../../../materials/images/gifs/celebrate.gif'
import './checkoutFinished.css';

const CheckoutFinishedPage = ({ success }) => {
  //const [checkoutProps, setCheckoutProps] = useState(success);
  //const [checkoutProps] = useState(success);
  //let { success } = checkoutProps.match.params;
  //console.log(checkoutProps);

  function handleSetConfetti() {
    if (!toConfetti) {
      $('#celebrate-gif-container').slideDown();
      $("html, body").animate({
        scrollTop: $("#celebrate-scroll").offset().top
      }, "fast");
      setConfettiTo(true);
      setTimeout(() => {
        setConfettiTo(false);
      }, 5000);
    }
  }

  const [toConfetti, setConfettiTo] = useState(false);
  updateNav();
  //const success = this.match.params.success || false;
  //console.log(success);
  let successMessage = '';
  let checkoutSuccess = false;
  if (success === 'true') {
    successMessage = 'Checkout complete';
    checkoutSuccess = true;
  } else {
    successMessage = 'Sorry, there was an error during checkout.';
  }



  return (
    <div id="checkout-complete" className="containerFluid containerFluidMedium">
      {toConfetti ? <Confetti /> : null}

      <h2>{successMessage}</h2>

      { checkoutSuccess ?
        <div>
          <h4>Thank-you, you legend!  We’ve got it</h4>
          <br />
          <p id="celebrate-scroll">Keep an eye out for an order confirmation from us in your inbox.</p>
          <p>The wines will be with you soon!</p>
          <p>Now all that’s left to do, click to <span className="celebrate-btn" onClick={handleSetConfetti}>Celebrate?</span></p>
          <div id="celebrate-gif-container">
            <div id="celebrate-gif-container-inner">
              <img src={CelebrateGif} />
            </div>
          </div>
        </div>
        : null}

    </div>
  );

}

export default CheckoutFinishedPage;