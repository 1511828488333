export function addRank(items, idName, rankBy, rankName) {
    if (!everyItemHasField(items, rankBy)) {
        return null;
    }
    let itemsToDestroy = items.map(item => item);
    var itemsBare = reduceToBare(itemsToDestroy, idName, rankBy);
    var sortedBareItems = quickSort(itemsBare, rankBy);
    return addRanking(itemsToDestroy, idName, sortedBareItems, rankName);
}

export function quickSortBy(items, sortBy) {
    return everyItemHasField(items, sortBy) ? quickSort(items, sortBy) : null;
}

export function quickSortBySplit(items, sortBy) {
    let productsHaveField = [];
    let unsorted = [];
    //console.log(items);
    items.forEach(item => {
        if ( item[sortBy] !== null && item[sortBy] !== undefined ) {
            productsHaveField.push(item);
        } else {
            unsorted.push(item);
        }
    });
    const sortedProducts = quickSort(productsHaveField, sortBy);
    return {
        sorted: sortedProducts,
        unsorted
    };
}

function quickSort(items, sortBy) {
    return _quickSort(items, 0, items.length - 1, sortBy);
}

export function similarProducts(targetProduct, products) {
    const targetType = targetProduct.grapeType;
    const sameTypes = products.filter(product => {
        return product.grapeType === targetType;
    });
    // Get price difference
    const targetPrice = targetProduct.price;
    const sameTypesWithDifference = sameTypes.map(product => {
        let productWithDifference = product;
        const productPrice = product.price;
        const priceDifference = Math.abs(productPrice - targetPrice);
        productWithDifference.priceDifference = priceDifference;
        return priceDifference;
    });
    // Rank in order of lowest price difference
    const productsSorted = quickSortBy(sameTypesWithDifference, 'priceDifference');
    return productsSorted;
}
export function updateSingleProduct(products, updatedProduct) {
    if (!products || !updatedProduct || products.length < 1) {
        return null;
    }
    const prodLen = products.length;
    // Update each product
    const updatedProductId = updatedProduct.id;
    for (let i = 0; i < prodLen; i++) {
        const storageId = products[i].id;
        if (updatedProductId === storageId) {
            products[i] = updatedProduct;
            break;
        }
    }
    return products;
}
export function updateSingleProductByFields(products, updatedProduct) {
    if (!products || !updatedProduct || products.length < 1) {
        return null;
    }
    const prodLen = products.length;
    // Update each product
    const updatedProductId = updatedProduct.id;
    for (let i = 0; i < prodLen; i++) {
        const storageId = products[i].id;
        if (updatedProductId === storageId) {
            for (const [key, value] of Object.entries(updatedProduct)) {
                products[i][key] = value;
            }
            break;
        }
    }
    return products;
}

function swap(items, lfI, rhI) {
    var temp = items[lfI];
    items[lfI] = items[rhI];
    items[rhI] = temp;
}
function partition(items, lf, rh, rankBy) {
    var pv = items[Math.floor((rh + lf) / 2)][rankBy],
        i = lf,
        j = rh;
    while (i <= j) {
        while (items[i][rankBy] < pv) {
            i++;
        }
        while (items[j][rankBy] > pv) {
            j--;
        }
        if (i <= j) {
            swap(items, i, j);
            i++;
            j--;
        }
    }
    return i;
}

function _quickSort(items, lf, rh, rankBy) {
    var i;
    if (items.length > 1) {
        i = partition(items, lf, rh, rankBy);
        if (lf < i - 1) {
            _quickSort(items, lf, i - 1, rankBy);
        }
        if (i < rh) {
            _quickSort(items, i, rh, rankBy);
        }
    }
    return items;
}

function reduceToBare(items, idName, propName) {
    let obs = [];
    items.forEach(item => {
        let ob = {};
        ob[idName] = item[idName];
        ob[propName] = item[propName];
        obs.push(ob);
    });
    return obs;
}

function addRanking(items, idName, sortedBareItems, rankName) {
    items.forEach(item => {
        const id = item[idName];
        const i = sortedBareItems.findIndex(bareItem => {
            return bareItem[idName] === id;
        });
        item[rankName] = i + 1;
    });
    return items;
}

function everyItemHasField(items, itemKey) {
    if (typeof (items) !== 'object' || !Array.isArray(items)) {
        return false;
    }
    if (typeof (itemKey) !== 'string') {
        return false;
    }
    for (const item of items) {
        if (typeof (item) !== 'object' || !item[itemKey]) {
            return false;
        }
    }
    return true;
}