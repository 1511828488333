import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './setPickup.css';
import DatePicker from '../../../../../components/checkout/datePicker/datePicker';

import axios from 'axios';

let year = '2021';
let month = '2021';
let day = '2021';

class AdminOrderSetPickupPage extends Component {

    arrangeNewCollection = async () => {
        try {
            $('#responseContainer').text('');
            const adminToken = localStorage.getItem('adminToken');
            const url = '/api/admin/dhl/arrange-new-collection';

            //const $collectionDate = $('#collectionDate');
            const $earliestTime = $('#earliestTime');
            const $latestTime = $('#latestTime');

            //const collectionDate = $collectionDate.val();
            const collectionDate = year + '-' + month + '-' + day;
            const earliestTime = $earliestTime.val();
            const latestTime = $latestTime.val();

            const response = await axios({
                url,
                method: 'POST',
                data: { adminToken, collectionDate, earliestTime, latestTime }
            });
            if (response) {
                const { data } = response;
                if (data) {
                    const { dhlCollectionRequest, bookingMessage } = data;
                    localStorage.setItem('dhlCollectionRequest', JSON.stringify(dhlCollectionRequest));
                    $('#responseContainer').text(bookingMessage);
                }
                console.log('Data error');
            } else {
                console.log('API response error');
            }
            console.log('Fetch error');
            return null;
        } catch (error) {
            console.log(error);
            $('#responseContainer').text(error);
            if (error['response']) {
                if (error['response']['data']) {
                    if (error['response']['data']['message']) {
                        const errorMessage = error['response']['data']['message'];
                        $('#responseContainer').text(errorMessage);
                    }
                }
            }
        }
    }

    componentDidMount() {
        const unix_timestamp_ms = Date.now();
        const dateObj = new Date(unix_timestamp_ms);
        const yearToday = dateObj.getFullYear();
        let monthToday = dateObj.getMonth() + 1;
        monthToday = "0" + monthToday.toString();
        monthToday = monthToday.substr(-2);
        let dayToday = dateObj.getDate();
        dayToday = "0" + dayToday.toString();
        dayToday = dayToday.substr(-2);

        year = yearToday;
        month = monthToday;
        day = dayToday;

        console.log(yearToday);
        console.log(monthToday);
        console.log(dayToday);
    }

    getPreviousPickup = () => {
        console.log('Init render recent pickup');
        const dhlPreviousPickupString = localStorage.getItem('dhlCollectionRequest');
        if (!dhlPreviousPickupString) return;
        console.log('Found in storage');
        const dhlPreviousPickup = JSON.parse(dhlPreviousPickupString);
        if (!dhlPreviousPickup) return;
        console.log('Parsed');

        const unix_timestamp_ms = Date.now();
        const dateObj = new Date(unix_timestamp_ms);
        const year = dateObj.getFullYear();
        let month = dateObj.getMonth() + 1;
        month = "0" + month.toString();
        month = month.substr(-2);
        let day = dateObj.getDate();
        day = "0" + day.toString();
        day = day.substr(-2);
        const todayFormatted = `${year}-${month}-${day}`;

        const todayToCompare = Date.parse(todayFormatted);
        console.log('Today: ' + todayFormatted);
        const collectionToCompare = Date.parse(dhlPreviousPickup.collectionDate);
        console.log('Last collection: ' + dhlPreviousPickup.collectionDate);

        let todayMessage = '';
        let dayClassName = 'text-green';
        if (todayToCompare === collectionToCompare) {
            todayMessage = 'TODAY';
        } else if (todayToCompare > collectionToCompare) {
            const differenceMs = collectionToCompare - todayToCompare;
            const differenceS = Math.floor(differenceMs / 1000);
            const days = Math.round(differenceS / 86400);
            const pluralS = days > 1 ? 's' : '';
            todayMessage = days + ' day' + pluralS + ' ago';
            dayClassName = 'text-red';
        } else {
            const differenceMs = collectionToCompare - todayToCompare;
            const differenceS = Math.floor(differenceMs / 1000);
            const days = Math.round(differenceS / 86400);
            const pluralS = days > 1 ? 's' : '';
            todayMessage = 'In ' + days + ' day' + pluralS;
        }

        const collectionJobNumber = dhlPreviousPickup.collectionJobNumber || '';

        return (
            <div className="dhl-arrange-table-container">
                <hr />
                <br />
                <br />
                <h6>Most recent DHL collection arranged:</h6>
                <p className={dayClassName}><strong>{todayMessage}</strong></p>
                <p><strong>Collection Job Number:</strong> {collectionJobNumber}</p>
                <br />
                <br />
                <table className="dhl-arrange-table">
                    <thead>
                        <tr>
                            <td><b><u>Date Today</u></b></td>
                            <td><b><u>Nearest Collection Date</u></b></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{todayFormatted}</td>
                            <td className={dayClassName}><strong>{dhlPreviousPickup.collectionDate}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    dateChanged = (dateUnix) => {
        dateUnix = Math.floor(dateUnix / 86400);
        dateUnix *= 86400;
        console.log('unix', dateUnix);
        const dateObject = new Date(dateUnix * 1000);
        const dayChange = dateObject.toLocaleString('en-GB', { day: "numeric" });
        const monthChange = dateObject.toLocaleString('en-GB', { month: "numeric" });
        const yearChange = dateObject.toLocaleString('en-GB', { year: "numeric" });

        let monthNew = "0" + monthChange.toString();
        monthNew = monthNew.substr(-2);
        let dayNew = "0" + dayChange.toString();
        dayNew = dayNew.substr(-2);

        year = yearChange;
        month = monthNew;
        day = dayNew;

        console.log('year', year);
        console.log('month', month);
        console.log('day', day);
    }

    render() {

        const adminToken = localStorage.getItem('adminToken');
        if (!adminToken) {
            console.log('redirect');
            window.location.href = '/admin-archie-96/login';
        } else {
            console.log('got token:');
        }

        return (
            <div className="admin-pages containerFluidMedium">
                <p><Link to="/admin-archie-96/dashboard/">Back to dashboard</Link></p>

                {this.getPreviousPickup()}

                <br />
                <br />
                <hr />
                <br />

                <h6>Arrange new collection date:</h6>

                <p id="responseContainer"></p>

                <div>
                    <h6></h6>
                    <div className="rel">
                        <p><b>Pick a date:</b></p>
                        <DatePicker returnDate={this.dateChanged} />
                    </div>
                    <br />
                    <br />

                    <p><b>Pick a time window:</b></p>
                    <div className="pickup-times">
                        <div className="pickup-time">
                            <label htmlFor="earliestTime"><b>Earliest (24hr):</b></label><br />
                            <input id="earliestTime" type="text" defaultValue="14:00"></input>
                        </div>

                        <div className="pickup-time">
                            <label htmlFor="latestTime"><b>Latest (24hr):</b></label><br />
                            <input id="latestTime" type="text" defaultValue="17:00"></input>
                        </div>
                    </div>

                    <br />
                    <button type="text" onClick={this.arrangeNewCollection}>ARRANGE</button>
                </div>

            </div>
        );
    }

}

export default AdminOrderSetPickupPage;