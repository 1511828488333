import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './whoWeAre.css';
import { updateNav } from '../../../utils/updateActivePage';
import wineImage from '../../../materials/images/image8.png';
import winePourImage from '../../../materials/images/image3.png';
import bottles from '../../../materials/images/BOTTLE COLLECTION.jpg';

class WhoWeAre extends Component {

    render() {
        updateNav();
        updateNav();
        const suggestionWine = <Link to="/shop/product/Valpolicella-Superiore">Marion, Valpolicella</Link>;
        return (
            <div className="whoWeArePage spanHighlight">

                <img id="whoWeAreImg1mob" src={wineImage} title="wine" alt="wine"></img>

                <div className="containerFluidNarrow">
                    <div id="whoWeAreDiv1" className="paperWhiteAngled lightenBg standardFontResponsive mob-padt-8">
                        <img id="whoWeAreImg1" src={wineImage} className="leftAlignImage" title="wine" alt="wine"></img>
                        <h4 id="whoWeAreHeader">Carafe - Who we are;</h4>
                        <p>Meet Carafe, your new best friend.</p>
                        <p>Hard day at work and want the perfect wine? How about {suggestionWine}, a lovely juicy rich crushed black fruit number that will taste even better whilst you supress that witty remark you wanted to give your colleague after she told your boss that you were 5 MiNUtEs late!!!</p>
                        <p>When actually, she was 15 minutes late last week and you said bugger all. And if she puts fish in the staff microwave again… you swear to god… Just lay back and take a sip… you got this. Taste the body on that bad boy, a body as big as your potential that is.</p>
                        <p>And for once in a month you are on top of your emails, hooray for you, it’s the little things. No more adulting today. Pour yourself a bigger glass this time, you got this. Do you get that lovely earthy spice on the finish? Yeah. We got you.</p>
                        <p>In fact there’s more where that came from, let us be your fairy godmother of wine. Drinks for all occasions, and after that absolute bull of a year, I think we all need a bit of medicating.</p>
                    </div>
                </div>

                <img id="whoWeAreImg2mob" src={winePourImage}  title="wine" alt="wine"></img>

                <div className="containerFluidNarrow">
                    <div className="paperBrown paperBrownTighter lightenBgMore whoWeAreCharity standardFontResponsive">
                        <div className="py5">
                            <img id="whoWeAreImg2" src={winePourImage} className="rightAlignImage" title="wine" alt="wine"></img>
                            <p>Also, feel good about indulging at this adults playground; we are part of the <a href="https://www.onepercentfortheplanet.org/" target="_blank" rel="noreferrer">“1% for the Planet”</a> movement. This means at minimum 1% of all annual revenue from Carafe will go to environmental <Link to="/help/charity">charities</Link>. So drink up dudes, feel better at having a glass of wine because you are a saint. You’re giving back, WOW (Owen Wilson voice).</p>
                            <p>We think Corporate Social Responsibility is so sexy, that’s why on top of this we have nominated <Link to="/help/charity">Charity wines</Link> each month where 25% of all the profits of all sales will go to our monthly charity - all ready to fill up your basket guilt free!</p>
                        </div>
                    </div>
                </div>

                <img src={bottles} id="whoWeAreImg3mob" title="wine" alt="wine"></img>

                <div className="containerFluidNarrow">
                    <div className="paperOffWhite lightenBgMore standardFontResponsive mob-padt-5">
                        <img id="whoWeAreImg3" src={bottles} className="leftAlignImage" title="wine" alt="wine"></img>
                        <p>Tell you what else is fantastically attractive? <Link to="/help/sustainability">Sustainability</Link>, I know... you were thinking it too, we are so *NSYNC, so This I promise you... all of our packaging is recyclable! We also strive to use only small producers so that we don't put any more money into the billionaires pockets. </p>
                        <p>We just sell really good juice. We like to sell traditional old world wines such as St.Emilion Bordeaux’s and Mâcon-Village Bourgogne alongside new world wines including Tokaj Furmint and Australian Pet Nats. It's 2021, let’s just shove old world and new world in the same room, they can behave. It’s our party, we control the guest list. Indulge.</p>
                        <p>And like all good friends, we will tell you some good gossip about your bottle so you have something to spout at your next social drinks and sound fancy. <b>Drinks for friends, by friends.</b> That’s us, great to make your <Link to="/contact">e-cquaintance</Link>.</p>
                    </div>
                </div>

            </div>
        )
    }
}

export default WhoWeAre;