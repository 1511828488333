import React, { useState } from "react";
import DatePicker from "react-datepicker";
import TodayIcon from '@material-ui/icons/Today';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import enGb from 'date-fns/locale/en-GB';
import "react-datepicker/dist/react-datepicker.css";
import './datePicker.css';
registerLocale('en-GB', enGb);
setDefaultLocale(enGb);

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DatePickerComp = ({ returnDate }) => {
  const [startDate, setStartDate] = useState(new Date());

  const returnDateHandler = (date) => {
    if (returnDate) {
      const dateToUnix = Math.floor(Date.parse(date) / 1000);
      returnDate(dateToUnix);
    }
    setStartDate(date);
  }



  return (
    <div className="davePickerRel">
      <DatePicker
        className="datePickerInput"
        locale="en-GB"
        dateFormat="dd/MM/yyyy"
        selected={startDate}
        onChange={date => returnDateHandler(date)}
      />
      <div className="todayIconContainer">
        <TodayIcon style={{ color: "#999" }} />
      </div>
    </div>
  );
};

export default DatePickerComp;