import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import FormInput from '../../../components/generic/formInput/formInput';
import SubmitButton from '../../../components/generic/submitButton/submitButton';
import { pass } from '../../../utils/passFormat';
import './resetPassword.css';

const ResetPasswordPage = ({ resetToken }) => {

    const onSubmit = async (evt) => {

        evt.preventDefault();
        disableSubmit();
        const $password = $('#password');
        const $password2 = $('#password2');
        const password = $password.val();
        const password2 = $password2.val();

        if (password !== password2) {
            console.log('Passwords do not match');
            $('.errorMessage').text('Passwords do not match');
            $('.errorMessage').addClass('show');
            enableSubmit();
            return;
        }
        if (!pass(password)) {
            console.log('Password not strong enough');
            $('.errorMessage').text('Password not strong enough');
            $('.errorMessage').addClass('show');
            enableSubmit();
            return;
        }
        if (!resetToken || resetToken.length !== 40) {
            console.log('Token error');
            $('.errorMessage').text('Token error');
            $('.errorMessage').addClass('show');
            enableSubmit();
            return;
        }
        // Pass
        //console.log('Sending email to api');
        try {
            const url = '/api/accounts/reset-password';
            const response = await axios({
                url: url,
                method: 'POST',
                data: { password, password2, resetToken }
            });
            const { data } = response;
            const { success } = data;
            if (success) {
                $('.submitButton').text('Password reset');
                $('.errorMessage').removeClass('show');
            } else {
                enableSubmit();
            }
        } catch (error) {
            console.log(error);
            enableSubmit();
        }
    }

    const disableSubmit = () => {
        $('.submitButton').attr("disabled", true);
    }
    const enableSubmit = () => {
        $('.submitButton').removeAttr("disabled");
    }

    return (
        <div className="resetPasswordPage containerFluidNarrow paperOffWhite paperOffWhiteLooser">
            <h5>Reset password</h5>
            <form onSubmit={onSubmit}>
                <FormInput id="password" placeholder="Password" type="password" required={true} />
                <FormInput id="password2" placeholder="Confirm password" type="password" required={true} />
                <SubmitButton onClick={onSubmit} />
            </form>
            <p className="errorMessage">Passwords must match</p>
        </div>
    )
}

export default ResetPasswordPage;