import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './delivery.css';

class DeliveryPage extends Component {
    //If you are lucky enough to live abroad from England we will have alternate delivery fees and timings, quotes will be generated when you checkout with your order. Please note at present we cannot deliver to Europe due to Brexit negotiations, damn politics messing with our wine delivery! We can however deliver further afield to countries such as Australia and the USA - the quotes for these deliveries will be generated at the checkout when purchasing. 
    render() {
    updateNav();
        return (
            <div className="deliveryPage containerFluid containerFluidMedium">
                <div className="paperOffWhite standardFontResponsive lightenBgMore linkHighlight">
                    <h4 className="centeredHeader">Delivery</h4>
                    <p>Oh yes, I am sure you are dreading this page after so many exorbitant delivery charges thinking “Okay, everything sounds good so far - what’s the catch? It must be delivery charges!” <br />
                    Happy to prove you wrong. </p>

                    <p>To look after our neighbours and as a nice little ‘Welcome to Carafe’ <b>we are granting free delivery for all first time orders in England</b> when you sign up for an account, so get on it lads and lassies!</p>

                    <p><em>“I got 5 on it”</em><br />
                    Once you’ve used your freebie, for all other orders we offer £5.99 delivery for all around England woo-hoo! Our fees are like Luniz and our delivery times are quicker than Craig David - no 7 Days wait for your order. Our lead delivery time is 1-3 working days. We will try to work magic and deliver the next day depending on when our last collection is, however if not sleep soundly knowing its only one more sleep until the wine arrives. </p>

                    <p>If you reside more than 6 miles out of postal code TN12 we will use our trusty delivery partner DHL to transport your wine, they will provide you with a tracking number so you can keep updated on your wines travels.</p>

                    <p>If you reside local to TN12 we will deliver the wine ourselves so no tracking number as it will go directly from Carafe HQ to your home. If you have special delivery requirements such as timings unavailable for delivery or a certain entrance you would like us to go to, please let us know in the delivery notes or send a message to <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk</a> with your order number in the subject line. </p>

                    <p>If you are lucky enough to live abroad from the UK, I am afraid at present we cannot deliver to you. We are in the works to get this going so hopefully within a month we can get wine across the water to you! Thank You so much for your interest - won’t be long. It’s coming!”</p>

                    <p>Any other questions drop a line at <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk</a></p>
                </div>
            </div>
        );
    }
};

export default DeliveryPage;