import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './picksOfTheWeek.css';

class PicksOfTheWeekPage extends Component {
    render() {
updateNav();
        return (
            <div>
                <h4>Wine picks of the week</h4>
                <p>Vicki:</p>
                <p>Alice:</p>
                <p>Bob:</p>
            </div>
        );
    }
};

export default PicksOfTheWeekPage;