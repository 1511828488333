/*
function handleWord(word, targetChar, classToAdd, plainChar) {
    // Generate replacement html surrounding targetChars
    const fix = `<span class="${classToAdd}">${plainChar}</span>`;
    while (word.includes(targetChar)) {
        // Keep mutating string until all targetChars are replaced with fix html
        word = word.replace(targetChar, fix);
    }
    return word;
}
*/

/*
function handleWordToElement(word, targetChar, classToAdd, plainChar) {
    // Generate replacement html surrounding targetChars
    const fix = <span class={classToAdd}>{plainChar}</span>;
    while (word.includes(targetChar)) {
        // Keep mutating string until all targetChars are replaced with fix html
        word = word.replace(targetChar, fix);
    }
    return word;
}
*/


/*
function modifyChar(char, targetChar, classToAdd, plainChar) {
    // Generate replacement html surrounding targetChars
    console.log(`Comparing: ${char} ${targetChar}`);
    if (char === targetChar) {
        return <span class={classToAdd}>{plainChar}</span>
    }
    return char;
}
*/

/*
function formatAccents(rawTitle) {
    const accentClass = 'accent';
    const circumflexClass = 'circumflex';
    // Object for targeting and replacing chars
    const accentObjects = [
        { targetChar: 'é', plainChar: 'e', classToAdd: accentClass },
        { targetChar: 'ô', plainChar: 'o', classToAdd: circumflexClass },
        { targetChar: 'â', plainChar: 'a', classToAdd: circumflexClass },
    ];
    // Convert to array of words
    const titleRawArray = rawTitle.split(' ');
    // Check each word and return fixed word with no accents etc
    const titleModArray = titleRawArray.map(word => {
        let changedWord = word;
        accentObjects.forEach(accentObj => {
            if (changedWord.includes(accentObj.targetChar)) {
                //
                const { targetChar, classToAdd, plainChar } = accentObj;
                changedWord = handleWord(changedWord, targetChar, classToAdd, plainChar);
            }
        });
        const wrapperClass = 'wordBlock';
        if (word === changedWord) {
            // No characters changed
            return word;
        } else {
            // Wrap word in span to prevent wrapping
            return `<span class="${wrapperClass}">${changedWord}</span>`;
        }
    });
    // Array to string
    const titleModified = titleModArray.join(' ');
    return titleModified;
}
*/


function formatAccentsToElementArray(name) {
    const accentClass = 'accent';
    const circumflexClass = 'circumflex';
    const doubleDotsClass = 'doubleDots';
    // Object for targeting and replacing chars
    const accentObjects = [
        { targetChar: 'é', plainChar: 'e', classToAdd: accentClass },
        { targetChar: 'è', plainChar: 'e', classToAdd: accentClass },
        { targetChar: 'ô', plainChar: 'o', classToAdd: circumflexClass },
        { targetChar: 'â', plainChar: 'a', classToAdd: circumflexClass },
        { targetChar: 'ä', plainChar: 'a', classToAdd: doubleDotsClass },
        { targetChar: 'ü', plainChar: 'u', classToAdd: doubleDotsClass }
    ];
    let containsAccents = false;
    for (let accentObject of accentObjects) {
        if (name.includes(accentObject.targetChar)) {
            containsAccents = true;
            break;
        }
    }
    if (!containsAccents) {
        return name;
    }
    // Convert to array of words
    const titleRawArray = name.split(' ');
    // Check each word and return fixed word with no accents etc
    let wordCount = 0;
    const newWords = titleRawArray.map(word => {
        let newChars = [];
        for (let i = 0; i < word.length; i++) {
            // Each Char
            let changed = false;
            const char = word[i];
            let letterCount = 0;
            for (let j = 0; j < accentObjects.length; j++) {
                // Check char against every possible accent char
                const accentObj = accentObjects[j];
                const { targetChar, classToAdd, plainChar } = accentObj;
                if (char === targetChar) {
                    // Match found, change
                    changed = true;
                    newChars.push(<span key={'titleLetter'+wordCount+'_'+letterCount} className={classToAdd}>{plainChar}</span>);
                    break;
                }
                letterCount++;
            }
            // Finished looping through accents for this char
            if (!changed) {
                // Valid plain character
                newChars.push(char);
            }
            wordCount++;
        }
        const wrapperClass = 'wordBlock';
        const newWord = newChars.join('');
        if (word === newWord) {
            // No characters changed
            return word + ' ';
        } else {
            // Wrap word in span to prevent wrapping
            return <span key={'titleWord'+wordCount} className={wrapperClass}>{newChars}&nbsp;</span>;
        }
    });
    return newWords;
}

const accentFuncs = { formatAccentsToElementArray };

export default accentFuncs;

//module.exports = accentFuncs;

/*
module.exports = {
    formatAccents: (rawTitle) => {
        const accentClass = 'accent';
        const circumflexClass = 'circumflex';
        // Object for targeting and replacing chars
        const accentObjects = [
            { targetChar: 'é', plainChar: 'e', classToAdd: accentClass },
            { targetChar: 'ô', plainChar: 'o', classToAdd: circumflexClass },
            { targetChar: 'â', plainChar: 'a', classToAdd: circumflexClass },
        ];
        // Convert to array of words
        const titleRawArray = rawTitle.split(' ');
        // Check each word and return fixed word with no accents etc
        const titleModArray = titleRawArray.map(word => {
            let changedWord = word;
            accentObjects.forEach(accentObj => {
                if (changedWord.includes(accentObj.targetChar)) {
                    //
                    const { targetChar, classToAdd, plainChar } = accentObj;
                    changedWord = handleWord(changedWord, targetChar, classToAdd, plainChar);
                }
            });
            const wrapperClass = 'wordBlock';
            if (word === changedWord) {
                // No characters changed
                return word;
            } else {
                // Wrap word in span to prevent wrapping
                return `<span class="${wrapperClass}">${changedWord}</span>`;
            }
        });
        // Array to string
        const titleModified = titleModArray.join(' ');
        return titleModified;
    },
    formatAccentsToElement: (name) => {
        const accentClass = 'accent';
        const circumflexClass = 'circumflex';
        // Object for targeting and replacing chars
        const accentObjects = [
            { targetChar: 'é', plainChar: 'e', classToAdd: accentClass },
            { targetChar: 'ô', plainChar: 'o', classToAdd: circumflexClass },
            { targetChar: 'â', plainChar: 'a', classToAdd: circumflexClass },
        ];
        // Convert to array of words
        const titleRawArray = name.split(' ');
        // Check each word and return fixed word with no accents etc
        const titleModArray = titleRawArray.map(word => {
            let changedWord = word;
            accentObjects.forEach(accentObj => {
                if (changedWord.includes(accentObj.targetChar)) {
                    //
                    const { targetChar, classToAdd, plainChar } = accentObj;
                    changedWord = handleWordToElement(changedWord, targetChar, classToAdd, plainChar);
                }
            });
            const wrapperClass = 'wordBlock';
            if (word === changedWord) {
                // No characters changed
                return word;
            } else {
                // Wrap word in span to prevent wrapping
                return <span class={wrapperClass}>{changedWord}</span>;
            }
        });
        // Array to string
        const titleModified = titleModArray.join(' ');
        return titleModified;
    },
}
*/