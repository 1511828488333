import { Link } from 'react-router-dom';
import $ from 'jquery';
import './summaryAddress.css';

const SummaryAddress = ({ address, deliveryAdditionalNotes }) => {
    const { fullName, address1, address2, city, county, country, email } = address;
    return (
        <div>
            { fullName ? <p>{fullName}</p> : null }
            { address1 ? <p>{address1}</p> : null }
            { address2 ? <p>{address2}</p> : null }
            { city ? <p>{city}</p> : null }
            { county ? <p>{county}</p> : null }
            { country ? <p>{country}</p> : null }
            <br/>
            { email ? <p id="stripe-email">{email}</p> : null }
            { deliveryAdditionalNotes ? <p>Notes:<br />{deliveryAdditionalNotes}</p> : null }
        </div>
    );
}

export default SummaryAddress;