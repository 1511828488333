import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './cookiepolicy.css';

class CookiePolicyPage extends Component {
    render() {
updateNav();
        return (
            <div>
                <h4>Cookie Policy</h4>
                <p>We track you with cookies etc</p>
            </div>
        );
    }
};

export default CookiePolicyPage;