import React, { Component } from 'react';
import AddressInputBilling from '../addressInputBilling/addressInputBilling';
import './billingForm.css';

export default class BillingForm extends Component {

    render() {
        const { billingAddress, updateCheckoutAddress, updateCheckoutAddressWhole, email, toDisable, token } = this.props;
        return (
            <div className="deliveryForm">
                <div className="addressSplit">
                    <AddressInputBilling 
                     billingAddress={billingAddress} 
                     updateCheckoutAddress={updateCheckoutAddress} 
                     updateCheckoutAddressWhole={updateCheckoutAddressWhole}
                     email={email} 
                     disabled={toDisable}
                     token={token}
                    />
                </div>
            </div>
        );
    }

}