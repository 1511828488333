import React, { Component } from 'react';
import { csvToJson } from '../../../../../utils/csvUtils';
import $ from 'jquery';
import axios from 'axios';
import './decrypt.css';

class AdminMailingListDecryptPage extends Component {

    returnJsonTocreen = () => {
        const csv = $('#csv').val();
        const delimiter = $('#delimiter').val() || ',';
        try {
            const response = csvToJson(csv, delimiter);
            if (response) {
                $('#json').text(JSON.stringify(response));
            }
        } catch (error) {
            console.log(error);
        }
    }

    sendToApi = async () => {
        const $email = $('#email');
        const email = $email.val().toLowerCase();
        const $password = $('#password');
        const password = $password.val();
        const url = '/api/decrypt/mailing-list/';
        const response = await axios({
            url,
            method: 'POST',
            data: { email, password }
        });
        const $outputDiv = $('#outputDiv');
        if (response) {
            if (response.data.success) {
                $email.val('');
                $password.val('');
                const { emails, plainEmails } = response.data;
                $outputDiv.slideDown();
                const $outputUl = $('#outputUl');

                if (plainEmails) {
                    const blankPlainElem = `<li><b>Recent plain:</b></li>`;
                    $outputUl.append(blankPlainElem);
                    plainEmails.forEach(email => {
                        const elem = `<li>${email}</li>`;
                        $outputUl.append(elem);
                    });
                    const blankDecElem = `<li><b>Old / decrypted:</b></li>`;
                    $outputUl.append(blankDecElem);
                }

                emails.forEach(email => {
                    const elem = `<li>${email}</li>`;
                    $outputUl.append(elem);
                });


            }
        } else {
            console.log('API response error');
        }
    }

    componentDidMount() {
        const $outputDiv = $('#outputDiv');
        $outputDiv.hide();
    }


    render() {

        return (
            <div className="testPage containerFluidMedium">
                <div>
                    <h6>Hey Vicki !</h6>
                    <p>Please enter your email and password below, and hopefully it works.</p>
                </div>

                <div>
                    <h6>Login:</h6>
                    <input id="email" type="text" placeholder="Email"></input><br />
                    <input id="password" type="password" placeholder="Password"></input><br />
                    <br />
                    <button onClick={this.sendToApi}>Login</button>
                </div>

                <br />
                <br />

                <hr />

                <br />
                <br />

                <div id="outputDiv">
                    <h6>Huzzah... Mailing List, maybe...:</h6>
                    <ul id="outputUl">

                    </ul>
                </div>


            </div>
        )
    }

}

export default AdminMailingListDecryptPage;