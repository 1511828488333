import { isNull, isUndefined } from "lodash";

export function addRemoveBasket(basket, product, quantity, set) {
  const quantityIsNum = typeof (quantity) === 'number';
  if (!basket || !product || !quantityIsNum) {
    return null;
  }
  let isUnique = true;
  let zeroQuantityIndex = -1;
  // Check for duplicates
  for (let i = 0; i < basket.productLines.length; i++) {
    if (basket.productLines[i].id === product.id) {
      if (set) {
        // Overwrite/set quantity
        basket.productLines[i].quantity = quantity;
      } else {
        // Increment/decrement
        basket.productLines[i].quantity += quantity;
      }
      isUnique = false;
      if (basket.productLines[i].quantity < 1) {
        zeroQuantityIndex = i;
      }
      break;
    }
  }
  if (isUnique) {
    // Add unique product
    const newBasketProduct = {
      id: product.id, 
      quantity,
      name: product.name, 
      grapeType: product.grapeType, 
      country: product.country, 
      producer: product.producer,
      price: product.price,
      stock: product.stock,
      nameNoWhSpc: product.nameNoWhSpc
    }
    basket.productLines.push(newBasketProduct);
  } else {
    // Remove empty product
    if (zeroQuantityIndex > -1) {
      basket.productLines.splice(zeroQuantityIndex, 1);
    }
  }
  return basket;
}

export function addRemoveGiftWrap(basket, product, quantity) {
  const quantityIsNum = typeof (quantity) === 'number';
  if (!basket || !product || !quantityIsNum) {
    return null;
  }
  // Check for duplicates
  for (let i = 0; i < basket.productLines.length; i++) {
    if (basket.productLines[i].id === product.id) {
      basket.productLines[i].giftQuantity = quantity;
      break;
    }
  }
  return basket;
}

export function addDiscountCodeToBasket(basket, discountObj, forDelivery) {
  // Discount codes, not multiBuy
  if (!basket || !discountObj || !forDelivery) {
    return null;
  }
  const { discountCode } = discountObj;
  if (!discountCode) {
    return null;
  }
  if (forDelivery) {
    // Delivery discounts
    basket.deliveryDiscountApplied = discountObj;
  } else {
    // Product discounts
    basket.productDiscountApplied = discountObj;
  }
  return basket;
}

export function liteBasketToFullUtil(liteBasket, stateMultiBuyDiscounts, stateProducts, stateKnotWraps, stateGiftBoxes, canMaybeHaveFreeFirstOrderDelivery) {
  let fullBasket = {};

  //console.log('liteBasket',liteBasket);
  
  const { productLines } = liteBasket;

  // Products
  let productSubtotal = 0;
  let productQuantity = 0;
  const fullProducts = productLines.map(liteProd => {
    for (const stateProd of stateProducts) {
      if (stateProd.id === liteProd.id) {
        const { quantity } = liteProd;
        productQuantity += Math.min(stateProd.stock, quantity);
        const minStockQuantity = Math.min(stateProd.stock, quantity);
        const productDetailsLite = {
          id: stateProd.id, 
          quantity: minStockQuantity,
          name: stateProd.name, 
          grapeType: stateProd.grapeType, 
          country: stateProd.country, 
          producer: stateProd.producer,
          price: stateProd.price,
          stock: stateProd.stock,
          nameNoWhSpc: stateProd.nameNoWhSpc
        }
        productSubtotal += quantity * stateProd.price;
        return productDetailsLite;
      }
    }
  });
  fullBasket.productLines = fullProducts || [];
  fullBasket.productSubtotal = productSubtotal;

  // Discounts
  let bestSavingPercent = 0;
  const multiBuyObjsLen = stateMultiBuyDiscounts.length;
  for (let i=0; i<multiBuyObjsLen; i++) {
    // Get best rate
    const multiBuyDiscount = stateMultiBuyDiscounts[i];
    const { minQuantity, savingPercent } = multiBuyDiscount;
    if (productQuantity >= minQuantity && savingPercent > bestSavingPercent && savingPercent <= 100) {
      // Best we can find so far
      bestSavingPercent = parseInt(savingPercent);
    }
  }
  const multiplier = (100 - bestSavingPercent) / 100;
  const subtotalWithDiscount = Math.floor(productSubtotal * multiplier);
  fullBasket.savingWithDiscount = productSubtotal - subtotalWithDiscount;
  fullBasket.subtotalWithDiscount = subtotalWithDiscount;

  fullBasket.subtotalWithProductCode = subtotalWithDiscount;

  // Product codes
  const { productDiscountApplied } = liteBasket;
  if (productDiscountApplied) {
    const { code, savingPercent, nullWithMultiBuy, message, forDelivery } = productDiscountApplied;
    if (code && !forDelivery) {
      if (bestSavingPercent && nullWithMultiBuy) {
        fullBasket.productDiscountApplied = null;
        fullBasket.savingWithProductCode = 0;
        fullBasket.subtotalWithProductCode = fullBasket.subtotalWithDiscount;
      } else {
        const strippedObject = { code, savingPercent, nullWithMultiBuy, message };
        fullBasket.productDiscountApplied = strippedObject;
        const productCodeMultiplier = (100 - savingPercent) / 100;
        const subtotalWithProductCode = Math.floor(subtotalWithDiscount * productCodeMultiplier);
        fullBasket.savingWithProductCode = subtotalWithDiscount - subtotalWithProductCode;
        fullBasket.subtotalWithProductCode = subtotalWithProductCode;
      }
    }
  }

  // Gift wraps
  const giftWrapIds = liteBasket.giftWrapIds || [];
  //console.log()
  const giftWrapIdLen = giftWrapIds.length;
  let giftWrapIdsNew = [];
  let giftWrapPrice = 0;
  for (let i=0; i<giftWrapIdLen; i++) {
    // Get obj in basket array
    const giftWrapId = giftWrapIds[i];
    const giftWrapStateObj = stateKnotWraps[giftWrapId];
    if (giftWrapStateObj) {
      const { name, price } = giftWrapStateObj;
      //const newGifWrapObj = { id: giftWrapId, name, price };
      giftWrapPrice += price;
      giftWrapIdsNew.push(giftWrapId);
    }
  }
  //console.log(fullBasket.subtotalWithProductCode);
  
  fullBasket.giftWrapIds = giftWrapIdsNew;
  //console.log(giftWrapPrice);
  fullBasket.giftWrapCost = giftWrapPrice;
  fullBasket.subtotalWithGiftWrap = fullBasket.subtotalWithProductCode + giftWrapPrice;
  //console.log(fullBasket.subtotalWithGiftWrap);

  // Gift boxes
  const giftBoxes = liteBasket.giftBoxes || [];
  const giftBoxesLen = giftBoxes.length;
  let giftBoxesNew = [];
  let giftBoxesPrice = 0;
  for (let i=0; i<giftBoxesLen; i++) {
    // Get obj in basket array
    const giftBox = giftBoxes[i];
    const giftBoxId = giftBox['id'] || 0;
    const giftBoxStateObj = stateGiftBoxes[giftBoxId];
    if (giftBoxStateObj) {
      const { name, price, maxBottleCount, products } = giftBoxStateObj;
      const newGifBoxObj = { name, price, maxBottleCount, products, id: giftBoxId };
      giftBoxesPrice += price;
      giftBoxesNew.push(newGifBoxObj);
    }
  }
  fullBasket.giftBoxes = giftBoxesNew;
  fullBasket.giftBoxCost = giftBoxesPrice;
  fullBasket.subtotalWithGiftBoxes = fullBasket.subtotalWithGiftWrap + giftBoxesPrice;

  // Delivery codes
  const { deliveryDiscountApplied } = liteBasket;
  const baseEnglandDeliveryRate = 599;
  fullBasket.deliveryCost = baseEnglandDeliveryRate;
  if (canMaybeHaveFreeFirstOrderDelivery) {
    //console.log('Can have free first order delivery')
    // Check country is valid for free mainland
    fullBasket.deliveryCost = 0;
  } else if (deliveryDiscountApplied) {
    //console.log('Got delivery discount code')
    const { code, savingPercent, message, forDelivery } = deliveryDiscountApplied;
    if (forDelivery) {
      //console.log('Is for delivery')
      // Check country is valid
      const strippedObject = { code, savingPercent, message, forDelivery };
      fullBasket.deliveryDiscountApplied = strippedObject;
      //console.log('Saving: ' + savingPercent + '%');
      const deliveryCodeMultiplier = (100 - savingPercent) / 100;
      //console.log('Multiplier: ' + deliveryCodeMultiplier)
      const newDeliveryRate = Math.floor(baseEnglandDeliveryRate * deliveryCodeMultiplier);
      //console.log('Delivery cost: ' + newDeliveryRate);
      fullBasket.deliveryCost = newDeliveryRate;
    }
  }
  fullBasket.totalWithDelivery = fullBasket.subtotalWithGiftBoxes + fullBasket.deliveryCost;

  return fullBasket;
}

export function workOutMultiDiscounts(basket, multiBuyDiscounts) {
  /**
   * 
   * basket = {
   *    products: [
   *      { id, quantity, price },
   *      ...
   *    ]
   * }
   * 
   * multiBuyDiscounts = [
   *    { minQuantity, savingPercent }
   *    ...
   * ]
   * 
   * 
   */

  // Product data
  let totalWithoutSaving = 0;
  let totalQuantity = 0;
  basket.productLines.forEach(productLine => {
    // Subtotal without multiBuy discounts
    const lineWithoutSaving = productLine.quantity * productLine.price;
    totalWithoutSaving += lineWithoutSaving;
    totalQuantity += productLine.quantity;
  });

  //console.log('totalQuantity',totalQuantity);

  // Discount data
  let bestAvailablePercentSaving = 0;
  multiBuyDiscounts.forEach(multiBuyDiscount => {
    // Get best rate
    const { minQuantity, savingPercent } = multiBuyDiscount;
    //console.log('minQuantity',minQuantity);
    //console.log('savingPercent',savingPercent);
    if (totalQuantity >= minQuantity && savingPercent > bestAvailablePercentSaving && savingPercent <= 100) {
      // Best we can find so far
      bestAvailablePercentSaving = parseInt(savingPercent);
    }
  });

  //console.log('bestAvailablePercentSaving',bestAvailablePercentSaving);

  // Now got best rate e.g. 5 => 5% saving, turn into price*0.95
  const multiplier = (100 - bestAvailablePercentSaving) / 100;
  const totalWithSaving = bestAvailablePercentSaving ? Math.floor(totalWithoutSaving * multiplier) : totalWithoutSaving;

  basket.productSubtotal = totalWithoutSaving;
  basket.subtotalWithDiscount = totalWithSaving;

  // Return updated object 
  return basket;

  /**
   * basket = {
   *    products: [
   *      product: { id, price, ... },
   *      quantity: 5
   *      giftQuantity: ...
   *      ...
   *    ],
   *    productSubtotal: 10,000
   *    subtotalWithDiscount: 9,500,
   *    deliveryDiscountsApplied: [ ... ],
   *    ...
   * }
   */
}