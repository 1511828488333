import React from 'react';
import $ from 'jquery';
import './over18modal.css';

class Over18Modal extends React.Component {

    preventDefaultForm = (evt) => {
        //console.log('preventing...');
        evt.preventDefault();
        //console.log('...prevented.');
    }

    cancel = (evt) => {
        this.preventDefaultForm(evt);
        //console.log('cancel');
        window.history.back();
    }

    accept = (evt) => {
        this.preventDefaultForm(evt);
        //console.log('accept');
        const unixNow = Math.floor(Date.now()/1000);
        localStorage.setItem('over18unix', unixNow);
        $('.App').removeClass('stop-scrolling');
        $('body, html').removeClass('stop-scrolling');
        $('body').unbind('touchmove', function(e){e.preventDefault()});
        $('.over18modal').remove();
        window.location.href = '/';
    }


    render() {

        $('.App').addClass('stop-scrolling');
        $('body, html').addClass('stop-scrolling');
        $('body').bind('touchmove', function(e){e.preventDefault()});

        return (
            <form className="over18modal" onSubmit={this.preventDefaultForm}>
                <div className="dark-blur"></div>
                <div className="notice-container">
                    <div className="notice-box">
                        <p>
                            To visit the Carafe store, you must be aged 18 years or older.
                        </p>
                        <div className="age-button-container">
                            <button className="over-18-btn-cancel" type="text" onClick={this.cancel}>Cancel</button>
                            <button className="over-18-confirm" type="text" onClick={this.accept}>Confirm over 18 years old</button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default Over18Modal;