import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import FormInput from '../../../components/generic/formInput/formInput';
import SubmitButton from '../../../components/generic/submitButton/submitButton';
import { pass } from '../../../utils/passFormat';
import './verifyEmail.css';

const VerifyEmailPage = ({ verifyToken }) => {

    const submitToken = async() => {
        if (!verifyToken) {
            return;
        }
        if (verifyToken.length !== 40) {
            return
        };
        try {
            const url = "/api/accounts/verify-email/" + verifyToken;
            axios({
                url: url,
                method: 'GET'
            });
        } catch (error) {
            
        }
    }
    submitToken();

    // componentDidMount = () => {
    //     submitToken();
    // }

    return (
        <div className="verifyEmailPage containerFluidNarrow paperOffWhite paperOffWhiteLooser">
            <h4>Email verify</h4>
            <div>
                <h6>Thank you for verifying your Carafe account!</h6>
            </div>
        </div>
    )
}

export default VerifyEmailPage;