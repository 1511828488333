import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './termsofservice.css';

class TermsOfServicePage extends Component {
    render() {
updateNav();
        return (
            <div>
                <h4>Terms of Service</h4>
                <p>By using this website, you agree to the following terms of service</p>
            </div>
        );
    }
};

export default TermsOfServicePage;