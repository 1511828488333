import React, { Component } from 'react';
import { updateNav } from '../../../utils/updateActivePage';
import './covid19.css';

class Covid19Page extends Component {
    render() {
updateNav();
        return (
            <div className="containerFluid">
                <div className="paperWhiteAngled standardFontResponsive helpContentPadding linkHighlight" id="covidContentPadding">
                <h4 className="centeredHeader">Covid-19</h4>
                <p>Carafe was launched in the midst of Covid-19, therefore from the get go Carafe has orchestrated hygiene and safety policies to protect our guests, ourselves and our distribution network.</p>

                <p>We take great hygiene procedures at Carafe, all staff wash their hands before handling boxes and quite often we wear gloves to protect ourselves. We use approved disinfectant sprays over the wine bottles before packaging for guests and our packaging arrives to us in sealed boxes from our suppliers. </p>

                <p>Unless the package is travelling more than 6 miles from Paddock Wood we will personally deliver the goods via a team member at Carafe. This team member when approaching the delivery drop off will place down the delivery, ring/knock the door then step back to ensure safe 2m distance between guests. <br />
                The Carafe staff member will wear a mask when delivering for the foreseeable future until Covid-19 is no longer a threat and a mask isn’t warranted.</p>

                <p>If the package is travelling more than 6 miles from Paddock Wood to the delivery point Carafe will arrange transportation through partnership with the courier DHL. DHL’s staff have been individually trained on appropriate safety measures and guidelines to follow, including customers no longer being required to sign scanners / delivery sheets and appropriate social distancing. <br />
                Read more at <a href="https://www.simplydhl.com/covid-19updates/faq-17636BK-55392DC.html">https://www.simplydhl.com/covid-19updates/faq-17636BK-55392DC.html</a></p>

                <p>We encourage our guests to take extra measures they feel necessary at their own discretion such as disinfecting the box when receiving for extra own peace of mind however we ensure we have done everything feasible on our end to ensure your safety and hygiene, inclusive of sanitizing when packaging. </p>

                <p>Furthermore, if there is something else we could do that would reassure you further and provide you more comfort buying wine from us in this confusing time please do not hesitate to contact us at <a href="mailto:info@carafewine.co.uk">info@carafewine.co.uk </a>  <br />
                Your happiness is our top priority and is why we do what we do. </p>
                </div>
                
            </div >
        );
    }
};

export default Covid19Page;