import React from 'react';
import './submitButton.css';

class SubmitButton extends React.Component {
    render() {
        return (
            <button className="submitButton" type="submit" onClick={this.props.onClick}>Submit</button>
        )
    }
}

export default SubmitButton;
